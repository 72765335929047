import {
  getUsers as _getUsers, getUsersFailed, getUsersSuccess,
  createUser as _createUser, createUserFailed, createUserSuccess, updateUser as _updateUser, updateUserFailed, updateUserSuccess,
  createAccount as _createAccount, createAccountSuccess, createAccountFailed, updateAccount as _updateAccount, updateAccountFailed, updateAccountSuccess,
  getAccountsSuccess, getAccountsFailed, getAccounts as _getAccounts,
  getAdminPositions as _getAdminPositions, getAdminPositionsFailed, getAdminPositionsSuccess,
  getAdminOrderTrades as _getAdminOrderTrades, getAdminOrderTradesFailed, getAdminOrderTradesSuccess,
  sendNotifications as _sendNotifications, sendNotificationsFailed, sendNotificationsSuccess
} from '../reducers/admin.reducer';
import { apiCallBegan } from '../api';
import { jsonToUrlParams } from '../../utils/url';

export const getUsers = (data) => (dispatch) => {
  let url = '/users';
  let urlParams = jsonToUrlParams(data);
  return dispatch(
    apiCallBegan({
      url: `${url}?${urlParams}`,
      data,
      method: 'GET',
      onStart: _getUsers.type,
      onSuccess: getUsersSuccess.type,
      onError: getUsersFailed.type,
    })
  );
};

export const createUser = (data) => (dispatch) => {
  let url = '/users';
  return dispatch(
    apiCallBegan({
      url: `${url}`,
      data,
      method: 'POST',
      onStart: _createUser.type,
      onSuccess: createUserSuccess.type,
      onError: createUserFailed.type,
      notificationMessage: 'User Created'

    })
  );
};

export const updateUser = (data, responseMessage= null) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `users/${data._id}`,
      data,
      method: 'PUT',
      onStart: _updateUser.type,
      onSuccess: updateUserSuccess.type,
      onError: updateUserFailed.type,
      notificationMessage: responseMessage ?? 'User Updated'

    })
  );
};



export const getAccounts = (data) => (dispatch) => {
  let url = '/users/accounts';
  let urlParams = jsonToUrlParams(data);
  return dispatch(
    apiCallBegan({
      url: `${url}?${urlParams}`,
      data,
      method: 'GET',
      onStart: _getAccounts.type,
      onSuccess: getAccountsSuccess.type,
      onError: getAccountsFailed.type,
    })
  );
};


export const getAdminPositions = (data) => (dispatch) => {
  let url = '/trades/admin/positions';
  let urlParams = jsonToUrlParams(data);
  return dispatch(
    apiCallBegan({
      url: `${url}?${urlParams}`,
      data,
      method: 'GET',
      onStart: _getAdminPositions.type,
      onSuccess: getAdminPositionsSuccess.type,
      onError: getAdminPositionsFailed.type,
    })
  );
};

export const getAdminOrderTrades = (data) => (dispatch) => {
  let url = '/trades/admin/orders';
  let urlParams = jsonToUrlParams(data);
  return dispatch(
    apiCallBegan({
      url: `${url}?${urlParams}`,
      data,
      method: 'GET',
      onStart: _getAdminOrderTrades.type,
      onSuccess: getAdminOrderTradesSuccess.type,
      onError: getAdminOrderTradesFailed.type,
    })
  );
};



export const createAccount = (data) => (dispatch) => {
  let url = '/users/accounts';
  return dispatch(
    apiCallBegan({
      url: `${url}`,
      data,
      method: 'POST',
      onStart: _createAccount.type,
      onSuccess: createAccountSuccess.type,
      onError: createAccountFailed.type,
      notificationMessage: 'Account Created'

    })
  );
};

export const updateAccount = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/users/accounts/${data?._id}`,
      data,
      method: 'PUT',
      onStart: _updateAccount.type,
      onSuccess: updateAccountSuccess.type,
      onError: updateAccountFailed.type,
      notificationMessage: 'Account Updated'
    })
  );
};

export const updateAccountCredit = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/users/accounts/${data?._id}/credits`,
      data,
      method: 'PUT',
      onStart: _updateAccount.type,
      onSuccess: updateAccountSuccess.type,
      onError: updateAccountFailed.type,
      notificationMessage: 'Account Updated'
    })
  );
};

export const sendNotifications = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/common/notifications/send`,
      data,
      method: 'POST',
      onStart: _sendNotifications.type,
      onSuccess: sendNotificationsSuccess.type,
      onError: sendNotificationsFailed.type,
      notificationMessage: 'Sent Notifications to Users'
    })
  );
};