import React, { useEffect, useState } from 'react';

import {
  Breadcrumbs,
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Divider,
  Grid,
  Box,
  CircularProgress,
  InputLabel,
  Link,
  Input,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { PAYOUT_STATUS, S3_BASE_URL } from '../../../config';
import { formatPrice } from '../../../utils/string';
import DeleteIcon from '@mui/icons-material/Delete';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AdminTopBar from '../../global/AdminTopbar';
import { updatePayout } from '../../../state/actions/user.actions';
import ProgressCircle from '../../../components/ProgressCircle';
import RArrowImage from '../../../assets/r-arrow.png';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AWS from 'aws-sdk';
import { downloadFile } from '../../../services/fn';
import { AWS_ACCESS_KEY, AWS_SECRET_KEY, S3_BUCKET, REGION } from '../../../config';
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const PayoutDetails = () => {

  const s3 = new AWS.S3({
    accessKeyId: AWS_ACCESS_KEY,
    secretAccessKey: AWS_SECRET_KEY,
    region: REGION,
  });

  const navigate = useNavigate();

  const location = useLocation();
  const { payoutData = null } = location.state ?? {};

  let localUser = JSON.parse(localStorage.getItem('user'));

  const [remarkText, setRemarkText] = useState('');
  const updatedPayoutState = useSelector((state) => state?.user?.updatedPayout);
  const loadingState = useSelector((state) => state?.user?.loading);
  const [isApprove, setIsApprove ] = useState(false);

  const [openRemarkDialog, setOpenRemarkDialog] = useState(false);
  const [loading, setLoading] = useState(true);


  const handleRemarkDialogClose = () => {
    setOpenRemarkDialog(false);
  };

  const [payout, setPayout] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setPayout(payoutData);
  }, [location, payoutData]);

  useEffect(() => {
    if (updatedPayoutState?.payout) {
      setPayout({
        ...payoutData,
        remarks: updatedPayoutState?.payout?.remarks,
        status: updatedPayoutState?.payout?.status,
        certificateFile: updatedPayoutState?.payout?.certificateFile,
        paymentReceiptFile: updatedPayoutState?.payout?.paymentReceiptFile,
      });
    }
  }, [updatedPayoutState]);

  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  function getProfitTargetValue(account) {
    return (account?.profitTarget / 100) * account?.amount;
  }

  function getDailyLossLimitValue(account) {
    return (account?.dll / 100) * account?.amount;
  }

  function getTotalLossLimitValue(account) {
    return (account?.tll / 100) * account?.amount;
  }

  function handleDeclinePayout() {
    setIsApprove(false);
    setOpenRemarkDialog(true);
  }

  function handleApprovePayout() {
    setIsApprove(true);
    setOpenRemarkDialog(true);
  }

  const handleRemarkText = (e) => {
    setRemarkText(e.target.value);
  };

  async function uploadToS3(params) {
    let response = await s3.upload(params).promise();
    return response.Key;
  }

  async function handleRemarkConfirm() {
    setLoading(true);
    let paymentReceiptFileKey = null;
    let certificateFileKey = null;

    if (paymentReceiptFile) {
      paymentReceiptFileKey = await uploadToS3({
        Bucket: S3_BUCKET,
        Key: `${Date.now()}-${paymentReceiptFile.name}`,
        Body: paymentReceiptFile,
        ContentType: paymentReceiptFile.type,
      });
    }

    if (certificateFile) {
      certificateFileKey = await uploadToS3({
        Bucket: S3_BUCKET,
        Key: `${Date.now()}-${certificateFile.name}`,
        Body: certificateFile,
        ContentType: certificateFile.type,
      });
    }
    dispatch(updatePayout({
      _id: payout?._id,
      status: isApprove ? PAYOUT_STATUS.PROCESSED : PAYOUT_STATUS.REJECTED,
      remarks: remarkText,
      paymentReceiptFile: paymentReceiptFileKey,
      certificateFile: certificateFileKey
    }));
    setOpenRemarkDialog(false);
    navigate('/admin-requests');

  }


  const [paymentReceiptFile, setPaymentReceiptFile] = useState(null);
  const [certificateFile, setCertificateFile] = useState(null);

  const handleFileChange1 = (event) => {
    setPaymentReceiptFile(event.target.files[0]);
  };

  const handleFileChange2 = (event) => {
    setCertificateFile(event.target.files[0]);
  };

  const handleRemoveFile1 = () => {
    setPaymentReceiptFile(null);
  };

  const handleRemoveFile2 = () => {
    setCertificateFile(null);
  };

  return (
    <>
      <AdminTopBar />

      <Grid sx={{
        p: 3,
        display: {
          sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
        },
        alignItems: 'center',
        justifyContent: 'space-between'
      }} item xs={12} md={12} lg={12} sm={12} mb={2}>
        <div>
          <Breadcrumbs sx={{
            pl: 1, pt: 1,
          }} separator={<img alt='img' style={{
            width: 9,
            color: 'white'
          }} src={RArrowImage} />} aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" href="/admin-requests">
              <Typography sx={{
                fontSize: 17,
                fontWeight: 600
              }}>Payout</Typography>
            </Link>
            <Link underline="hover" key="1" color="inherit" href="/admin-requests">
              <Typography sx={{
                fontSize: 17,
                fontWeight: 600,
                color: '#41AAAF'
              }}>Payout Details</Typography>
            </Link>
          </Breadcrumbs>
        </div>
        <div>
          {loading && <CircularProgress style={{
            position: 'fixed',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999
          }} color="inherit" />}

          {localUser.role === 'SUPERADMIN' && payout?.status === PAYOUT_STATUS.REQUESTED && <div style={{
            display: 'flex',
          }}><Button
              onClick={() => handleApprovePayout()}
              sx={{
                background: '#41AAAF',
                color: '#ffffff',
                fontSize: 10,
                width: 100,
                marginLeft: 2,
                '&:hover': {
                  backgroundColor: '#41AAAF',
                  color: '#ffffff'
                },
                '&:disabled': {
                  backgroundColor: 'gray',
                  color: '#ffffff'
                },
              }} variant='contained' >Approve</Button>
            <Button
              onClick={() => handleDeclinePayout()}
              sx={{
                marginLeft: 2,
                fontSize: 10,
                width: 100,
                background: '#FF4757',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#FF4757',
                  color: '#ffffff'
                },
              }} variant='contained' >Decline</Button>

            <Dialog open={openRemarkDialog} onClose={handleRemarkDialogClose}>
              <DialogTitle>{isApprove ? 'Approve': 'Decline'} Payout?</DialogTitle>
              <DialogContent>
                <div style={{padding: 9}}>
                </div>
                <TextField
                  sx={{
                    width: 470,
                    mb: 2
                  }}
                  value={remarkText}
                  onChange={handleRemarkText}
                  autoFocus
                  multiline
                  rows={3}
                  id="name"
                  label="Remarks"
                  type="text"
                />
                {isApprove &&
                  <div>
                    <Box display="flex" alignItems="center" marginBottom={2}>
                      <Input
                        id="file-input-1"
                        type="file"
                        onChange={handleFileChange1}
                        inputProps={{ accept: '.jpg,.jpeg,.png,.pdf', }}
                        style={{ display: 'none' }}
                      />
                      <label htmlFor="file-input-1">
                        <IconButton
                          color="primary"
                          component="span"
                          aria-label="upload picture"
                          disabled={!!paymentReceiptFile}
                        >
                          <CloudUploadIcon />
                        </IconButton>
                        <Typography variant="body1" component="span" color="textSecondary">
                          {paymentReceiptFile ? paymentReceiptFile.name : 'Upload Payment Receipt'}
                        </Typography>
                      </label>
                      {paymentReceiptFile && (
                        <Button
                          sx={{
                            ml: 2,
                          }}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          startIcon={<DeleteIcon />}
                          onClick={handleRemoveFile1}
                        >
                          Remove
                        </Button>
                      )}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Input
                        id="file-input-2"
                        type="file"
                        onChange={handleFileChange2}
                        inputProps={{ accept: '.jpg,.jpeg,.png,.pdf', }}
                        style={{ display: 'none' }}
                      />
                      <label htmlFor="file-input-2">
                        <IconButton
                          color="primary"
                          component="span"
                          aria-label="upload picture"
                          disabled={!!certificateFile}
                        >
                          <CloudUploadIcon />
                        </IconButton>
                        <Typography variant="body1" component="span" color="textSecondary">
                          {certificateFile ? certificateFile.name : 'Upload Certificate'}
                        </Typography>
                      </label>
                      {certificateFile && (
                        <Button
                          sx={{
                            ml: 2
                          }}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          startIcon={<DeleteIcon />}
                          onClick={handleRemoveFile2}
                        >
                          Remove
                        </Button>
                      )}
                    </Box>
                  </div>
                }
              </DialogContent>
              <DialogActions sx={{
                justifyContent: 'center',
                pb: 2
              }}>
                <Button variant='outlined' onClick={handleRemarkDialogClose}>Cancel</Button>
                <Button variant='outlined' onClick={handleRemarkConfirm}>Confirm</Button>
              </DialogActions>
            </Dialog>
          </div>
          }
        </div>
      </Grid>

      <div style={{
        paddingLeft: 27,
        paddingRight: 27,
      }}>
        <Paper >
          <Grid container p={3}>

           
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Account Number
              </InputLabel>
              <Typography>{payout?.account?.accountNumber}</Typography>
            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">User Name</InputLabel>
              <Typography>{payout?.user?.username}</Typography>
            </Grid>
           
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Plan Display Name
              </InputLabel>
              <Typography>{payout?.account?.planAlias}</Typography>
            </Grid>

            <Grid pb={2} pr={2} item md={12} lg={12} sm={12} xs={12} >
              <Divider orientation />
            </Grid>


            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Account Type
              </InputLabel>
              <Typography>{payout?.account?.accountType?.label}</Typography>
            </Grid>

            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Account Size
              </InputLabel>
              <Typography>{payout?.account?.accountSize?.label}</Typography>

            </Grid>

            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Daily Loss Limit
              </InputLabel>
              <Typography>{formatPrice(getDailyLossLimitValue(payout?.account))}</Typography>
            </Grid>

            
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Total Loss Limit
              </InputLabel>
              <Typography>{formatPrice(getTotalLossLimitValue(payout?.account))}</Typography>
            </Grid>

            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
               Profit Target
              </InputLabel>

              <Typography>{formatPrice(getProfitTargetValue(payout?.account))}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Trade Square-off
              </InputLabel>
              <Typography>{payout?.account?.tradeSquareOff}</Typography>
            </Grid>

            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Validity Days
              </InputLabel>
              <Typography>{payout?.account?.validityDays}</Typography>
            </Grid>

            <Grid pb={2} pr={2} item md={12} lg={12} sm={12} xs={12} >
              <Divider orientation />
            </Grid>

            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Requested Date
              </InputLabel>
              <Typography>{dayjs(payout?.createdAt).format('DD-MM-YYYY')}</Typography>
            </Grid>

            {payout?.remarks && <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Remarks
              </InputLabel>
              <Typography>{payout?.remarks}</Typography>
            </Grid>
            }

            {payout?.paymentReceiptFile && <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Payment Receipt
              </InputLabel>
              <a style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: 'blue'
              }} onClick={() => downloadFile(`${S3_BASE_URL}/${payout?.paymentReceiptFile}`, payout?.paymentReceiptFile)} >{payout?.paymentReceiptFile} (Download)</a>
            </Grid>
            }
            {payout?.certificateFile && <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Certificate
              </InputLabel>
              <a style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: 'blue'
              }} onClick={() => downloadFile(`${S3_BASE_URL}/${payout?.certificateFile}`, payout?.certificateFile)}>{payout?.certificateFile} (Download)</a>
            </Grid>
            }

          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default PayoutDetails;
