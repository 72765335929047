import { useMediaQuery, useTheme } from '@mui/material';
import {
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer
} from 'recharts';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useState, useEffect } from 'react';
import { formatPrice } from '../../../utils/string';

import { tokens } from '../../../theme';
const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);


const ChartSection = ({ account }) => {


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectedDays, setSelectedDays] = useState(10); 
  const isDesktop = useMediaQuery('(min-width:600px)'); 
  const [openingBalanceCreditHistory, setOpeningBalanceCreditHistory] = useState(0);

  useEffect(() => {
    if (account?.credits?.openingBalanceHistory) {
      let _openingBalanceCreditHistory = JSON.parse(JSON.stringify(account?.credits?.openingBalanceHistory));
      _openingBalanceCreditHistory = _openingBalanceCreditHistory.sort((a, b) => new Date(a.date) - new Date(b.date));
      for (let element of _openingBalanceCreditHistory) {
        element.date = dayjs.utc(element.date).format('DD MMM');
        element.formattedBalance = formatPrice(element?.balance);
      }
      _openingBalanceCreditHistory.unshift({
        date: 'Opened',
        balance: _openingBalanceCreditHistory?.[0]?.balance,
        formattedBalance : formatPrice(_openingBalanceCreditHistory?.[0]?.balance)
      });
      setOpeningBalanceCreditHistory(_openingBalanceCreditHistory);
    }
  }, [account]);


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div >
          <p style={{
            color: colors.teal
          }}>{`${label} - ${formatPrice(payload[0].value)}`}</p>
        </div>
      );
    }

    return null;
  };

  const handleChange = (event) => {
    let _openingBalanceCreditHistory = JSON.parse(JSON.stringify(account?.credits?.openingBalanceHistory));
    _openingBalanceCreditHistory = _openingBalanceCreditHistory.sort((a, b) => new Date(a.date) - new Date(b.date));
    for (let element of _openingBalanceCreditHistory) {
      element.date = dayjs.utc(element.date).format('DD MMM');
      element.formattedBalance = formatPrice(element?.balance);
    }
    _openingBalanceCreditHistory.unshift({
      date: 'Opened',
      balance: _openingBalanceCreditHistory?.[0]?.balance,
      formattedBalance: formatPrice(_openingBalanceCreditHistory?.[0]?.balance)
    });
    setOpeningBalanceCreditHistory(_openingBalanceCreditHistory.slice(-event?.target?.value));
    setSelectedDays(event.target.value);
  };

  return (
    <div style={{
    }}>
      <FormControl sx={{
        float: 'right'
      }}>
        <Select disableUnderline
          variant="standard"
          sx={{
            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '& .MuiOutlinedInput-root': {
              border: 'none',
            },
            '& .MuiInputBase-root': {
              border: 'none',
            },
            color: colors.teal
                    
          }} value={selectedDays}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Select Days' }}>
          <MenuItem value={10}>Last 10 days</MenuItem>
          <MenuItem value={50}>Last 50 days</MenuItem>
          <MenuItem value={100}>Last 100 days</MenuItem>
        </Select>
      </FormControl>
      <br />
      <br />
      <br />
      <ResponsiveContainer width="100%" height={240}>
      <LineChart data={openingBalanceCreditHistory}>
        <XAxis dataKey="date" padding={{ left: 30, right: 30 }} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          dataKey="balance"
          dot={false}
          stroke="#0ac8d2"
          activeDot={{ r: 8 }}
        />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartSection;