import React from 'react';
import { Skeleton, Table, TableBody, TableCell, TableRow } from '@mui/material';

const RealTimeInstrumentTableLoader = () => {
  const rowCount = 4;
  const columnCount = 4;

  const renderSkeletonRows = () => {
    const rows = [];
    for (let i = 0; i < rowCount; i++) {
      rows.push(
        <TableRow key={i}>
          {Array.from({ length: columnCount }).map((_, index) => (
            <TableCell key={index}>
              <Skeleton width={58} height={10} animation="pulse" />
            </TableCell>
          ))}
        </TableRow>
      );
    }
    return rows;
  };

  return (
    <Table>
      <TableBody>{renderSkeletonRows()}</TableBody>
    </Table>
  );
};

export default RealTimeInstrumentTableLoader;