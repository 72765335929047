import { Box, Button, Divider, DialogTitle, Grid, Paper, DialogContent, FormControlLabel, IconButton, MenuItem, Dialog, Radio, RadioGroup, TextField, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
// import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


import Draggable from 'react-draggable';
import axios from 'axios';
import { MARGIN, CHARGE, REST_URL } from '../../../config';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { useEffect, useState } from 'react';
import { formatPrice } from '../../../utils/string';
import { useUserContext } from '../../../UserContext';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper style={{
        border: '1px solid #FFFFFF33',
        borderRadius: '10px',
      }} {...props} />
    </Draggable>
  );
}




const BuySell = (props) => {
  const { tab, handleBuySellClose, openBuySellDialog, handleBuy, handleQuantityChange,
    handleTabChange, handleSell,
    quantity, instrumentName, price, availableMargin, handlePriceChange,
    orderType, handleOrderTypeChange,
    orderMode, lastTradePrice, priceDisabled, stopLossPrice,
    handleStopLossPriceChange, buySellDialogMode,
    handleUpdatePosition, existingOpenOrderPrice = 0
  } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let buySellTabs = buySellDialogMode == 'Add' && orderMode == 1 ? ['Buy']
    : buySellDialogMode == 'Exit' && orderMode == 1 ? ['Sell']
      : buySellDialogMode == 'Add' && orderMode == 2 ? ['Sell']
        : buySellDialogMode == 'Exit' && orderMode == 2 ? ['Buy']
          : buySellDialogMode == 'Edit' && tab == 'Buy' ? ['Buy']
            : buySellDialogMode == 'Edit' && tab == 'Sell' ? ['Sell']
              : buySellDialogMode == 'Update StopLoss' && tab == 'Buy' ? ['Buy']
                : buySellDialogMode == 'Update StopLoss' && tab == 'Sell' ? ['Sell']
                  : ['Buy', 'Sell'];

  const [clickCount, setClickCount] = useState(0);

  const [availablePositionMargin, setAvailablePositionMargin] = useState(0);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [requiredMargin, setRequiredMargin] = useState(0);
  const [buySellDisabled, setBuySellDisabled] = useState(false);
  const [quantityDisabled, setQuantityDisabled] = useState(false);
  const [orderTypeDisabled, setOrderTypeDisabled] = useState(false);
  const [stopLossPriceDisabled, setStopLossPriceDisabled] = useState(false);
  const [pricesDisabled, setPricesDisabled] = useState(false);
  const [availableMarginState, setAvailableMarginState] = useState(0);
  const [orderExists, setOrderExists] = useState(undefined);


  const [priceLabel, setPriceLabel] = useState('Price');

  const { accountContext, userContext, updateAccountContext, updateUserContext } = useUserContext();

  useEffect(() => {
    setPricesDisabled(priceDisabled);
  }, [priceDisabled]);

  useEffect(() => {
    setAvailableMarginState(availableMargin);
  }, [availableMargin]);

  useEffect(() => {
    setPriceLabel(orderType == 'Market' ?
      'Price' :
      'Limit Price'
    );
  }, [orderType]);


  useEffect(() => {
    let _requiredMargin = price * quantity
    let marketOrLimitPrice = 0;
    if (orderType == 'Market') {
      marketOrLimitPrice = lastTradePrice;
    } else {
      marketOrLimitPrice = Number(price);
    }
    if (quantity < 1 || quantity == '') {
      setErrorMessage('Qty should be more than 1');
      setBuySellDisabled(true);
    } else if (price < 0 || price == '') {
      setErrorMessage('Price should be more than 1');
      setBuySellDisabled(true);
    } else {
      setErrorMessage(undefined);
      setBuySellDisabled(false);
      // ---- EDIT --- //
      if (buySellDialogMode == 'Edit') {
        let hasOpenPositions = false;
        let _availableMarginState = availableMargin;
        if (orderMode == 1 && tab == 'Buy') {
          setStopLossPriceDisabled(false);
          setRequiredMargin(_requiredMargin);
          _availableMarginState += existingOpenOrderPrice;
          setAvailableMarginState(_availableMarginState);

        }
        else if (orderMode == 1 && tab == 'Sell') {
          hasOpenPositions = true;
          setStopLossPriceDisabled(true);
          setRequiredMargin(0);
        }
        else if (orderMode == 2 && tab == 'Buy') {
          hasOpenPositions = true;
          setStopLossPriceDisabled(true);
          setRequiredMargin(0);
        }
        else if (orderMode == 2 && tab == 'Sell') {
          setStopLossPriceDisabled(false);
          setRequiredMargin(_requiredMargin);
          _availableMarginState += existingOpenOrderPrice;
          setAvailableMarginState(_availableMarginState);
        }

        if (tab == 'Buy') {
          if (!hasOpenPositions && _requiredMargin > _availableMarginState) {
            setErrorMessage('Not Enough Margin');
            setBuySellDisabled(true);
          }
          if (stopLossPrice > 0 && stopLossPrice >= marketOrLimitPrice) {
            setErrorMessage('Stop loss Price cannot be greater than market price');
            setBuySellDisabled(true);
          }
          if (_requiredMargin < _availableMarginState) {
            if (stopLossPrice > 0) {
              if (stopLossPrice <= marketOrLimitPrice) {
                setErrorMessage(undefined);
                setBuySellDisabled(false);
              }
            } else {
              setErrorMessage(undefined);
              setBuySellDisabled(false);
            }
          }
        }
        if (tab == 'Sell') {
          if (!hasOpenPositions && _requiredMargin > _availableMarginState) {
            setErrorMessage('Not Enough Margin');
            setBuySellDisabled(true);
          }
          if (stopLossPrice > 0 && stopLossPrice <= marketOrLimitPrice) {
            setErrorMessage('Stop loss Price cannot be less than market price');
            setBuySellDisabled(true);
          }
          if (_requiredMargin < _availableMarginState) {
            if (stopLossPrice > 0) {
              if (stopLossPrice >= marketOrLimitPrice) {
                setErrorMessage(undefined);
                setBuySellDisabled(false);
              }
            } else {
              setErrorMessage(undefined);
              setBuySellDisabled(false);
            }
          }
          
        }
      }
      // ---- END EDIT --- //

      // ---- EXIT --- //
      if (buySellDialogMode == 'Exit') {
        setBuySellDisabled(false);
        setStopLossPriceDisabled(true);
      }
      // ---- END EXIT --- //

      // ---- ADD --- //
      if (buySellDialogMode == 'Add') {
        setRequiredMargin(_requiredMargin);
        if (tab == 'Buy') {
          if (_requiredMargin > availableMarginState) {
            setErrorMessage('Not Enough Margin');
            setBuySellDisabled(true)
          }
          else if (stopLossPrice > 0 && stopLossPrice >= marketOrLimitPrice) {
            setErrorMessage('Stop loss Price cannot be greater than market price');
            setBuySellDisabled(true)
          }
          else {
            setErrorMessage(undefined);
            setBuySellDisabled(false)
          }
        }
        if (tab == 'Sell') {
          if (_requiredMargin > availableMarginState) {
            setErrorMessage('Not Enough Margin');
            setBuySellDisabled(true)
          }
          else if (stopLossPrice > 0 && stopLossPrice <= marketOrLimitPrice) {
            setErrorMessage('Stop loss Price cannot be less than market price');
            setBuySellDisabled(true)
          }
          else {
            setErrorMessage(undefined);
            setBuySellDisabled(false)
          }
        }
      }
      // ---- END ADD --- //
      // ---- UPDATE STOPLOSS --- //
      if (buySellDialogMode == 'Update StopLoss') {
        setQuantityDisabled(true);
        setPricesDisabled(true);
        setOrderTypeDisabled(true);
        setRequiredMargin(0)

        if (tab == 'Buy') {
          if (stopLossPrice > 0 && stopLossPrice >= marketOrLimitPrice) {
            setErrorMessage('Stop loss Price cannot be greater than market price');
            setBuySellDisabled(true)
          }
          else {
            setErrorMessage(undefined);
            setBuySellDisabled(false)
          }
        }
        if (tab == 'Sell') {
          if (stopLossPrice > 0 && stopLossPrice <= marketOrLimitPrice) {
            setErrorMessage('Stop loss Price cannot be less than market price');
            setBuySellDisabled(true)
          }
          else {
            setErrorMessage(undefined);
            setBuySellDisabled(false)

          }
        }
      }
      // ---- END UPDATE STOPLOSS --- //
      // ---- BUY / SELL --- //
      if (!buySellDialogMode) {
        if (availablePositionMargin > 0) {
          _requiredMargin = 0;
          if (orderExists == 'BUY') {
            if (tab == 'Buy') {
              _requiredMargin = price * quantity;
              setStopLossPriceDisabled(false);
            }
          }
          else if (orderExists == 'OSELL') {
            if (tab == 'Sell') {
              _requiredMargin = price * quantity;
              setStopLossPriceDisabled(false);
            }
          }
          if (tab == 'Buy') {
            if (_requiredMargin > availableMarginState) {
              setErrorMessage('Not Enough Margin');
              setBuySellDisabled(true);
            }
            else if (stopLossPrice > 0 && stopLossPrice >= marketOrLimitPrice) {
              setErrorMessage('Stop loss Price cannot be greater than market price');
              setBuySellDisabled(true);
            }
            else {
              setErrorMessage(undefined);
              setBuySellDisabled(false);
            }
          } else if (tab == 'Sell') {
            if (_requiredMargin > availableMarginState) {
              setErrorMessage('Not Enough Margin');
              setBuySellDisabled(true);
            }
            else if (stopLossPrice > 0 && stopLossPrice <= marketOrLimitPrice) {
              setErrorMessage('Stop loss Price cannot be less than market price');
              setBuySellDisabled(true);
            }
            else {
              setErrorMessage(undefined);
              setBuySellDisabled(false);
            }
          }
          setRequiredMargin(_requiredMargin);
        } else {
          if (orderExists == 'BUY') {
            if (tab == 'Buy') {
              setStopLossPriceDisabled(false);
            }
          }
          else if (orderExists == 'OSELL') {
            if (tab == 'Sell') {
              setStopLossPriceDisabled(false);
            }
          }
          if (tab == 'Buy') {
            if (_requiredMargin > availableMarginState) {
              setErrorMessage('Not Enough Margin');
              setBuySellDisabled(true);
            }
            else if (stopLossPrice > 0 && stopLossPrice >= marketOrLimitPrice) {
              setErrorMessage('Stop loss Price cannot be greater than market price');
              setBuySellDisabled(true);
            }
            else {
              setErrorMessage(undefined);
              setBuySellDisabled(false);
            }
          } else if (tab == 'Sell') {
            if (_requiredMargin > availableMarginState) {
              setErrorMessage('Not Enough Margin');
              setBuySellDisabled(true);
            }
            else if (stopLossPrice > 0 && stopLossPrice <= marketOrLimitPrice) {
              setErrorMessage('Stop loss Price cannot be less than market price');
              setBuySellDisabled(true);
            }
            else {
              setErrorMessage(undefined);
              setBuySellDisabled(false);
            }
          }
          setRequiredMargin(_requiredMargin);
        }
      }
    }
    // ---- END BUY / SELL --- //
  }, [price, quantity, accountContext, availablePositionMargin, stopLossPrice, tab, buySellDialogMode])



  useEffect(() => {
    if (tab && accountContext && instrumentName) {
      getRequiredMargin();
      if (!openBuySellDialog) {
        setOrderTypeDisabled(false);
        setQuantityDisabled(false);
      }
    }

    async function getRequiredMargin() {
      try {
        if (buySellDialogMode != 'Edit') {
          const accessToken = localStorage.getItem('token');
          const response = await axios.post(`${REST_URL}/trades/balances/required-margin`, {
            instrument: instrumentName,
            account: accountContext?._id,
            mode: tab // Buy or Sell
          }, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          });
          setAvailablePositionMargin(response?.data?.availablePositionMargin);
          let existingTradeExists = response?.data?.trades?.find((trade) => {
            return trade?.instrument == instrumentName
          })
          if (existingTradeExists) {
            let currentType = tab == 'Buy' ? 1 : 2;
            if (instrumentName == existingTradeExists?.instrument &&
              existingTradeExists?.type == (currentType == 1 ? 2 : 1)) {
              setStopLossPriceDisabled(true);
            } else {
              setStopLossPriceDisabled(false);
            }
            if (existingTradeExists?.mode == 1 && existingTradeExists?.type == 1) {
              setOrderExists('BUY')
            } else if (existingTradeExists?.mode == 1 && existingTradeExists?.type == 2) {
              setOrderExists('SELL')
            } else if (existingTradeExists?.mode == 2 && existingTradeExists?.type == 1) {
              setOrderExists('OBUY')
            } else if (existingTradeExists?.mode == 2 && existingTradeExists?.type == 2) {
              setOrderExists('OSELL')

            }
          } else {
            setStopLossPriceDisabled(false);
          }

        }
      } catch (error) {
        console.log('error: ', error);
      }
    }

  }, [openBuySellDialog, tab])


  return (
    <Dialog
      open={openBuySellDialog}
      PaperComponent={PaperComponent}
      onClose={handleBuySellClose}
      aria-labelledby="draggable-dialog-title"
    >
      <Grid style={{
      }} sx={{
        overflow: 'none',
        backgroundColor: colors.dashboard.background, width: { md: 420, lg: 420 }
      }} container spacing={2} >

        {buySellDialogMode == 'Add' ?
          <div style={{
            backgroundColor: orderMode == 1 ? colors.buyBackground : colors.sellBackground,
            paddingTop: 20,
            borderBottom: '1px solid #FFFFFF33',

            paddingBottom: 3,
            paddingLeft: 35,
            paddingRight: 10,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}>
            <div style={{
              cursor: 'move'
            }} id="draggable-dialog-title">
              <Typography style={{
                fontSize: 16,
                fontWeight: 600,
                marginTop: 6

              }}>Add Position</Typography>
            </div>
            <div>  &nbsp;
              <IconButton onClick={handleBuySellClose}>
                <CloseOutlined />
              </IconButton>
            </div>
          </div>
          : buySellDialogMode == 'Exit' ? <div style={{
            backgroundColor: orderMode == 1 ? colors.sellBackground : colors.buyBackground,
            borderBottom: '1px solid #FFFFFF33',
            paddingTop: 20,
            paddingBottom: 3,
            paddingLeft: 35,
            paddingRight: 10,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}>
            <div style={{
              cursor: 'move'
            }} id="draggable-dialog-title">
              <Typography style={{
                fontSize: 16,
                fontWeight: 600,
                marginTop: 6

              }}>Exit Position</Typography>
            </div>
            <div>  &nbsp;
              <IconButton onClick={handleBuySellClose}>
                <CloseOutlined />
              </IconButton>
            </div>
          </div> :
            <div style={{
              backgroundColor: tab == 'Buy' ? colors.buyBackground : colors.sellBackground,
              borderBottom: '1px solid #FFFFFF33',
              paddingTop: 20,
              paddingBottom: 3,
              paddingLeft: 35,
              paddingRight: 10,
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}>
              <div style={{
                cursor: 'move'
              }} id="draggable-dialog-title">
                <Typography style={{
                  fontSize: 16,
                  fontWeight: 600,
                  marginTop: 6

                }}>{
                    buySellDialogMode == 'Edit' && tab == 'Buy' ? 'Buy' :
                      buySellDialogMode == 'Edit' && tab == 'Sell' ? 'Sell' :
                        buySellDialogMode == 'Update StopLoss' ? 'Update StopLoss' :
                          'Buy & Sell'
                  }</Typography>
              </div>
              <div>  &nbsp;
                <IconButton onClick={handleBuySellClose}>
                  <CloseOutlined />
                </IconButton>
              </div>
            </div>}


        <Box sx={{ backgroundColor: colors.dashboard.background, flexGrow: 1, padding: 0, display: { md: 'flex', xs: 'flex' } }}>
          {
            buySellTabs.map((item) => (
              <MenuItem
                key={item}
                onClick={(event) => handleTabChange(item)}
                selected={tab === item}
                sx={{
                  marginX: 4,
                  '&.Mui-selected': {
                    background: `${colors.topbar.background}!important`,
                    position: 'relative',

                    color: `${item === 'Buy' ? colors.green : '#FF4757'}`,
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      textAlign: 'center',
                      transform: 'translateX(-76%)',
                      width: '100%',
                      height: '2px',
                      borderBottom: `2px solid ${item === 'Buy' ? colors.green : '#FF4757'}`,
                    },
                  },
                  my: '20px',
                  color: colors.grey[200],
                  display: 'block', fontWeight: 600, fontSize: '14px', textTransform: 'none'
                }}
                style={{ paddingLeft: '10px', }}
              >
                {item}
              </MenuItem>
            ))}
        </Box>


        <Grid sx={{
          display: { md: 'block', xs: 'block' }
        }} style={{
          marginTop: '-36px'
        }} item xs={12} sm={12} md={12} lg={12}>
          <Divider style={{
          }} orientation="horizontal" />
        </Grid>

        <Grid style={{
          paddingLeft: 35,
          paddingRight: 15,
          display: 'flex',
          justifyContent: 'space-between'
        }} item xs={12} sm={12} md={12} lg={12}>
          <Typography style={{
            fontWeight: 700,
            fontSize: 15
          }}>{instrumentName ? `${instrumentName}.x` : 'NA'}</Typography>
          <Typography style={{
            fontWeight: 700,
            fontSize: 15
          }}>x {quantity}</Typography>
        </Grid>

        <Grid style={{
          paddingLeft: 35,
          paddingRight: 15,
          display: 'flex',
          justifyContent: 'space-between'
        }} item xs={12} sm={12} md={12} lg={12}>
          <Typography style={{
            fontWeight: 600,
            color: 'grey',
            fontSize: 11
          }}>LTP: {lastTradePrice}</Typography>

        </Grid>
        <Grid style={{
          paddingLeft: 35,
          paddingRight: 15,
        }} item xs={6} sm={6} md={6} lg={6}>
          <TextField
            type="number"
            disabled={quantityDisabled}
            value={quantity}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.tealBlack,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: colors.tealBlack,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.tealBlack,
              },
              '& .MuiInputLabel-root': {
                color: colors.tealBlack,
              },
            }}
            label="Qty"
            onWheel={event => event.target.blur()}
            onChange={handleQuantityChange}
            id="outlined-size-small"
            defaultValue={quantity}
            inputProps={{ min: '1', step: '1' }}
          />
        </Grid>
        <Grid style={{
          paddingLeft: 35,
          paddingRight: 15,
        }} item xs={6} sm={6} md={6}>
          <TextField
            value={price}
            onChange={handlePriceChange}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.tealBlack,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: colors.tealBlack,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.tealBlack,
              },
              '& .MuiInputLabel-root': {
                color: colors.tealBlack,
              },
            }}
            onWheel={event => event.target.blur()}
            disabled={pricesDisabled}
            type="number"
            label={priceLabel}
            id="outlined-size-small"
            defaultValue="0"
            inputProps={{ min: '0', step: '1' }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>

          <RadioGroup
            value={orderType}
            onChange={handleOrderTypeChange}
            row sx={{
              justifyContent: 'space-around'
            }}  >
            <FormControlLabel value="Market"
              disabled={orderTypeDisabled}
              control={<Radio sx={{
                color: colors.tealBlack,
                '&.Mui-checked': {
                  color: colors.tealBlack,
                },
              }} />} label="Market" />
            <FormControlLabel
              disabled={orderTypeDisabled}
              value="Limit" control={<Radio sx={{
                color: colors.tealBlack,
                '&.Mui-checked': {
                  color: colors.tealBlack,
                },
              }} />} label="Limit" />
          </RadioGroup>
        </Grid>
        <Grid style={{
          paddingLeft: 35,
          paddingRight: 15,
        }} item xs={12} sm={12} md={12}>
          <TextField
            value={stopLossPrice}
            onChange={handleStopLossPriceChange}
            type="number"
            disabled={stopLossPriceDisabled}
            onWheel={event => event.target.blur()}
            fullWidth
            sx={{
              borderColor: 'red',
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.tealBlack,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: colors.tealBlack,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.tealBlack,
              },
              '& .MuiInputLabel-root': {
                color: colors.tealBlack,
              },
            }}
            label="Stop Loss Price"
            id="outlined-size-small"
            defaultValue="0"
            inputProps={{ min: '0', step: '1' }}
          />
        </Grid>

        <Grid sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginLeft: 3,
          marginRight: 3

        }} item xs={12} sm={12} md={12}>
          <div>
            <Typography style={{
              fontSize: 16,
              fontWeight: 600,

            }}>Brokerage</Typography>

          </div>
          <div>
            <Typography style={{
              fontSize: 16,

              fontWeight: 600
            }}><span style={{
              fontFamily: 'sans-serif',
            }}></span>{CHARGE}</Typography>
          </div>
        </Grid>

        <Grid sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginLeft: 3,
          marginRight: 3

        }} item xs={12} sm={12} md={12}>
          <div>
            <Typography style={{
              fontSize: 12,
              fontWeight: 700,
            }}>Margin Available</Typography>
            <Typography><span style={{
              fontFamily: 'sans-serif',
            }}>₹</span>{formatPrice(availableMarginState)}</Typography>

          </div>
          <div style={{
            textAlign: 'right',
          }}>
            <Typography style={{
              fontSize: 12,
              fontWeight: 700
            }}>Required</Typography>
            <Typography><span style={{
              fontFamily: 'sans-serif',
            }}>₹</span>{formatPrice(requiredMargin)}</Typography>
          </div>

        </Grid>
        {errorMessage &&
          <Grid item sx={{
            marginLeft: 3,
            display: 'flex'
          }}>
            <Typography style={{
              color: '#FF4757',
              fontSize: 12
            }}>{errorMessage}</Typography>
          </Grid>}
        <Grid style={{
          paddingLeft: 34,
          paddingRight: 18,
          paddingBottom: 18,
        }} item xs={12} sm={12} md={12}>

          {buySellDialogMode == 'Update StopLoss' && tab == 'Buy' ?
            <Button
              disabled={buySellDisabled || errorMessage != undefined}
              sx={{
                backgroundColor: colors.buyButton,
                color: colors.black[100],
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: colors.buyButton,
                },
              }}
              color="primary"
              onClick={() => {
                setBuySellDisabled(true);
                if (!buySellDisabled) {
                  handleUpdatePosition();
                }
              }}
              fullWidth variant="contained" size="medium">
              Update
            </Button>
            : buySellDialogMode == 'Update StopLoss' && tab == 'Sell' ?
              <Button
                disabled={buySellDisabled || errorMessage != undefined}
                sx={{
                  backgroundColor: colors.sellButton,
                  fontWeight: 600,
                  color: colors.black[100],
                  '&:hover': {
                    backgroundColor: colors.sellButton,
                  },
                }}
                color="primary"
                onClick={() => {
                  setBuySellDisabled(true);
                  if (!buySellDisabled) {
                    handleUpdatePosition();
                  }
                }} fullWidth variant="contained" size="medium">
                Update
              </Button> :
              tab === 'Buy' ?
                <Button
                  disabled={buySellDisabled || errorMessage != undefined}
                  sx={{
                    backgroundColor: colors.buyButton,
                    fontWeight: 600,
                    color: colors.black[100],
                    '&:hover': {
                      backgroundColor: colors.buyButton,
                    },
                  }}
                  color="primary"
                  onClick={() => {
                    setBuySellDisabled(true);
                    if (!buySellDisabled) {
                      handleBuy();
                    }
                  }}
                  fullWidth variant="contained" size="medium">
                  Buy
                </Button>
                :
                <Button
                  disabled={buySellDisabled || errorMessage != undefined}
                  sx={{
                    backgroundColor: colors.sellButton,
                    fontWeight: 600,
                    color: colors.black[100],
                    '&:hover': {
                      backgroundColor: colors.sellButton,
                    },
                  }}
                  color="primary"
                  onClick={() => {
                    setBuySellDisabled(true);
                    if (!buySellDisabled) {
                      handleSell();
                    }
                  }
                  }
                  fullWidth variant="contained" size="medium">
                  Sell
                </Button>}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default BuySell;