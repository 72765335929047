


import {
  ButtonBase,
  Grid,
  Divider,
  Typography,
  useTheme,
  Dialog,
  Button,
  IconButton,
  TextField,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import React, { useEffect, useState, useRef } from 'react';
import AccountCardImage from '../../../assets/acc-card.png';
import UpgradeImage from '../../../assets/upgrade.png';
import { tokens } from '../../../theme';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useDispatch, useSelector } from 'react-redux';
import { validatePayoutOTP } from '../../../state/actions/user.actions';

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const AccountUpgradeDialog = ({
  accountUpgradeDialog, 
  handleAccountUpgradeDialogClose,
  handleUpgradeClick,
}) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Dialog
      open={accountUpgradeDialog}
      onClose={handleAccountUpgradeDialogClose}
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{
            fontWeight: 700,
            fontSize: 16
          }}>{'Upgrade'}</span>
          <IconButton onClick={handleAccountUpgradeDialogClose} edge="end" color="inherit" aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <Grid>
        <Divider />
      </Grid>
      <DialogContent>
        <Grid container>
          <Grid item md={12} sm={12} xs={12} sx={{
            paddingBottom: 4
          }}>
            <Typography sx={{
              fontSize: 13
            }}>
              Trading will be disabled for this account.
              Check your registered Email ID for further
              communication
            </Typography>
          </Grid>
       

          <Grid md={2.5} sm={2.5} xs={2.5} item>
          </Grid>

          <Grid item md={3} sm={3} xs={3} sx={{
            textAlign: 'center',
            paddingBottom: 3

          }}>
            <Button
              onClick={handleUpgradeClick}
              sx={{
                background: '#41AAAF',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#41AAAF',
                  color: '#ffffff'
                },
              }} fullWidth variant='contained'>Yes </Button>
          </Grid>
          <Grid md={1} sm={1} xs={1} item>
          </Grid>
          <Grid item sm={3} md={3} xs={3} sx={{
            textAlign: 'center',
            paddingBottom: 3

          }}>
            <Button
              onClick={handleAccountUpgradeDialogClose}
              sx={{
                background: colors.red,
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: colors.red,
                  color: '#ffffff'
                },
              }} fullWidth variant='contained'>No </Button>
          </Grid>

          <Grid md={2.5} sm={2.5} xs={2.5} item>
          </Grid>
        </Grid>


      </DialogContent>
    </Dialog>
  );
};

export default AccountUpgradeDialog;