import {
  Divider,
  Typography,
  useTheme,
  Skeleton,
} from '@mui/material';
import { tokens } from '../../theme';

import {
  Grid
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getUser, logoutUser } from '../../state/actions/auth.actions';
import { getAccountBalance } from '../../state/actions/user.actions';
import CustomerTopbar from '../global/CustomerTopbar';
import InstrumentList from '../instrument/InstrumentList';
import AccountCardSection from './Sections/AccountCardSection';
import ChartSection from './Sections/ChartSection';
import PaperCardsSection from './Sections/PaperCardsSection';
import TargetSection from './Sections/TargetSection';
import { useUserContext } from '../../UserContext';

const Dashboard = () => {
  const theme = useTheme();


  const colors = tokens(theme.palette.mode);

  const location = useLocation();
  const navigate = useNavigate();
  const { accountContext, userContext, updateAccountContext, updateUserContext } = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams({});
  const userState = useSelector((state) => state.auth?.user);

  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');
  const accountId = queryParams.get('accountId');

  const dispatch = useDispatch();

  const accountBalancesState = useSelector((state) => state.user?.accountBalances);
  const auth = useSelector((state) => state.auth);
  const [totalProfitLoss, setTotalProfitLoss] = useState(0);
  const [positionAndOpenOrderTotal, setPositionAndOpenOrderTotal] = useState(0);

  const [isProfitReached, setIsProfitReached] = useState(false);
  const [hasOpenOrders, setHasOpenOrders] = useState(false);
  const [hasOpenPositions, setHasOpenPositions] = useState(false);
  const [cashBalance, setCashBalance] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [user, setUser] = useState();

  const [instruments, setInstruments] = useState([]);



  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    let _openPositions = accountBalancesState?.positions;
    let _totalProfitLoss = 0;
    if (accountBalancesState?.positions?.length) {
      for (let item of _openPositions) {
        let profitLoss = 0;
        profitLoss = getProfitLoss(item?.instrument, item?.price, item?.quantity, item?.status, item?.executedQuantity, item?.mode, item?.average);
        profitLoss = isNaN(profitLoss) ? 0 : profitLoss;
        _totalProfitLoss += profitLoss;
      }
    }
    setCashBalance(accountBalancesState?.cashBalance);
    setPositionAndOpenOrderTotal(accountBalancesState?.positionsTotal +
      accountBalancesState?.openOrdersTotal);
    setTotalProfitLoss(_totalProfitLoss);
    let requiredProfit = getProfitTargetValue();
    let accountSize = selectedAccount?.amount;
    let overallProfitAmount = cashBalance - accountSize;
    if (requiredProfit < overallProfitAmount) {
      setIsProfitReached(true);
    } else {
      setIsProfitReached(false);
    }
    if (accountBalancesState?.openOrdersTotal > 0) {
      setHasOpenOrders(true);
    } else {
      setHasOpenOrders(false);
    }

    if (accountBalancesState?.positionsTotal > 0) {
      setHasOpenPositions(true);
    } else {
      setHasOpenPositions(false);
    }
  }, [accountBalancesState, instruments, selectedAccount, user]);


  useEffect(() => {
    if (userState?.user) {
      updateAccountContext(
        userState?.user?.accounts?.find((account) => account?._id == selectedAccount?._id)
      );
    }
  }, [userState])

  function getProfitTargetValue() {
    return (selectedAccount?.profitTarget / 100) * selectedAccount?.amount;
  }

  useEffect(() => {
    if (auth?.user?.user) {
      setUser(auth?.user?.user);
    }
  }, [auth])

  useEffect(() => {
    if (accountContext) {
      setSelectedAccount(accountContext);
      setSearchParams({ accountId: accountContext?._id, userId: userId });
    }
    _getAccountBalance();
  }, [accountContext])

  useEffect(() => {
    if (userContext) {
      setUser(userContext);
    }
  }, [userContext])

  function _getAccountBalance() {
    if (accountContext?._id) {
      dispatch(getAccountBalance({ accountId: accountContext?._id }));
    }
  }

  useEffect(() => {
    _getAccountBalance();
    const checkIsMobile = () => {
      const mobileMediaQuery = window.matchMedia('(max-width: 767px)');
      setIsMobile(mobileMediaQuery.matches);
    };

    checkIsMobile();
    const resizeListener = () => {
      checkIsMobile();
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };

  }, []);


  function getProfitLoss(instrument, praice, quantity, status, executedQuantity, mode, averagePrice) {
    let _instrument = instruments?.find((item) => item?.[0] == instrument);
    if (mode === 1) {
      if (status === 6) {
        let openQuantity = quantity - executedQuantity;
        return (Number(_instrument?.[1]) * openQuantity) - Number(averagePrice * openQuantity);
      } else {
        return (Number(_instrument?.[1]) * quantity) - Number(averagePrice * quantity);
      }
    } else {
      if (status === 6) {
        let openQuantity = quantity - executedQuantity;
        let value = (Number(_instrument?.[1]) * openQuantity) - Number(averagePrice * openQuantity);
        value = value * -1;
        return value;
      } else {
        let value = (Number(_instrument?.[1]) * quantity) - Number(averagePrice * quantity);
        return value * -1;
      }
    }
  }


  function onBuySellChange() {
    _getAccountBalance();
    dispatch(getUser({ userId }))
  }


  const onInstrumentsChange = (instruments) => {
    setInstruments(instruments);
  };


  return (
    <>
      <CustomerTopbar user={auth?.user?.user} account={selectedAccount} />
      <div style={{
        paddingTop: isMobile ? '114px' : '60px',

        display: 'flex', height: '100vh', backgroundColor: colors.dashboard.background

      }}>
        <InstrumentList isMobile={location?.state?.page == 'Symbols' && isMobile ? true : false} user={user} account={selectedAccount} onInstrumentsChange={onInstrumentsChange} onBuySellChange={onBuySellChange} />

        <Grid
          style={{
            paddingLeft: 18,
            flex: 2,
            overflowX: 'auto',
          }}
          sx={{
            display: {
              xs: location?.state?.page == 'Symbols' && isMobile ? 'none' : 'block',
            }
          }}
          pt={2}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          {!user ? <Skeleton animation="wave" width={190} height={70} sx={{
            backgroundColor: colors.dashboardCards.background,
          }} /> :
            <Typography
              sx={{}}
              style={{
                fontWeight: 600,
                fontSize: 24,
                paddingBottom: 34,
              }}
            >
              {' '}
              Hi, {user?.firstName + ' ' + user?.lastName}
            </Typography>}
          <TargetSection account={selectedAccount} user={user} cashBalance={cashBalance} currentPositionAndOpenOrderBalance={positionAndOpenOrderTotal + totalProfitLoss} />

          {selectedAccount ?
            <AccountCardSection account={selectedAccount} user={user} isProfitReached={isProfitReached} hasOpenOrders={hasOpenOrders} hasOpenPositions={hasOpenPositions} /> :
            <Skeleton variant="rectangular" width={'98%'} height={'160px'} sx={{
              backgroundColor: colors.dashboardCards.background,
              marginTop: '30px',
              borderRadius: '10px'
            }} />
          }
          <br />
          <Grid pt={2} pb={1}>
            <Divider />
          </Grid>
          <br />
          <Typography sx={{ fontWeight: '600', fontSize: '18px', paddingBottom: '20px' }}>Trading Objective:</Typography>
          <PaperCardsSection account={selectedAccount} user={user} cashBalance={cashBalance} currentPositionAndOpenOrderBalance={positionAndOpenOrderTotal + totalProfitLoss} />
          <br />
          <br />
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <ChartSection account={selectedAccount} user={user} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
