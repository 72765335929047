
import React from 'react';
// import { useSocket } from '../../SocketContext';
import { io } from 'socket.io-client';
import { SOCKET_URL } from '../../config';

const socket = io(SOCKET_URL, {
    transportOptions: {
        polling: {
            extraHeaders: {},
        },
    },
});


const Privacy = (props) => {

    return (
        <div style={{
            padding: 70,
            backgroundColor:'#00002a'
        }}>

            <>
                <div style={{ textAlign: 'start', border: '0px' }}>
                    <h1 style={{ color: '#FFFFFF', fontSize: '60px', border: '0px' }}>Privacy<span style={{ color: 'rgb(var(--color_41))', border: '0px' }}>.</span></h1>
                </div>
                <div
                    style={{
                        textAlign: "start",
                        color: "rgb(0, 0, 0)",
                        fontSize: 10,
                        border: 0
                    }}
                >
                    <div
                        style={{
                            textAlign: "start",
                            color: "rgb(0, 0, 0)",
                            fontSize: 10,
                            border: 0
                        }}
                    >
                        <div style={{ border: 0 }}>
                            <div style={{ border: 0 }}>
                                <div style={{ textAlign: "start", border: 0 }}>
                                    <div
                                        style={{
                                            textAlign: "start",
                                            color: "rgb(0, 0, 0)",
                                            fontSize: 10,
                                            border: 0
                                        }}
                                    >
                                        <div style={{ border: 0 }}>
                                            <div style={{ border: 0 }}>
                                                <div style={{ textAlign: "start", border: 0 }}>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            1. GENERAL
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        a. This Website with the URL of{" "}
                                                                        <a
                                                                            href="http://www.uctrader.in/"
                                                                            target="_blank"
                                                                            style={{ color: "inherit", border: 0 }}
                                                                        >
                                                                            www.uctrader.in
                                                                        </a>{" "}
                                                                        ("Website/Site") is operated by Hash Gametech Pvt.
                                                                        Ltd. ("We/Our/Us"). We are committed to protecting
                                                                        and respecting your privacy. We collect your
                                                                        personal information and process your personal
                                                                        data in accordance with the IT Act, 2000 (21 of
                                                                        2000) and other national and state laws that
                                                                        relate to the processing of personal data. Please
                                                                        read the following carefully to understand our
                                                                        views and practices regarding your personal data.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        b. We collect your personal information in order
                                                                        to provide and continually improve our products
                                                                        and services.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        c. Our privacy policy is subject to change at any
                                                                        time without notice. To make sure you are aware of
                                                                        any changes, please review this policy
                                                                        periodically.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        d. All partner firms and any third-party working
                                                                        with or for Us, and who have access to personal
                                                                        information, will be expected to read and comply
                                                                        with this policy. No third party may access, or
                                                                        process sensitive personal information held by Us
                                                                        without having first entered into a
                                                                        confidentiality agreement.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            2. HOW WE COLLECT THE INFORMATION
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        a. From you directly and through this Site: We may
                                                                        collect information through the Website when you
                                                                        visit. The data we collect depends on the context
                                                                        of your interactions with our Website.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        b. Through business interaction: We may collect
                                                                        information through business interaction with you
                                                                        or your employees.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        c. From other sources: We may receive information
                                                                        from other sources, such as public databases;
                                                                        joint marketing partners; social media platforms;
                                                                        or other third parties such as:
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        I. Information about your interactions with the
                                                                        products and services offered by our subsidiaries.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            3. INFORMATION WE COLLECT
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        a. We collect information primarily to provide
                                                                        better services to all of our customers.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        b. We collect the following information from you
                                                                        when you use or signup on our Website:
                                                                    </p>
                                                                    <ul
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <li style={{ border: 0 }}>
                                                                            <p
                                                                                style={{
                                                                                    color: "#FFFFFF",
                                                                                    fontSize: 16,
                                                                                    border: 0
                                                                                }}
                                                                            >
                                                                                Name
                                                                            </p>
                                                                        </li>
                                                                        <li style={{ border: 0 }}>
                                                                            <p
                                                                                style={{
                                                                                    color: "#FFFFFF",
                                                                                    fontSize: 16,
                                                                                    border: 0
                                                                                }}
                                                                            >
                                                                                Email
                                                                            </p>
                                                                        </li>
                                                                        <li style={{ border: 0 }}>
                                                                            <p
                                                                                style={{
                                                                                    color: "#FFFFFF",
                                                                                    fontSize: 16,
                                                                                    border: 0
                                                                                }}
                                                                            >
                                                                                Address
                                                                            </p>
                                                                        </li>
                                                                        <li style={{ border: 0 }}>
                                                                            <p
                                                                                style={{
                                                                                    color: "#FFFFFF",
                                                                                    fontSize: 16,
                                                                                    border: 0
                                                                                }}
                                                                            >
                                                                                Phone Number
                                                                            </p>
                                                                        </li>
                                                                        
                                                                        <li style={{ border: 0 }}>
                                                                            <p
                                                                                style={{
                                                                                    color: "#FFFFFF",
                                                                                    fontSize: 16,
                                                                                    border: 0
                                                                                }}
                                                                            >
                                                                                KYC
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        c. We do collect the following sensitive personal
                                                                        information from you when you use or sign up on
                                                                        our Website:
                                                                    </p>
                                                                    <ul
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                      
                                                                        <li style={{ border: 0 }}>
                                                                            <p
                                                                                style={{
                                                                                    color: "#FFFFFF",
                                                                                    fontSize: 16,
                                                                                    border: 0
                                                                                }}
                                                                            >
                                                                                KYC
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        d. When you visit our Site, some information is
                                                                        automatically collected. This may include
                                                                        information such as the Operating System (OS)
                                                                        running on your device, Internet Protocol (IP)
                                                                        address, access times, browser type, and language,
                                                                        and the website you visited before our Site. We
                                                                        also collect information about how you use Our
                                                                        products or services.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        e. We automatically collect purchase or content
                                                                        use history, which we sometimes aggregate with
                                                                        similar information from other customers to create
                                                                        features such as Best Seller, Top Rated, etc.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        f. The full Uniform Resource Locators (URL)
                                                                        clickstream to, through and from our website
                                                                        (including date and time); cookie number; products
                                                                        and/or content you viewed or searched for; page
                                                                        response times; download errors; length of visits
                                                                        to certain pages; page interaction information
                                                                        (such as scrolling, clicks, and mouseovers).
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        g. We automatically collect information using
                                                                        "Cookies". Cookies are small data files stored on
                                                                        your hard drive. Among other things, cookies help
                                                                        us improve our Site, our marketing activities, and
                                                                        your experience. We use cookies to see which areas
                                                                        and features are popular and to count visits to
                                                                        our Site.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        h. Most Web browsers are set to accept cookies by
                                                                        default. If you prefer, you can choose to set your
                                                                        browser to remove cookies and to reject cookies.
                                                                        If you set your browser to reject cookies, some
                                                                        features will be unavailable. For more information
                                                                        on how to reject cookies, see your browser's
                                                                        instructions on 3/8 changing your cookie settings.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        i. By using this Website, you are agreeing that We
                                                                        may advertise your feedback on the Website and
                                                                        marketing materials.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        j. We will retain your information as long as we
                                                                        require this to provide you with the goods and
                                                                        services and for such period as mandated by the
                                                                        laws concerned.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        k. If you opt to receive marketing correspondence
                                                                        from us, subscribe to our mailing list or
                                                                        newsletters, enter into any of our competitions or
                                                                        provide us with your details at networking events,
                                                                        we may use your personal data for our legitimate
                                                                        interests in order to provide you with details
                                                                        about our goods, services, business updates and
                                                                        events.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            4. HOW WE USE INFORMATION
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        a. We use the information we collect primarily to
                                                                        provide, maintain, protect, and improve our
                                                                        current products and services.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        b. We use the information collected through this
                                                                        website as described in this policy and we may use
                                                                        your information to:
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        I. Improve our services, Site and how we operate
                                                                        our businesses;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        II. Understand and enhance your experience using
                                                                        our Site, products, and services;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        III. Personalize our products or services and make
                                                                        recommendations;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        IV. Provide and deliver the products and services
                                                                        you request;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        V. Process, manage, complete, and account for
                                                                        transactions;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        VI. Provide customer support and respond to your
                                                                        requests, comments, and inquiries;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        VII. Create and manage the online accounts you
                                                                        manage on our Website;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        VIII. Send you related information, including
                                                                        confirmations, invoices, technical notices,
                                                                        updates, security alerts and support and
                                                                        administrative messages;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        IX. Communicate with you about promotions,
                                                                        upcoming events, and news about products and
                                                                        services;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        X. We may process your personal information
                                                                        without your knowledge or &nbsp;consent where
                                                                        required by applicable law or regulation for the
                                                                        purposes of verification of identity or for
                                                                        prevention, detection, or investigation, including
                                                                        of cyber incidents, prosecution, and punishment of
                                                                        offences;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        XI. Protect, investigate, and deter against
                                                                        fraudulent, unauthorized, or illegal activity.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            5. DATA TRANSFER
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        a. Information about our users is an important
                                                                        part of our business and we take due care to
                                                                        protect the same.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        b. We share your data with your consent to
                                                                        complete any transaction or provide any product or
                                                                        service you have requested or authorized. We also
                                                                        share data with our affiliates and subsidiaries,
                                                                        with vendors working on our behalf.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        c. We may employ other companies and individuals
                                                                        to perform functions on our behalf. The functions
                                                                        include fulfilling orders for products or
                                                                        services, delivering packages, sending postal mail
                                                                        and e-mail, removing repetitive information from
                                                                        customer lists, providing marketing assistance,
                                                                        providing search results and links (including paid
                                                                        listings and links),
                                                                        transmitting content, and
                                                                        providing customer service.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        d. These third-party service providers have access
                                                                        to personal information needed to perform their
                                                                        functions but may not use it for other purposes.
                                                                        Further, they must process the personal
                                                                        information in accordance with this Privacy Policy
                                                                        and as permitted by applicable data protection
                                                                        laws.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        e. We release accounts and other personal
                                                                        information when we believe it is appropriate to
                                                                        comply with the law, enforce or apply our
                                                                        conditions of use, and other agreements, and
                                                                        protect the rights, property or safety of Us, our
                                                                        users, or others. This includes exchanging
                                                                        information with other companies and organizations
                                                                        for fraud protection and credit risk reduction.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            6. COOKIES
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        a. To optimize our web presence, we use cookies.
                                                                        These are small text files stored on your
                                                                        computer's main memory. These cookies are deleted
                                                                        after you close the browser. Other cookies remain
                                                                        on your computer (long-term cookies) and permit
                                                                        its recognition on your next visit. This allows us
                                                                        to improve your access to our site. This helps us
                                                                        to learn more about your interests and provides
                                                                        you with essential features and services,
                                                                        including:&nbsp;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        I. Keeping track of items stored in your shopping
                                                                        basket.
                                                                    </p>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        II. Conducting research and diagnostics to improve
                                                                        the content, products, and services.
                                                                    </p>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        III. Preventing fraudulent activity.
                                                                    </p>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        IV. Improving security.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        b. Our cookies allow you to take advantage of some
                                                                        of our essential features. For instance, if you
                                                                        block or otherwise reject our cookies, you will
                                                                        not be able to add items to your shopping basket,
                                                                        proceed to checkout, or use any products or
                                                                        services that require you to sign in.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        c. Approved third parties also may set cookies
                                                                        when you interact with Our services.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        d. Third parties include search engines, providers
                                                                        of measurement and analytics services, social
                                                                        media networks, and advertising companies.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        e. Third parties use cookies in the process of
                                                                        delivering content, including ads relevant to your
                                                                        interests, to measure the effectiveness of their
                                                                        ads, and to perform services on behalf of Us.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        f. You can prevent the storage of cookies by
                                                                        choosing a "disable cookies" option in your
                                                                        browser settings. But this can limit the
                                                                        functionality of our services.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            7. DATA SECURITY
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        a. We take due care to protect customer data.
                                                                        Technical measures are in place to prevent
                                                                        unauthorized or unlawful access to data and
                                                                        against accidental loss or destruction of, or
                                                                        damage to, data. The employees who are dealing
                                                                        with the data have been trained to protect the
                                                                        data from any illegal or unauthorized usage.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        b. We work to protect the security of your
                                                                        information during transmission by using Secure
                                                                        Sockets Locker (SSL) software, which encrypts the
                                                                        information you input. SSL allows sensitive
                                                                        information such as credit card numbers, UID's and
                                                                        login credentials to be transmitted securely.
                                                                    </p>
                                                                    
                                                                  
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        d. We maintain physical, electronic, and
                                                                        procedural safeguards in connection with the
                                                                        collection, storage, and disclosure of personal
                                                                        customer information.&nbsp;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        e. We take reasonable steps to help protect your
                                                                        personal information in an effort to prevent loss,
                                                                        misuse, unauthorized access, disclosure alteration
                                                                        and destruction. It is your responsibility to
                                                                        protect your user names and passwords to help
                                                                        prevent anyone from accessing or abusing your
                                                                        accounts and services. You should not use or reuse
                                                                        the same passwords you use with other accounts as
                                                                        your password for our services.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        f. It is important for you to protect against
                                                                        unauthorized access to your password and your
                                                                        computers, devices, and applications. Be sure to
                                                                        sign off when you finish using a shared computer.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        g. The information you provide to us is shared on
                                                                        our secure servers. We have implemented
                                                                        appropriate physical, technical and organizational
                                                                        measures designed to secure your information
                                                                        against accidental loss and unauthorized access,
                                                                        use, alteration, or disclosure. In addition, we
                                                                        limit access to personal data to those employees,
                                                                        agents, contractors, and other third parties that
                                                                        have a legitimate business need for such access.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        h. Information collected from you will be stored
                                                                        for such period as required to complete the
                                                                        transaction entered into with you or such period
                                                                        as mandated under the applicable laws.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            8. LINKS TO THIRD-PARTY SITES/APPS
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        Our Site may, from time to time, contain links to
                                                                        and from other websites of third parties. Please
                                                                        note that if you follow a link to any of these
                                                                        websites, such websites will apply different terms
                                                                        to the collection and privacy of your personal
                                                                        data, and we do not accept any responsibility or
                                                                        liability for these policies. When you leave our
                                                                        Site, we encourage you to read the privacy policy
                                                                        of every website you visit.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            9. SOCIAL NETWORK PLUGINS
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        This Website incorporates plugins and/or buttons
                                                                        for social networks, in order to allow easy
                                                                        sharing on your favourite social networks. These
                                                                        plugins are programmed so as not to set any
                                                                        cookies when assessing the page to safeguard the
                                                                        privacy of users. Cookies may be set if you make
                                                                        voluntary use of the plugin. The collection and
                                                                        use of information obtained by means of the plugin
                                                                        are governed by the respective privacy policies of
                                                                        the social networks.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            10. SHARING OF PERSONAL INFORMATION
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        a. We do not share your personal data with third
                                                                        parties without your prior consent other than:
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        I. With third parties who work on our behalf
                                                                        provided such third parties adhere to the data
                                                                        protection principles set out in the IT Act, 2000
                                                                        (21 of 2000) and other applicable legislation, or
                                                                        enter into a written agreement with Us requiring
                                                                        that the third party provide at least the same
                                                                        level of privacy protection as is required by such
                                                                        principles;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        II. To comply with laws or to respond to lawful
                                                                        requests and legal process;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        III. To protect the rights and property of Us, our
                                                                        agents, customers, and others including to enforce
                                                                        our agreements, policies, and terms of use;
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        IV. In an emergency, including to protect the
                                                                        personal safety of any person; and
                                                                    </p>
                                                                    
                                                                   
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            11. CHILDREN
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        If you are under 18, or the age of majority in the
                                                                        jurisdiction in which you reside, you may only use
                                                                        Our Website with the consent of your parent or
                                                                        legal guardian. In any case, We will not be liable
                                                                        for any cause of action that arose due to
                                                                        noncompliance with this section.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            12. YOUR INFORMATION CHOICES AND CHANGES
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        a. You can also make choices about the collection
                                                                        and processing of your data by Us. You can access
                                                                        your personal data and opt-out of certain services
                                                                        provided by the Us. In some cases, your ability to
                                                                        control and access your data will be subject to
                                                                        applicable laws.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        b. You may opt-out of receiving promotional emails
                                                                        from Us by following the instructions in those
                                                                        emails. If you opt-out, we may still send you
                                                                        nonpromotional emails, such as emails about our
                                                                        ongoing business relationship. You may also send
                                                                        requests about you got preferences, changes and
                                                                        deletions to your information including requests
                                                                        to opt-out of sharing your personal information
                                                                        with third parties by sending an email to the
                                                                        email address provided at the bottom of this
                                                                        document.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            13. CHANGES TO THIS POLICY
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        We may change this policy from time to time. If we
                                                                        make any changes to this policy, we will change
                                                                        the "Last Updated" date above. You agree that your
                                                                        continued use of our services after such changes
                                                                        have been published to our services will
                                                                        constitute your acceptance of such revised policy.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            textAlign: "start",
                                                            color: "rgb(0, 0, 0)",
                                                            fontSize: 10,
                                                            border: 0
                                                        }}
                                                    >
                                                        <div style={{ border: 0 }}>
                                                            <div style={{ border: 0 }}>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <h4
                                                                        style={{
                                                                            color: "#FFFFFF",
                                                                            fontSize: 30,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 30, border: 0 }}>
                                                                            14. NEWSLETTER
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                                <div style={{ textAlign: "start", border: 0 }}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        a. Following your subscription to the newsletter,
                                                                        your e-mail address is used for our advertising
                                                                        purposes until you cancel the newsletter again.
                                                                        Cancellation is possible at any time. The
                                                                        following consent has been expressly granted by
                                                                        you separately, or possibly in the course of an
                                                                        ordering process: (I am accepting to receive
                                                                        newsletters from this website), you may revoke
                                                                        your consent at any time with future effect. If
                                                                        you no longer want to receive the newsletter, then
                                                                        unsubscribe by clicking on the unsubscribe option
                                                                        given in the email footer.
                                                                    </p>
                                                                    
                                                                    <p
                                                                        style={{
                                                                            textAlign: "justify",
                                                                            color: "#FFFFFF",
                                                                            fontSize: 16,
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        If you have any concerns about privacy or
                                                                        grievances with Us, please contact us with a
                                                                        thorough description and we will try to resolve
                                                                        the issue for you.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        textAlign: "start",
                        color: "rgb(0, 0, 0)",
                        fontSize: 10,
                        border: 0
                    }}
                >
                    <br />
                </div>
            </>

        </div>    
    );
};

export default Privacy;
