import React, {
  createContext, useContext,
  useState,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from './state/actions/auth.actions'
import { useLocation, useNavigate } from 'react-router-dom';


const UserProviderContext = createContext();

export const useUserContext = () => {
  return useContext(UserProviderContext);
};

export const UserProvider = ({ children }) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const userId = queryParams.get('userId');
  const accountId = queryParams.get('accountId');


  const [accountContext, setAccountContext] = useState(null);
  const [userContext, setUserContext] = useState(null);
  const getUserState = useSelector((state) => state.auth?.user);


  useEffect(() => {
    if (userId) {
      dispatch(getUser({ userId }));
    }
  }, []);

  useEffect(() => {
    if (getUserState?.user && !accountContext) {
      setUserContext(getUserState?.user);
      let account = getUserState?.user?.accounts?.find((item) => item._id == accountId);
      if (!account) {
        account = getUserState?.user?.accounts?.find((item) => item?.selected);
      }
      setAccountContext(account);

    }
  }, [getUserState]);


  const updateUserContext = (newUser) => {
    setUserContext(newUser);
  };

  const updateAccountContext = (newAccount) => {
    setAccountContext(newAccount);
  };

  return (
    <UserProviderContext.Provider value={{ updateAccountContext, updateUserContext, userContext, accountContext  }}>
      {children}
    </UserProviderContext.Provider>
  );
};
