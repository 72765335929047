import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';


export default function PieChartComponent({ data, options }) {
  return (
    <div>
      <PieChart style={{
        padding: 14
      }} 
      width={ 100} height={ 110} >
        <Pie
          data={data}
          dataKey="value"
          strokeWidth={0}
          innerRadius={38}
          outerRadius={50}
          fill="#000000"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
}
