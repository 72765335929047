import React, { useEffect, useState } from 'react';

import {
  Breadcrumbs,
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Link,
  Paper, Popover,
  TextField,
  Typography
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUsers, updateAccount } from '../../../state/actions/admin.actions';
import { getPlans } from '../../../state/actions/common.actions';
import AdminTopBar from '../../global/AdminTopbar';
import { formatPrice}  from '../../../utils/string'
import _ from 'lodash';
import RArrowImage from '../../../assets/r-arrow.png';

const AccountDetails = () => {
  const location = useLocation();
  const { accountData = null, accountNumber = '' } = location.state ?? {};
  const [plans, setPlans] = useState([]);
  const [declineReasonText, setDeclineReasonText] = useState('');



  const [account, setAccount] = useState({
    accountNumber: accountNumber,
    userId: '',
    username: '',
    plan: '',
    planAlias: '',
    accountType: '',
    accountAlias: '',
    accountSize: '',
    amount: '',
    dll: '',
    tll: '',
    profitTarget: '',
    tradeSquareOff: '',
    validityDays: '',
  });


  const [deleteConfirmationAnchorEl, setDeleteConfirmationAnchorEl] = React.useState(null);

  const navigate = useNavigate();


  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);

  const _getUsers = () => {
    dispatch(getUsers({
      page: 1, limit: 100
    }));
  };

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    setAccount(accountData);
  }, [location]);

  useEffect(() => {
    if (common?.plans?.plans?.length) {
      setPlans(common?.plans);
    }
  }, [common]);

  useEffect(() => {
    dispatch(getPlans({}));
    _getUsers();
  }, []);

  const handleAccountEditOpen = () => {
    navigate('/admin-account', {
      state: {
        editing: true,
        accountData: {
          ...account,
        }
      }
    });
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationAnchorEl(null);
  };

  const handleDeleteAccount = (row) => {
    let data = {
      ...row, active: false, deleted: true
    };
    dispatch(updateAccount(_.pick(data, [
      'accountNumber',
      'userId',
      'username',
      'plan',
      'planAlias',
      'accountType',
      'accountAlias',
      'accountSize',
      'amount',
      'status',
      'dll',
      'tll',
      'profitTarget',
      'tradeSquareOff',
      'validityDays',
      'active',
      'declineReason',
      'deleted',
      '_id'
    ])));
    setDeleteConfirmationAnchorEl(null);
    navigate('/admin-accounts');
  };


  const handleUserDeleteOpen = (event, row) => {
    setDeleteConfirmationAnchorEl(event.currentTarget);
  };

  const handleDeclineAccount = () => {
    setOpenDeclineDialog(true);
  };


  const handleApproveAccount = () => {
    let data = {
      ...account, status: 1
    };
    dispatch(updateAccount(_.pick(data, [
      'accountNumber',
      'userId',
      'username',
      'plan',
      'planAlias',
      'accountType',
      'accountAlias',
      'accountSize',
      'amount',
      'dll',
      'status',
      'tll',
      'profitTarget',
      'tradeSquareOff',
      'validityDays',
      'active',
      'declineReason',
      'deleted',
      '_id'
    ])));
    navigate('/admin-accounts');
  };


  const [openDeclineDialog, setOpenDeclineDialog] = React.useState(false);


  const handleDeclineDialogClose = () => {
    setOpenDeclineDialog(false);
  };

  const handleDeclineConfirm = () => {
    let data = {
      ...account.accounts, status: 3, declineReason: declineReasonText
    };
    dispatch(updateAccount(_.pick(data, [
      'accountNumber',
      'userId',
      'username',
      'plan',
      'planAlias',
      'accountType',
      'accountAlias',
      'accountSize',
      'amount',
      'dll',
      'status',
      'tll',
      'profitTarget',
      'tradeSquareOff',
      'validityDays',
      'active',
      'deleted',
      'declineReason',
      '_id'
    ])));
    navigate('/admin-accounts');
  };

  const handleDeclineReasonText = (e) => {
    setDeclineReasonText(e.target.value);
  };

  const open = Boolean(deleteConfirmationAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <AdminTopBar />
      <Grid sx={{
        p: 3,
        display: {
          sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
        },
        alignItems: 'center',
        justifyContent: 'space-between'
      }} item xs={12} md={12} lg={12} sm={12} mb={2}>
        <div>
          <Breadcrumbs sx={{
            pl: 1, pt: 1,
          }} separator={<img alt='img' style={{
            width: 9
          }} src={RArrowImage} />} aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" href="/admin-accounts">
              <Typography sx={{
                fontSize: 17,
                fontWeight: 600
              }}>Accounts</Typography>
            </Link>
            <Link underline="hover" key="1" color="inherit" href="/admin-accounts">
              <Typography sx={{
                fontSize: 17,
                fontWeight: 600,
                color: '#41AAAF'
              }}>Account Details</Typography>
            </Link>
          </Breadcrumbs>
        </div>

        <div style={{
          display: 'flex',
          width: user.role === 'SUPERADMIN' && [2, 4].includes(account?.status) ? 520 : 280
        }}>
          {user.role === 'SUPERADMIN' && account?.status === 2 && <><Button
            fullWidth
            onClick={() => handleApproveAccount(account)}
            sx={{
              background: '#41AAAF',
              color: '#ffffff',
              fontSize: 10,
              marginLeft: 2,
              '&:hover': {
                backgroundColor: '#41AAAF',
                color: '#ffffff'

              },
              '&:disabled': {
                backgroundColor: 'gray',
                color: '#ffffff'
              },
            }} type='contained' >Approve</Button>
          <Button
            fullWidth
            onClick={() => handleDeclineAccount(account)}
            sx={{
              marginLeft: 2,
              fontSize: 10,

              background: '#FF4757',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#FF4757',
                color: '#ffffff'
              },
            }} type='contained' >Decline</Button>

          <Dialog open={openDeclineDialog} onClose={handleDeclineDialogClose}>
            <DialogTitle>Decline Reason</DialogTitle>
            <DialogContent >
              <TextField
                sx={{
                  width: 470
                }}
                value={declineReasonText}
                onChange={handleDeclineReasonText}
                autoFocus
                margin="dense"
                id="name"
                label="Enter decline reason"
                type="text"
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeclineDialogClose}>Cancel</Button>
              <Button onClick={handleDeclineConfirm}>Confirm</Button>
            </DialogActions>
          </Dialog>
          </>
          }
          {user.role === 'SUPERADMIN' && account?.status === 4 && <><Button
            fullWidth
            onClick={() => handleDeleteAccount(account)}
            sx={{
              background: '#41AAAF',
              color: '#ffffff',
              fontSize: 10,
              marginLeft: 2,
              '&:hover': {
                backgroundColor: '#41AAAF',
                color: '#ffffff'

              },
              '&:disabled': {
                backgroundColor: 'gray',
                color: '#ffffff'
              },
            }} type='contained' >Confirm Delete</Button>
          </>
          }
          <Button
            fullWidth
            onClick={handleUserDeleteOpen}
            sx={{
              fontSize: 10,
              marginLeft: 2,
              background: '#FF4757',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#FF4757',
                color: '#ffffff'
              },
            }} type='contained' >Delete</Button>
          <Popover
            sx={{
              '& .MuiPaper-root': {
                boxShadow: 'none',
              },
            }}
            id={id}
            open={open}
            anchorEl={deleteConfirmationAnchorEl}
            onClose={handleDeleteConfirmationClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div style={{
              margin: 8
            }}>
              <Typography sx={{
                fontWeight: 600,
                paddingBottom: 1
              }}>Are you sure?</Typography>
              <div style={{

              }}>
                <Button
                  onClick={() => handleDeleteAccount(account)}
                  sx={{
                    marginRight: 2,
                    color: '#ffffff',
                    background: '#41aaae',
                    borderColor: 'unset',
                    '&:hover': {
                      backgroundColor: '#41aaae',
                      color: '#ffffff'

                    },
                  }} variant="outlined">Confirm</Button>
                <Button
                  onClick={handleDeleteConfirmationClose}
                  sx={{
                    background: '#FF4757',
                    color: '#ffffff',
                    borderColor: 'unset',
                    '&:hover': {
                      backgroundColor: '#FF4757',
                      color: '#ffffff'

                    },

                  }} variant="outlined">Cancel</Button>
              </div>
            </div>
          </Popover>
          <Button
            fullWidth
            onClick={handleAccountEditOpen}
            sx={{
              background: '#41AAAF',
              fontSize: 10,
              color: '#ffffff',
              marginLeft: 2,
              '&:hover': {
                backgroundColor: '#41AAAF',
                color: '#ffffff'

              },
              '&:disabled': {
                backgroundColor: 'gray',
                color: '#ffffff'
              },
            }} type='contained' >Edit</Button>

        </div>

      </Grid>

      <div style={{
        paddingLeft: 27,
        paddingRight: 27,
      }}>
        <Paper >
          <Grid container p={3}>

            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                                Account Number
              </InputLabel>
              <Typography>{account?.accountNumber}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">User Name</InputLabel>
              <Typography>{account?.user?.username}</Typography>



            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">Plan</InputLabel>
              <Typography>{plans?.plans?.find(plan => plan._id === account?.plan).name}</Typography>

            </Grid>
            <Grid pb={5} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                                Plan Display Name
              </InputLabel>
              <Typography>{account?.planAlias}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                                Account Type
              </InputLabel>
              <Typography>{account?.accountType?.label}</Typography>

            </Grid>
            {account?.declineReason && <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                                Decline Reason
              </InputLabel>
              <Typography>{account?.declineReason}</Typography>
            </Grid>}
            <Grid pb={2} pr={2} item md={12} lg={12} sm={12} xs={12} >
              <Divider orientation />

            </Grid>


            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                                Display Name
              </InputLabel>

              <Typography>{account?.accountAlias}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                                Account Size
              </InputLabel>

              <Typography>{account?.accountSize?.label}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >

              <InputLabel shrink htmlFor="bootstrap-input">
                                Amount
              </InputLabel>

              <Typography>{formatPrice(account?.amount)}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                                Daily Loss Limit
              </InputLabel>

              <Typography>{account?.dll}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                                Total Loss Limit
              </InputLabel>

              <Typography>{account?.tll}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                                Profit Target
              </InputLabel>

              <Typography>{account?.profitTarget}</Typography>


            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                                Square Trade-off
              </InputLabel>


              <Typography>{account?.tradeSquareOff}</Typography>


            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                                Validity Days
              </InputLabel>
              <Typography>{account?.validityDays}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default AccountDetails;
