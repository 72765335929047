import React, { useEffect, useState } from 'react';

import { useTheme, Box, Select, TableSortLabel, CircularProgress, InputLabel, FormControl, MenuItem, Button, Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';

import { Search as SearchIcon } from '@mui/icons-material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, getUsers, updateUser, getAdminOrderTrades } from '../../../state/actions/admin.actions';
import AdminTopBar from '../../global/AdminTopbar';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import { S3_BASE_URL, REST_URL } from '../../../config';

import DeleteImage from '../../../assets/delete.png';
import EditImage from '../../../assets/edit.png';
import { Transition } from '../../../components/Transition';
import { formatPrice } from '../../../utils/string';
import DateRangePickerPopup from '../../../components/DateRangePicker';
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Trades = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [loading, setLoading] = useState(false);
  const [filterApply, setFilterApply] = useState(false);
  const [startDate, setStartDate] = useState(dayjs.utc());
  const [endDate, setEndDate] = useState(dayjs.utc());
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  function handleSort(property) {
    const direction = sortBy === property && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortBy(property);
    setSortDirection(direction);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    _getTrades();
  };


  const _getTrades = () => {
    setLoading(true);
    let params = {};
    if (sortBy && sortDirection) {
      params.sort = `${sortBy}|${sortDirection}`;
    }
    if (startDate) {
      params['startDate'] = dayjs(startDate).format('YYYY-MM-DD');
      params['endDate'] = dayjs(endDate).format('YYYY-MM-DD');
    }
    if (user) {
      params['userId'] = user?._id
    }
    if (account) {
      params['accountId'] = account?._id
    }
    dispatch(getAdminOrderTrades({
      page: page + 1, limit: rowsPerPage, status: '1,3,4,5,6,7', position: 1, expand: 'account,user',
      ...params
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    _getTrades();
  };

  const [orderTrades, setOrderTrades] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);
  const [searchText, setSearchText] = useState('');


  const dispatch = useDispatch();
  const orderTradesState = useSelector((state) => state.admin?.orderTrades);
  const usersState = useSelector((state) => state.admin?.users);


  useEffect(() => {
    if (orderTradesState?.trades?.length) {
      setOrderTrades(orderTradesState);
      setLoading(false);
    } else {
      setOrderTrades([]);
      setLoading(false);
    }
  }, [orderTradesState]);

  useEffect(() => {
    if (usersState?.users?.length) {
      setUsers(usersState);
    } else {
      setUsers([]);
    }
  }, [usersState]);



  useEffect(() => {
    _getTrades(page + 1, rowsPerPage);
  }, [page, rowsPerPage, account, user, sortBy, sortDirection, filterApply]);

  useEffect(() => {
    if (searchText?.length >= 1) {
      dispatch(getUsers({
        page: 1, limit: 100, q: searchText, roles: 'CUSTOMER'
      }));
    } else {
      dispatch(getUsers({
        page: 1, limit: 100, roles: 'CUSTOMER'
      }));
    }
  }, [searchText]);




  function getTradeStatusColor(status) {
    switch (status) {
      case 1:
        return colors.green;
      case 2:
        return colors.green;
      case 3:
        return colors.green;
      case 4:
        return '#FF4757';
      case 5:
        return '#FF4757';
      case 6:
        return colors.green;
      default:
        return '#FF4757';
    }
  }

  function getTradeStatus(status) {
    switch (status) {
      case 1:
        return 'Completed';
      case 2:
        return 'Open';
      case 3:
        return 'Completed';
      case 4:
        return 'Cancelled';
      case 5:
        return 'Rejected';
      case 6:
        return 'Partially Completed';
      default:
        return 'NA';
    }
  }

  function handleAccountChange(event) {
    setAccount(event?.target?.value);
  }

  async function handleDownload() {
    try {
      setLoading(true);
      let apiUrl = `${REST_URL}/trades/admin/orders?status=1,3,4,5,6,7&position=1&expand=account,user&download=1`;
      if (user) {
        apiUrl += `&userId=${user?._id}`
      }
      if (account) {
        apiUrl += `&accountId=${account?._id}`
      }
      if (startDate) {
        apiUrl += `&startDate=${dayjs(startDate).format('YYYY-MM-DD')}`
      }
      if (endDate) {
        apiUrl += `&endDate=${dayjs(endDate).format('YYYY-MM-DD')}`
      }
      const accessToken = localStorage.getItem('token');
      const response = await axios.get(apiUrl, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      console.log('response: ', response);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Trades-${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoading(false);
    } catch (error) {
      console.error('Error downloading XLSX file:', error);
    }
  }


  return (
    <>
      <AdminTopBar />
      <Grid container p={3}>

        <Grid sx={{
          display: {
            sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
          },
          alignItems: 'center',
          justifyContent: 'space-between'
        }} item xs={12} md={12} lg={12} sm={12} mb={2}>
          <div>
            <Typography sx={{
              fontSize: 18,
              color: '#656565',
              mt: 2,
              ml: 1,
              fontWeight: 600
            }}>Trades</Typography>
          </div>

          <DateRangePickerPopup
            startDate={startDate}
            endDate={endDate}
            onChange={(dateRange) => {
              setStartDate(dateRange?.startDate);
              setEndDate(dateRange?.endDate);
            }}
            closed={() => {
              setFilterApply(!filterApply);
            }}
          />
          <Button
            sx={{
              height: 35,
              width: { md: 150, lg: 150, sm: '75%', xs: '75%' },
              marginLeft: 5,
              backgroundColor: '#41AAAF',
              '&:hover': {
                backgroundColor: '#41AAAF',
              },
            }}
            fullWidth
            onClick={handleDownload}
            startIcon={<DownloadIcon />}
            variant="contained" >
            Download
          </Button>

          <Box sx={{
            display: {
              sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
            },
            alignItems: 'center'
          }}>
            <Autocomplete
              sx={{
                width: 300,
                mr: 3
              }}
              options={users?.users}
              onChange={(event, newValue) => {
                console.log('newValue: ', newValue);
                if (!newValue) {
                  setUser(newValue);
                  setAccount(newValue);
                } else {
                  setUser(newValue);
                }
              }}
              getOptionLabel={(option) => option.email}
              getOptionSelected={(option, value) => option._id === value._id}
              onInputChange={(event, value) => setSearchText(value)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="User" />
              )}
            />

            <FormControl>
              <InputLabel id="demo-simple-select-label">Account</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label='Account'
                sx={{
                  width: 200,
                }}
                onChange={handleAccountChange}
              >{!user?.accounts?.length ? (
                <MenuItem disabled>No User Selected</MenuItem>
              ) : user?.accounts?.map((account) => {
                return (
                  < MenuItem key={account?._id} value={account
                  }> {account?.accountNumber}</MenuItem>
                );
              })}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          {loading ?
            <div style={{
              marginTop: '50px',
              display: 'flex',
              justifyContent: 'center'
            }}>
              <CircularProgress color="inherit" />
            </div>
            :
            <TableContainer sx={{
              boxShadow: '0px 4px 12px 8px rgba(0, 0, 0, 0.1)'
            }} component={Paper}>
           
              <Table >
                <TableHead>
                  <TableRow sx={{
                    fontFamily: 'Poppins'
                  }}>
                    <TableCell sx={{
                      pl: 3,
                      color: '#747D8C'
                    }}>Account Number</TableCell>
                    <TableCell sx={{
                      color: '#747D8C'
                    }}>Order ID</TableCell>
                    <TableCell sx={{
                      color: '#747D8C'
                    }}><TableSortLabel
                      active={sortBy === 'createdAt'}
                      direction={sortBy === 'createdAt' ? sortDirection : 'asc'}
                      onClick={() => handleSort('createdAt')}
                    >
                        Time
                      </TableSortLabel></TableCell>
                    <TableCell sx={{
                      color: '#747D8C'
                    }}><TableSortLabel
                      active={sortBy === 'user.email'}
                      direction={sortBy === 'user.email' ? sortDirection : 'asc'}
                      onClick={() => handleSort('user.email')}
                    >
                        Email
                      </TableSortLabel></TableCell>
                    <TableCell sx={{
                      color: '#747D8C'
                    }}><TableSortLabel
                      active={sortBy === 'instrument'}
                      direction={sortBy === 'instrument' ? sortDirection : 'asc'}
                      onClick={() => handleSort('instrument')}
                    >
                        Instrument
                      </TableSortLabel></TableCell>
                    <TableCell sx={{
                      color: '#747D8C'
                    }}><TableSortLabel
                      active={sortBy === 'type'}
                      direction={sortBy === 'type' ? sortDirection : 'asc'}
                      onClick={() => handleSort('type')}
                    >
                        Type
                      </TableSortLabel></TableCell>
                    <TableCell sx={{
                      color: '#747D8C'
                    }}><TableSortLabel
                      active={sortBy === 'quantity'}
                      direction={sortBy === 'quantity' ? sortDirection : 'asc'}
                      onClick={() => handleSort('quantity')}
                    >
                        Quantity
                      </TableSortLabel></TableCell>
                    <TableCell sx={{
                      color: '#747D8C'
                    }}><TableSortLabel
                      active={sortBy === 'price'}
                      direction={sortBy === 'price' ? sortDirection : 'asc'}
                      onClick={() => handleSort('price')}
                    >
                        Price
                      </TableSortLabel></TableCell>
                    <TableCell sx={{
                      color: '#747D8C'
                    }}><TableSortLabel
                      active={sortBy === 'status'}
                      direction={sortBy === 'status' ? sortDirection : 'asc'}
                      onClick={() => handleSort('status')}
                    >
                        Status
                      </TableSortLabel></TableCell>
                  </TableRow>
                </TableHead>
                {orderTrades?.trades?.length ? <TableBody>
                  {orderTrades?.trades?.map((row) => {
                    return (
                      <TableRow key={row._id}>
                        <TableCell width={180} sx={{
                          pl: 3,
                          fontSize: 13,
                          fontWeight: 500,

                        }}>{row?.account?.accountNumber}</TableCell>
                        <TableCell width={180} sx={{
                          fontSize: 13,
                          fontWeight: 500,

                        }}>{row?.identifier}</TableCell>
                        <TableCell width={220} sx={{
                          fontSize: 13,
                          fontWeight: 500,

                        }}>{dayjs(row.createdAt).format('DD MMM hh:mm A')}</TableCell>
                        <TableCell width={180} sx={{
                          fontSize: 13,
                          fontWeight: 500,

                        }}>{row?.user?.email}</TableCell>
                        <TableCell width={180} sx={{
                          fontSize: 13,
                          fontWeight: 500,

                        }}>{row?.instrument}.x</TableCell>
                        <TableCell width={180} sx={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: row?.type == 1 ? colors.green : '#FF4757'

                        }}>{row.type == 1 ? 'BUY' : 'SELL'}</TableCell>
                        <TableCell width={180} sx={{
                          fontSize: 13,
                          fontWeight: 500,

                        }}>{row.quantity}</TableCell>
                        <TableCell width={180} sx={{
                          fontSize: 13,
                          fontWeight: 500,

                        }}>{formatPrice(Number(row?.price))}</TableCell>
                        <TableCell width={180} sx={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: getTradeStatusColor(row?.status),

                        }}>{getTradeStatus(row?.status)}</TableCell>




                      </TableRow>
                    );
                  })} </TableBody>
                  :
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{
                        fontSize: 13,
                        textAlign: 'center'
                      }} colSpan={9}>No Trades Found</TableCell>

                    </TableRow>
                  </TableBody>
                }

              </Table>
          
            </TableContainer>
          }
          {orderTrades?.meta && <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={orderTrades.meta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page:"
            nextIconButtonText="Next Page"
            backIconButtonText="Previous Page"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
            showFirstButton
            showLastButton

          />}
        </Grid>
      </Grid>
    </>
  );
};

export default Trades;
