import { Grid, Paper, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import CalImage from '../../../assets/cal.png';
import DailyImage from '../../../assets/daily.png';
import ProfitImage from '../../../assets/profit.png';
import TotalImage from '../../../assets/total.png';
import PieChartComponent from '../../../charts/Piechart';
import { tokens } from '../../../theme';
import { formatPrice } from '../../../utils/string';
import { MINIMUM_TRADING_DAYS } from '../../../config';


const PaperCardsSection = (props) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { cashBalance, user, account, currentPositionAndOpenOrderBalance } = props;

  const [overallProfit, setOverallProfit] = useState(0);
  const [requiredProfit, setRequiredProfit] = useState(0);
  const [totalLoss, setTotalLoss] = useState(0);
  const [dailyLoss, setDailyLoss] = useState(0);
  const [totalLossLimit, setTotalLossLimit] = useState(0);
  const [dailyLossLimit, setDailyLossLimit] = useState(0);


  function getProfitTargetValue() {
    return (account?.profitTarget / 100) * account?.amount;
  }

  function getDailyLossLimitValue() {
    return (account?.dll / 100) * account?.amount;
  }

  function getTotalLossLimitValue() {
    return (account?.tll / 100) * account?.amount;
  }

  useEffect(() => {
    const profitTarget = getProfitTargetValue();
    const dailyLossLimit = getDailyLossLimitValue();
    const totalLossLimit = getTotalLossLimitValue();
    setTotalLossLimit(totalLossLimit);
    setDailyLossLimit(dailyLossLimit);
    setRequiredProfit(profitTarget);
  }, [account]);


  useEffect(() => {
    let totalProfitAmount = (cashBalance + currentPositionAndOpenOrderBalance) - account?.credits?.total;
    const currentDayProfitAmount = account?.credits?.openingBalance - (cashBalance + currentPositionAndOpenOrderBalance) ;
    setTotalLoss(totalProfitAmount < 0 ? totalProfitAmount * -1 : 0);
    setDailyLoss(currentDayProfitAmount > 0 ? currentDayProfitAmount : 0);
    setOverallProfit(totalProfitAmount > 0 ? totalProfitAmount : 0);


  }, [currentPositionAndOpenOrderBalance, account, cashBalance]);


  return (
    <Grid style={{ backgroundColor: colors.dashboard.background }} container spacing={2}>
      <Grid style={{
        paddingRight: 16
      }} item xs={12} sm={12} md={6}  >
        <Paper style={{ height: '100%', borderRadius: '10px', padding: '14px', backgroundColor: colors.dashboardCards.background, boxShadow: '0px 1px 5px 0px #00000029' }}>

          <span style={{
            float: 'left'
          }}>
            <span style={{ display: 'flex', alignItems: 'center' }}><img
              alt="total-count"
              src={ProfitImage}></img>&nbsp;<Typography style={{
              fontWeight: 500,
              fontSize: 13,
                padding: 5,
              color: 'rgba(158, 158, 158, 1)'
            }}>Profit</Typography>
              {overallProfit >= requiredProfit ?
                <div style={{
                  display: 'flex',
                  backgroundColor: colors.darkBlue,
                  padding: '4px',
                  borderRadius: 5,
                  alignItems:"center"
                }}>
                <>
                  <Typography style={{
                    fontSize: 6,
                    paddingLeft: '4px',
                      color: colors.tealBlack
                  }}>{'\u2B24'}</Typography>
                  <Typography style={{
                      color: colors.tealBlack
                  }} >&nbsp;&nbsp;Passed</Typography>
                </>
               </div>
                :
                <div style={{
                  display: 'flex',
                  backgroundColor: colors.darkBlue,
                  padding: '4px',
                  borderRadius: 5,
                  alignItems:"center"
                }}>
                  <>
                    <Typography style={{
                      fontSize: 6,
                      paddingLeft: '4px',
                      color: colors.tealBlack

                    }}>{'\u2B24'}</Typography>
                    <Typography style={{
                      color: colors.tealBlack
                    }} >&nbsp;Ongoing</Typography></>
               </div>
               }
            </span>


            <Typography style={{
              fontWeight: 500,
              fontSize: 16,
              padding: 5
            }}>Profit Target : {formatPrice(requiredProfit)} ({account?.profitTarget}%)</Typography>
            <Typography style={{
              fontWeight: 500,
              fontSize: 16,
              padding: 5
            }}>Current Profit : {overallProfit == 0 ? 0 : formatPrice(overallProfit)}</Typography>
          </span>
          <span style={{
            float: 'right'
          }}>
            <PieChartComponent data={[
              { name: 'A1', value: overallProfit, color: colors.piechart.green },
              { name: 'A2', value: overallProfit == 0 ? requiredProfit : Number(requiredProfit - overallProfit) > 0 ? Number(requiredProfit - overallProfit) : 0, color: colors.piechart.greenishGrey },
            ]} />
          </span>

        </Paper>
      </Grid>


      <Grid style={{
        paddingRight: 16
      }} item xs={12} sm={12} md={6}  >
        <Paper style={{ height: '100%', borderRadius: '10px', padding: '14px', backgroundColor: colors.dashboardCards.background, boxShadow: '0px 1px 5px 0px #00000029' }}>

          <span style={{
            float: 'left'
          }}>
            <span style={{ display: 'flex', alignItems: 'center' }}><img
              alt="total-count"
              src={DailyImage}></img>&nbsp;<Typography style={{
              fontWeight: 500,
              fontSize: 13,
              padding: 5,
              color: 'rgba(158, 158, 158, 1)'
            }}>Daily Loss Limit</Typography>
              {dailyLoss >= dailyLossLimit ?
                 <div style={{
                  display: 'flex',
                  backgroundColor: colors.darkBlue,
                  padding: '4px',
                  borderRadius: 5,
                  alignItems:"center"
                }}>
                <>
                  <Typography style={{
                      fontSize: 6,
                      paddingLeft: '4px',
                      color: colors.tealBlack
                  }}>{'\u2B24'}</Typography>
                  <Typography style={{
                      color: colors.tealBlack
                  }} >&nbsp;Failed</Typography>
                  </> </div>:
                 <div style={{
                  display: 'flex',
                  backgroundColor: colors.darkBlue,
                  padding: '4px',
                  borderRadius: 5,
                  alignItems:"center"
                }}>
                <>
                  <Typography style={{
                      fontSize: 6,
                    paddingLeft: '4px',
                    color: colors.tealBlack

                  }}>{'\u2B24'}</Typography>
                  <Typography style={{
                      color: colors.tealBlack
                  }} >&nbsp;Ongoing</Typography></>
              </div>}
            </span>

            <Typography style={{
              fontWeight: 500,
              fontSize: 16,
              padding: 5
            }}>Maximum : {formatPrice(dailyLossLimit)} ({account?.dll}%)</Typography>
            <Typography style={{
              fontWeight: 500,
              fontSize: 16,
              padding: 5
            }}>Current : {dailyLoss == 0 ? 0 : formatPrice(dailyLoss)}</Typography>
          </span>
          <span style={{
            float: 'right'
          }}>
            <PieChartComponent data={[
              { name: 'A1', value: dailyLoss, color: colors.piechart.red },
              { name: 'A2', value: dailyLoss == 0 ? dailyLossLimit : Number(dailyLossLimit - dailyLoss) > 0 ? Number(dailyLossLimit - dailyLoss) : 0, color: colors.piechart.redishGrey },
            ]} />
          </span>

        </Paper>
      </Grid>

      <Grid style={{
        paddingRight: 16
      }} item xs={12} sm={12} md={6}  >
        <Paper style={{ height: '100%', borderRadius: '10px', padding: '14px', backgroundColor: colors.dashboardCards.background, boxShadow: '0px 1px 5px 0px #00000029' }}>

          <span style={{
            float: 'left'
          }}>
            <span style={{ display: 'flex', alignItems: 'center' }}><img
              alt="total-count"
              src={TotalImage}></img>&nbsp;<Typography style={{
              fontWeight: 500,
              fontSize: 13,
              padding: 5,
              color: 'rgba(158, 158, 158, 1)'
            }}>Total Loss Limit</Typography>
              {totalLoss >= totalLossLimit ? 
                 <div style={{
                  display: 'flex',
                  backgroundColor: colors.darkBlue,
                  padding: '4px',
                  borderRadius: 5,
                  alignItems:"center"
                }}>
                <>
                  <Typography style={{
                    fontSize: 6,
                    paddingLeft: '4px',
                      color: colors.tealBlack
                  }}>{'\u2B24'}</Typography>
                  <Typography style={{
                      color: colors.tealBlack
                  }} >&nbsp;Failed</Typography>
                </> 
                </div>
                :
                 <div style={{
                  display: 'flex',
                  backgroundColor: colors.darkBlue,
                  padding: '4px',
                  borderRadius: 5,
                  alignItems:"center"
                }}>
                  <>
                    <Typography style={{
                    fontSize: 6,
                    paddingLeft: '4px',
                      color: colors.tealBlack

                    }}>{'\u2B24'}</Typography>
                    <Typography style={{
                      color: colors.tealBlack
                    }} >&nbsp;Ongoing</Typography></>
                </div>}
            </span>

            <Typography style={{
              fontWeight: 500,
              fontSize: 16,
              padding: 5
            }}>Maximum : {formatPrice(totalLossLimit)} ({account?.tll}%)</Typography>
            <Typography style={{
              fontWeight: 500,
              fontSize: 16,
              padding: 5
            }}>Current : {formatPrice(totalLoss)}</Typography>
          </span>
          <span style={{
            float: 'right'
          }}>
            <PieChartComponent data={[
              { name: 'A1', value: totalLoss, color: colors.piechart.red },
              { name: 'A2', value: totalLoss == 0 ? totalLossLimit : Number(totalLossLimit - totalLoss) > 0 ? Number(totalLossLimit - totalLoss): 0, color: colors.piechart.redishGrey },
            ]} />
          </span>

        </Paper>
      </Grid>

      <Grid style={{
        paddingRight: 16
      }} item xs={12} sm={12} md={6}  >
        <Paper style={{ height: '100%',  borderRadius: '10px', padding: '14px', backgroundColor: colors.dashboardCards.background, boxShadow: '0px 1px 5px 0px #00000029' }}>

          <span style={{
            float: 'left'
          }}>
            <span style={{ display: 'flex', alignItems: 'center' }}><img
              alt="total-count"
              src={CalImage}></img>&nbsp;<Typography style={{
              fontWeight: 500,
              fontSize: 13,
              padding: 5,
              color: 'rgba(158, 158, 158, 1)'
            }}>Minimum Trading Days</Typography>
              {account?.trades?.tradedDays >= MINIMUM_TRADING_DAYS ? 
                <div style={{
                  display: 'flex',
                  backgroundColor: colors.darkBlue,
                  padding: '4px',
                  borderRadius: 5,
                  alignItems:"center"
                }}>
                <>
                  <Typography style={{
                      fontSize: 6,
                    paddingLeft: '4px',
                      color: colors.tealBlack

                  }}>{'\u2B24'}</Typography>
                  <Typography style={{
                      color: colors.tealBlack
                    }} >&nbsp;&nbsp;Passed</Typography></></div>
                : 
                  <div style={{
                    display: 'flex',
                  backgroundColor: colors.darkBlue,
                    padding: '4px',
                    borderRadius: 5,
                    alignItems: "center"
                  }}>
                <>
                  <Typography style={{
                      fontSize: 6,
                      paddingLeft: '4px',
                    color: colors.tealBlack

                  }}>{'\u2B24'}</Typography>
                  <Typography style={{
                      color: colors.tealBlack
                      }} >&nbsp;&nbsp;Ongoing</Typography></>
                  </div>}
            </span>

            <Typography style={{
              fontWeight: 500,
              fontSize: 16,
              padding: 5
            }}>Minimum : {MINIMUM_TRADING_DAYS}</Typography>
            <Typography style={{
              fontWeight: 500,
              fontSize: 16,
              paddingBottom: 20,
              padding: 5
            }}>Current : {account?.trades?.tradedDays ?? 0}</Typography>
          </span>

        </Paper>
      </Grid>



    </Grid>
  );
};

export default PaperCardsSection;