import {
  ButtonBase,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import UpgradeImage from '../../../assets/upgrade.png';
import ConditionalTooltip from '../../../components/Tooltip';
import { ACCOUNT_STATUS, MINIMUM_TRADING_DAYS } from '../../../config';
import { createPayout, createRequest, getPayouts, getRequests } from '../../../state/actions/user.actions';
import { tokens } from '../../../theme';
import { formatPrice } from '../../../utils/string';
import { getAccountStatus } from '../../../utils/constants';
import AccountPayoutDialog from '../Elements/AccountPayoutDialog';
import AccountUpgradeDialog from '../Elements/AccountUpgradeDialog';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const AccountCardSection = (props) => {

  const theme = useTheme();
  const dispatch = useDispatch();

  const colors = tokens(theme.palette.mode);

  const { account, user, isProfitReached, hasOpenPositions, hasOpenOrders } = props;
  const [accountUpdateTooltipMessage, setAccountUpdateTooltipMessage] = useState('');

  const [payoutStatus, setPayoutStatus] = useState([]);
  const [requestStatus, setRequestStatus] = useState([]);

  const [isValidityDaysCompleted, setIsValidityDaysCompleted] = useState(false);
  const [accountUpgradeDialog, setAccountUpgradeDialog] = useState(false);
  const [accountPayoutDialog, setAccountPayoutDialog] = useState(false);
  const payouts = useSelector((state) => state.user?.payouts);
  const requests = useSelector((state) => state.user?.requests);


  useEffect(() => {
    if (account) {
      dispatch(getPayouts({
        account: account?._id, expand: 'account'
      }));
      dispatch(getRequests({
        account: account?._id
      }));
    }
  }, [account]);

  useEffect(() => {
    if (account) {

      let accountType = account?.accountType?.value;

      if (payouts?.payouts?.length == 0 || requests?.requests?.length == 0) {
        setPayoutStatus(null);
        setRequestStatus(null);

        if ([ACCOUNT_STATUS.COMPLETED, ACCOUNT_STATUS.LIQUIDATED, ACCOUNT_STATUS.RESET].includes(account.status)) {
          if (account?.status == ACCOUNT_STATUS.COMPLETED) {
            setAccountUpdateTooltipMessage('Target Reached');
          }
          if (account?.status == ACCOUNT_STATUS.LIQUIDATED) {
            setAccountUpdateTooltipMessage('Account Liquidated');
          }
          if (account?.status == ACCOUNT_STATUS.RESET) {
            setAccountUpdateTooltipMessage('Account Reset');
          }
        } else {
          let remainingDays = getRemainingDays();
          if (accountType?.toLowerCase() == 'prime' && account?.plan?.name?.toLowerCase() == 'rapid') {
            let tradedDays = isNaN(account?.trades?.tradedDays) ? 0 : account?.trades?.tradedDays;
            if (tradedDays < MINIMUM_TRADING_DAYS) {
              setAccountUpdateTooltipMessage('Complete Minimum Trading Days');
            }
            else if (!isProfitReached) {
              setAccountUpdateTooltipMessage('Complete Profit Target');
            }
            else if (remainingDays == 'NA') {
              setAccountUpdateTooltipMessage('Start Trading');
            }
            else if (remainingDays >= 0) {
              setAccountUpdateTooltipMessage(`Complete Trading Cycle`);
              setIsValidityDaysCompleted(false);
            }
            else if (hasOpenOrders) {
              setAccountUpdateTooltipMessage(`Close All Open Orders`);
            }
            else if (hasOpenPositions) {
              setAccountUpdateTooltipMessage(`Close All Open Positions`);
            }
            else {
              setIsValidityDaysCompleted(true);
              setAccountUpdateTooltipMessage('Payout Available');
            }
          } else if (accountType?.toLowerCase() == 'prime' && account?.plan?.name?.toLowerCase() == 'evaluation') {
            let tradedDays = isNaN(account?.trades?.tradedDays) ? 0 : account?.trades?.tradedDays;
            if (tradedDays < MINIMUM_TRADING_DAYS) {
              setAccountUpdateTooltipMessage('Complete Minimum Trading Days');
            }
            else if (account?.amount > account?.credits?.cashBalance) {
              setAccountUpdateTooltipMessage('Complete Profit Target');
            }
            else if (remainingDays == 'NA') {
              setAccountUpdateTooltipMessage('Start Trading');
            }
            else if (remainingDays >= 0) {
              setAccountUpdateTooltipMessage(`Complete Trading Cycle`);
              setIsValidityDaysCompleted(false);
            }
            else if (hasOpenOrders) {
              setAccountUpdateTooltipMessage(`Close All Open Orders`);
            }
            else if (hasOpenPositions) {
              setAccountUpdateTooltipMessage(`Close All Open Positions`);
            }
            else {
              setIsValidityDaysCompleted(true);
              setAccountUpdateTooltipMessage('Payout Available');
            }
          } else if (account?.plan?.name?.toLowerCase() == 'evaluation' && accountType?.toLowerCase() != 'prime') {
            let tradedDays = isNaN(account?.trades?.tradedDays) ? 0 : account?.trades?.tradedDays;
            if (tradedDays < MINIMUM_TRADING_DAYS) {
              setAccountUpdateTooltipMessage('Complete Minimum Trading Days');
            }
            else if (!isProfitReached) {
              setAccountUpdateTooltipMessage('Complete Profit Target');
            }
            else if (remainingDays == 'NA') {
              setAccountUpdateTooltipMessage('Start Trading');
            }
            else if (hasOpenOrders) {
              setAccountUpdateTooltipMessage(`Close All Open Orders`);
            }
            else if (hasOpenPositions) {
              setAccountUpdateTooltipMessage(`Close All Open Positions`);
            }
            else {
              setIsValidityDaysCompleted(true);
              setAccountUpdateTooltipMessage('Upgrade Available');
            }
          }
        }
      }

      if (payouts?.payouts?.length > 0) {
        setPayoutStatus(payouts?.payouts?.[0]?.status);
        setAccountUpdateTooltipMessage(`Payout Request ${getPayoutStatus(payouts?.payouts?.[0]?.status)}`);
      }


      if (requests?.requests?.length > 0) {
        setRequestStatus(requests?.requests?.[0]?.status);
        setAccountUpdateTooltipMessage(`Upgrade Request ${getRequestStatus(requests?.requests?.[0]?.status)}`);
      }
    }
  }, [payouts, requests, isProfitReached]);



  const handleAccountUpgradeDialogClose = () => {
    setAccountUpgradeDialog(false);
  };

  const handleAccountPayoutDialogClose = () => {
    setAccountPayoutDialog(false);
  };

  function getDaysRemaining() {
    if (!account?.meta?.firstTraded) {
      return 'NA';
    }
    const currentDate = dayjs.utc().startOf('day');
    const remainingDays = dayjs.utc(account?.meta?.firstTraded).startOf('day').add(account?.validityDays, 'day').diff(currentDate, 'day');
    return `${remainingDays > 0 ? remainingDays : 0} Days`;
  }

  function getRemainingDays() {
    if (!account?.meta?.firstTraded) {
      return 'NA';
    }
    const currentDate = dayjs.utc().startOf('day');
    const remainingDays = dayjs.utc(account?.meta?.firstTraded).startOf('day').add(account?.validityDays, 'day').diff(currentDate, 'day');
    return remainingDays;
  }

  function handleAccountUpgradeDialog() {
    setAccountUpgradeDialog(true);
  }

  function handleAccountPayoutDialog() {
    console.log('Yess');
    setAccountPayoutDialog(true);
  }

  function handleUpgradeClick() {
    dispatch(createRequest({
      account: account?._id,
      user: user._id,
      type: 1
    }));

    setAccountUpgradeDialog(false);
    setTimeout(() => {
      dispatch(getRequests({
        account: account?._id
      }));
      window.location.reload();
    }, 600);
  }

  function handlePayoutClick() {
    dispatch(createPayout({
      account: account?._id,
      user: user._id,
    }));

    setAccountPayoutDialog(false);
    setTimeout(() => {
      dispatch(getPayouts({
        account: account?._id, expand: 'account'
      }));
      window.location.reload();
    }, 600);
  }

  function getPayoutStatus(status, prefix = '', suffix = '') {
    switch (status) {
      case 1:
        return `${prefix}In Progress${suffix}`;
      case 2:
        return `${prefix}Processed${suffix}`;
      case 3:
        return `${prefix}Rejected${suffix}`;
      default:
        return '';
    }
  }

  function getRequestStatus(status, prefix = '', suffix = '') {
    switch (status) {
      case 1:
        return `${prefix}In Progress${suffix}`;
      case 2:
        return `${prefix}Processed${suffix}`;
      case 3:
        return `${prefix}Rejected${suffix}`;
      default:
        return '';
    }
  }

  return (
    <Grid component={Paper} style={{
      borderRadius: '10px',
      width: '98%',
      marginLeft: '1px',
      marginTop: '30px',
      paddingBottom: '25px',
      boxShadow: '0px 1px 5px 0px #00000029',
      backgroundColor: colors.dashboardCards.background,
    }} container spacing={2}>
      <Grid item xs={12} sm={12} md={3} lg={3} >
        <div>
          <Typography style={{
            fontWeight: 400,
            fontSize: 12,
            color: colors.textLabel
          }}>Account Number</Typography>
          <Typography style={{
            fontWeight: '600',
            fontSize: '25px'
          }}>{account?.accountNumber}</Typography>
        </div>
        <div style={{
          paddingTop: '34px',
          alignItems: 'flex-start',
          display: 'flex'
        }}>
          {(account?.accountType?.value?.toLowerCase() == 'prime') && (account?.plan?.name?.toLowerCase() == 'evaluation') ?
            <ConditionalTooltip placement="top" condition={true} title={accountUpdateTooltipMessage}>
              <div>
                <ButtonBase
                  disabled={accountUpdateTooltipMessage !== 'Payout Available' || !isValidityDaysCompleted}
                  onClick={handleAccountPayoutDialog}
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    paddingLeft: '5px',
                    paddingRight: '15px',
                    borderRadius: '7px',
                    border: '1px solid #5E5E5E33',
                    background: colors.upgradeBtn,
                    display: 'flex'
                  }}><img style={{
                    marginRight: 5
                  }} width={20} height={20} src={UpgradeImage}></img> <Typography sx={{
                    color: colors.tealBlack,
                    fontSize: 12,
                    fontWeight: 600
                  }}>Payout {getPayoutStatus(payoutStatus, '(', ')')}</Typography>
                </ButtonBase>
              </div>
            </ConditionalTooltip>
            :
            (account?.accountType?.value?.toLowerCase() == 'prime') ?
            <ConditionalTooltip placement="top" condition={true} title={accountUpdateTooltipMessage}>
              <div>
                <ButtonBase
                  disabled={!isProfitReached || accountUpdateTooltipMessage !== 'Payout Available' || !isValidityDaysCompleted}
                  onClick={handleAccountPayoutDialog}
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    paddingLeft: '5px',
                    paddingRight: '15px',
                    borderRadius: '7px',
                    border: '1px solid #5E5E5E33',
                    background: colors.upgradeBtn,
                    display: 'flex'
                  }}><img style={{
                    marginRight: 5
                  }} width={20} height={20} src={UpgradeImage}></img> <Typography sx={{
                    color: colors.tealBlack,
                    fontSize: 12,
                    fontWeight: 600
                  }}>Payout {getPayoutStatus(payoutStatus, '(', ')')}</Typography>
                </ButtonBase>
              </div>
            </ConditionalTooltip> :
            <ConditionalTooltip placement="top" condition={true} title={accountUpdateTooltipMessage}>
              <div>
                <ButtonBase
                  disabled={!isProfitReached || accountUpdateTooltipMessage !== 'Upgrade Available'}
                  onClick={handleAccountUpgradeDialog}
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    paddingLeft: '5px',
                    paddingRight: '15px',
                    borderRadius: '7px',
                    border: '1px solid #5E5E5E33',
                    background: colors.upgradeBtn,
                    display: 'flex'
                  }}><img style={{
                    marginRight: 5
                  }} width={20} height={20} src={UpgradeImage}></img> <Typography sx={{
                    color: colors.tealBlack,
                    fontSize: 12,
                    fontWeight: 600
                  }}>Upgrade {getRequestStatus(requestStatus, '(', ')')}</Typography>
                </ButtonBase>
              </div>
            </ConditionalTooltip>}
          <AccountUpgradeDialog
            accountUpgradeDialog={accountUpgradeDialog}
            handleAccountUpgradeDialogClose={handleAccountUpgradeDialogClose}
            handleUpgradeClick={handleUpgradeClick}
          />

          <AccountPayoutDialog
            accountPayoutDialog={accountPayoutDialog}
            handleAccountPayoutDialogClose={handleAccountPayoutDialogClose}
            handlePayoutClick={handlePayoutClick}
          />

        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={9} lg={9}>

        <Grid container>

          <Grid item xs={6} sm={6} md={3} lg={3}   >

            <Typography style={{
              fontWeight: 400,
              fontSize: 14,
              color: colors.textLabel,

            }}>Status</Typography>
            <Typography style={{
              fontWeight: 600,
              fontSize: 13,
              color: [ACCOUNT_STATUS.ACTIVE, ACCOUNT_STATUS.COMPLETED].includes(account?.status) ? colors.green : colors.red
            }}>{getAccountStatus(account?.status)}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}   >
            <Typography style={{
              fontWeight: 400,
              fontSize: 14,
              color: colors.textLabel,

            }}>Plan Type</Typography>
            <Typography style={{
              fontWeight: 600,
              fontSize: 14
            }}>{account?.planAlias}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}   >
            <Typography style={{
              fontWeight: 400,
              fontSize: 14,
              color: colors.textLabel,

            }}>Account Size</Typography>
            <Typography style={{
              fontWeight: 600,
              fontSize: 14
            }}>{account?.accountSize?.label == 'Custom' ? formatPrice(account?.amount) : formatPrice(account?.accountSize?.value)}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}   >
            <Typography style={{
              fontWeight: 400,
              fontSize: 14,
              color: colors.textLabel,

            }}>Account Type</Typography>
            <Typography style={{
              fontWeight: 600,
              fontSize: 14
            }}>{account?.accountAlias}</Typography>
          </Grid>

          <Grid item mr={3} pt={2} pb={2} xs={12} md={12} lg={12} sm={12}>
            <Divider orientation='horizontal' />

          </Grid>

          <Grid item xs={6} sm={6} md={3} lg={3}   >
            <Typography style={{
              color: colors.textLabel,
              fontWeight: 400,
              fontSize: 14,

            }}>Position Closes</Typography>
            <Typography style={{
              fontWeight: 600,
              fontSize: 14
            }}>{account?.tradeSquareOff}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}   >
            <Typography style={{
              fontWeight: 400,
              fontSize: 14,
              color: colors.textLabel,

            }}>Account Created</Typography>
            <Typography style={{
              fontWeight: 600,
              fontSize: 14
            }}>{dayjs(account?.createdAt).format('DD MMM YYYY')}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}   >
            <Typography style={{
              fontWeight: 400,
              fontSize: 14,
              color: colors.textLabel,

            }}>First Traded Date</Typography>
            <Typography style={{
              fontWeight: 600,
              fontSize: 14
            }}>{account?.meta?.firstTraded ? dayjs(account?.meta?.firstTraded).format('DD MMM YYYY') : 'NA'}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}   >
            <Typography style={{
              fontWeight: 400,
              fontSize: 14,
              color: colors.textLabel,

            }}>Days Remaining</Typography>
            <Typography style={{
              fontWeight: 600,
              fontSize: 14
            }}>{getDaysRemaining()}</Typography>
          </Grid>

        </Grid>

      </Grid>


    </Grid>
  );
};

export default AccountCardSection;