import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { AppBar, Avatar, Box, Container, Grid, Hidden, IconButton, Menu, MenuItem, Stack, Toolbar, Typography, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DarkAppLogo from '../../assets/logo-dark.png';
import LightAppLogo from '../../assets/logo-light.png';
import { logoutUser } from '../../state/actions/auth.actions';
import { setNavMenuItem } from '../../state/actions/common.actions';
import { ColorModeContext, tokens } from '../../theme';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { S3_BASE_URL, REST_URL } from '../../config';
import axios from 'axios';

const AdminTopbar = () => {

  const _theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const colors = tokens(_theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const common = useSelector((state) => state.common);
  const user = JSON.parse(localStorage.getItem('user'));
  const [isThemeChecked, setThemeChecked] = useState(localStorage.getItem('theme') === 'dark' ? true : false);
  const [isExplicitTrading, setIsExplicitTrading] = useState(false);


  const handleThemeChange = (event) => {
    event.stopPropagation();
    setThemeChecked(!isThemeChecked);
    colorMode.toggleColorMode();
  };

  const handleExplicitTrading = async (event) => {
    event.stopPropagation();
    const accessToken = localStorage.getItem('token');
    const response = await axios.put(`${REST_URL}/trades/explicit/trade-access`, {
      explicitTrading: !isExplicitTrading
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
    setIsExplicitTrading(!isExplicitTrading);
  };



  let pages = [
    {
      name: 'Users',
      navigateTo: '/admin-users'
    },
    {
      name: 'Account',
      navigateTo: '/admin-accounts'
    },
  ];

  if (user?.role === 'SUPERADMIN') {
    pages.push({
      name: 'Admin',
      navigateTo: '/admin-admins'
    },
      {
        name: 'Position',
        navigateTo: '/admin-positions'
      },
      {
        name: 'Trade',
        navigateTo: '/admin-trades'
      },
      {
        name: 'Request',
        navigateTo: '/admin-requests'
      },
      {
        name: 'Other',
        navigateTo: '/admin-other'
      });
  } else {
    pages.push(
      {
        name: 'Position',
        navigateTo: '/admin-positions'
      },
      {
        name: 'Trade',
        navigateTo: '/admin-trades'
      },
      {
        name: 'Request',
        navigateTo: '/admin-requests'
      },
      {
        name: 'Other',
        navigateTo: '/admin-other'
      });
  }


  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(setNavMenuItem('Users'));
    dispatch(logoutUser());
    navigate('/login', {
      replace: true
    });
  };

  const handlePageClick = (event, NAVIGATE_TO, PAGE_NAME) => {
    dispatch(setNavMenuItem(PAGE_NAME));
    setSelectedPage(PAGE_NAME);
    navigate(`${NAVIGATE_TO}`);
  };

  function getActiveMenu(pathname) {
    switch (pathname) {
      case '/admin-accounts':
        return 'Account';
      case '/admin-account-view':
        return 'Account';
      case '/admin-users':
        return 'Users';
      case '/admin-admins':
        return 'Admin';
      case '/admin-user-view':
        return 'Admin';
      case '/admin-positions':
        return 'Position';
      case '/admin-position-view':
        return 'Position';
      case '/admin-trades':
        return 'Trade';
      case '/admin-requests':
        return 'Request';
      case '/admin-payout-view':
        return 'Request';
      case '/admin-other':
        return 'Other';
      default:
        return 'Users';
    }
  }



  const [selectedPage, setSelectedPage] = useState(common?.navMenuItem ?? 'Users');
  useEffect(() => {
    getTradeConfiguration();
    setSelectedPage(getActiveMenu(location?.pathname));
  }, [location?.pathname]);

  function getAvatarNameChar(data) {
    return `${data?.firstName?.[0]}${data?.lastName?.[0]}`.toUpperCase();
  }

  function getUserName(data) {
    return `${data?.firstName}${' '}${data?.lastName}`
  }

  function homeIconPress() {
    setSelectedPage('Users');
    dispatch(setNavMenuItem('Users'));
    navigate('/admin-users')
  }

  async function getTradeConfiguration() {
    const accessToken = localStorage.getItem('token');
    const response = await axios.get(`${REST_URL}/trades/accessor/configurations`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
    if (response) {
      setIsExplicitTrading(response?.data?.config?.explicitTrading ?? false)
    }
  }

  return (
    <AppBar style={{ background: colors.topbar[100], boxShadow: colors.appBar.boxShadow }} position="relative">
      <Container maxWidth="xl">
        <Hidden mdUp>
          <div style={{
            cursor: 'pointer',
            textAlign: 'center',
            padding: 6
          }} onClick={() => homeIconPress()}>
            <img alt='img' style={{ width: '190px' }} src={_theme.palette.mode === 'dark' ? LightAppLogo : DarkAppLogo} />
          </div>

        </Hidden>
        <Toolbar disableGutters>
          <Grid onClick={() => homeIconPress()} sx={{
            cursor: 'pointer',
            display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' }
          }} >
            <img alt='img' style={{ width: '200px' }} src={_theme.palette.mode == 'dark' ? LightAppLogo : DarkAppLogo} />

          </Grid>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              sx={{ color: colors.black[100] }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name}
                  onClick={(event) => handlePageClick(event, page.navigateTo, page.name)}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, justifyContent: 'center', display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <MenuItem
                key={page.name}
                onClick={(event) => handlePageClick(event, page.navigateTo, page.name)}
                selected={selectedPage === page.name}
                sx={{
                  marginX: 4,
                  '&.Mui-selected': {
                    background: `${colors.topbar.background}!important`,
                    position: 'relative',

                    color: colors.teal,
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      left: '50%',
                      right: '50%',
                      bottom: 0,
                      transform: 'translateX(-50%)',
                      width: '20%',
                      height: '2px',
                      borderBottom: '2px solid #0ac8d2',
                    },
                  },
                  '&:hover': {
                    background: `${colors.topbar.background}!important`,
                  },
                  my: 2, color: colors.grey[200], display: 'block', fontWeight: 600, fontSize: '14px', textTransform: 'none'
                }}
                style={{ paddingLeft: '10px', }}
              >
                {page.name}
              </MenuItem>
            ))}
          </Box>


          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleClick} sx={{ p: 0 }}>
              <Typography>{getUserName(user)}</Typography> &nbsp;
              <Avatar sx={{
                bgcolor: '#DBAD38'
              }} >{getAvatarNameChar(user)}</Avatar>
            </IconButton>
            <Menu
              sx={{
                mt: '45px',
                '& .MuiPaper-root': {
                  backgroundColor: colors.accountMenu.background
                },
              }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={() => handleClose()}
            >
              <MenuItem onClick={() => handleClose()} disableRipple>

                <Stack sx={{
                  pr: { md: 16, lg: 16, sm: 16, xs: 16 }
                }} direction="row" alignItems="center" spacing={2}>
                  <Avatar sx={{
                    bgcolor: '#DBAD38'
                  }} alt={'displayName'}>{getAvatarNameChar(user)}</Avatar>
                  <div>
                    <Typography variant="subtitle1">{getUserName(user)}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {user?.email}
                    </Typography>
                  </div>
                </Stack>
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: 0,
                paddingBottom: 0,
              }}
                disableRipple>
                <span style={{
                  display: 'flex'
                }}>
                  <DarkModeOutlinedIcon />&nbsp;Theme
                </span>
                <div>
                  <Switch checked={isThemeChecked} onChange={handleThemeChange} />
                </div>

              </MenuItem>

              <MenuItem sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: 0,
                paddingBottom: 0,
              }}
                disableRipple>
                <span style={{
                  display: 'flex'
                }}>
                  <MonetizationOnIcon />&nbsp;Explicit Trading
                </span>
                <div>
                  <Switch checked={isExplicitTrading} onChange={handleExplicitTrading} />
                </div>

              </MenuItem>
              <Divider sx={{ my: 0.5 }} />

              <MenuItem onClick={() => handleLogout()} disableRipple>
                <Typography pl={1} sx={{
                  color: 'red'
                }} >Logout</Typography>

              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>

  );
};

export default AdminTopbar;
