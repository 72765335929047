import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import PositionDetails from './scenes/admin/positions/PositionDetails';
import Positions from './scenes/admin/positions/Positions';
import PayoutDetails from './scenes/admin/requests/PayoutDetails';
import Requests from './scenes/admin/requests/Requests';
import Trades from './scenes/admin/trades/Trades';
import CustomerUsers from './scenes/admin/users/Customer';
import Dashboard from './scenes/dashboard';
import History from './scenes/history';
import Login from './scenes/login';
import Orders from './scenes/orders';
import Payout from './scenes/payout';
import Loading from './components/Loading';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { io } from 'socket.io-client';
import { SocketProvider } from './SocketContext';
import { UserProvider } from './UserContext';
import { Notification as NotificationComponent } from './components/Notification';
import { SOCKET_URL } from './config';
import AccountDetails from './scenes/admin/accounts/AccountDetails';
import AccountForm from './scenes/admin/accounts/AccountForm';
import Accounts from './scenes/admin/accounts/Accounts';
import Other from './scenes/admin/other/Other';
import AdminUsers from './scenes/admin/users/Admin';
import UserDetails from './scenes/admin/users/UserDetails';
import TermsAndConditions from './scenes/static/TermsAndConditions';
import Privacy from './scenes/static/Privacy'
import { ColorModeContext, useMode } from './theme';
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import { messaging } from "./messaging";
import { useDispatch, useSelector } from 'react-redux';
import { updateFCMToken } from './state/actions/user.actions';
 

function App() {
  const dispatch = useDispatch();

  const [theme, colorMode] = useMode();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const logoutState = useSelector(
    (state) => state.auth?.logout
  );

  function useNotification() {
    return useSelector(
      (state) => state.notification
    );
  }

  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    new Notification(payload.notification.title, {
      body: payload.notification.body
    }).onclick = () => {
      navigate(
        payload?.notification.title?.includes('Market') ? '/dashboard' :
          payload?.notification.title?.includes('Order') ? '/hstry' :
         '/dashboard'
      );
    };
  });

  useEffect(() => {
    if (logoutState) {
      navigate('/login')
    }
  }, [logoutState])

  const { message, messageId } = useNotification();

  const [permissionState, setPermissionState] = useState('default'); 

  useEffect(() => {
    if (permissionState == 'granted') {
      _getToken();
      async function _getToken() {
        const token = await getToken(messaging, {
          vapidKey: 'BEJFhvsWaR7O8t4mRY-89EJv1LTVQKQ-YnDfQK7akjgRKJe_Ew7hMz4FFwNeKGLFuJlXP38yoE64m4_DaywI7G0',
        });
        let cachedUser = localStorage.getItem('user');
        console.log('token: ', token);
        if (cachedUser) {
          dispatch(updateFCMToken({
            _id: JSON.parse(cachedUser)?._id,
            webFCMToken: token
          }))
        }
      }
    }
  }, [permissionState])

  const handleNotificationPermission = async () => {
    const permission = await Notification.requestPermission();

    try {
      setPermissionState(permission);
    } catch (error) {
      console.error('Error requesting notification permission:', error);
    }
  };

  handleNotificationPermission()

  return (

    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserProvider>
          <SocketProvider>
            <div className="app">
              <main className="content">
                <Routes>
                  <Route path="/" element={<Loading />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/orders-positions" element={<Orders />} />
                  <Route path="/history" element={<History />} />
                  <Route path="/payout" element={<Payout />} />
                  <Route path="/admin-users" element={<CustomerUsers />} />
                  <Route path="/admin-admins" element={<AdminUsers />} />
                  <Route path="/admin-positions" element={<Positions />} />
                  <Route path="/admin-trades" element={<Trades />} />
                  <Route path="/admin-accounts" element={<Accounts />} />
                  <Route path="/admin-requests" element={<Requests />} />
                  <Route path="/admin-account" element={<AccountForm />} />
                  <Route path="/admin-account-view" element={<AccountDetails />} />
                  <Route path="/admin-position-view" element={<PositionDetails />} />
                  <Route path="/admin-payout-view" element={<PayoutDetails />} />
                  <Route path="/admin-user-view" element={<UserDetails />} />
                  <Route path="/admin-other" element={<Other />} />
                  <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="*" element={<Loading />} />
                </Routes>
                <NotificationComponent key={messageId} message={message} />
              </main>
            </div>
          </SocketProvider>
        </UserProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
