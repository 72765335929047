import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    loginError: null,
    loginResponse: null,
    loading: false,
    logout: false,
  },

  reducers: {
    loginRequested: (auth, action) => {
      auth.loading = true;
    },

    loginSuccess: (auth, action) => {
      auth.loginResponse = action.payload;
      auth.loading = false;
      auth.logout = false;
    },

    loginFailed: (auth, action) => {
      auth.loginError = action.payload;
      auth.loading = false;
    },

    getUser: (auth, action) => {
      auth.loading = true;
    },

    getUserSuccess: (auth, action) => {
      auth.user = action.payload;
      auth.loading = false;
    },

    getUserFailed: (auth, action) => {
      auth.user = action.payload;
      auth.loading = false;
    },

    logout: (auth, action) => {
      auth.loginResponse = null;
      auth.loading = false;
      localStorage.clear();
      auth.logout = true;
    },
  },
});

const { actions, reducer } = authSlice;
export const { loginRequested, loginSuccess, loginFailed, logout,
  getUser, getUserFailed, getUserSuccess} = actions;
export default reducer;
