import axios from 'axios';
import { sendNotification } from './reducers/notification.reducer';
import * as actions from './api';
import { REST_URL } from '../config';
import { logout } from './reducers/auth.reducer';

const api =
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type !== actions.apiCallBegan.type) return next(action);

          const { url, method, data, onStart, onSuccess, onError, onStatic, notificationMessage=null } =
                    action.payload;

          if (onStatic) dispatch({ type: onStatic });


          if (onStart) dispatch({ type: onStart });

          next(action);
          const user = JSON.parse(localStorage.getItem('user'));
          const accessToken = localStorage.getItem('token');

          try {
            const response = await axios.request({
              baseURL: REST_URL,
              headers: {
                Authorization: `Bearer ${accessToken}`,
                Role: `${user?.role}`

              },
              url,
              method,
              data,
            });
            if (onSuccess) {
              if (notificationMessage) {
                dispatch({ type: sendNotification.type, payload: { message: notificationMessage, } });
              }
              dispatch({ type: onSuccess, payload: response.data });

            }
          } catch (error) {
            if (error?.response?.status === 401) {
              localStorage.clear();
              dispatch({ type: logout.type, payload: { key: Date.now() } });
              dispatch({ type: sendNotification.type, payload: { message: 'Invalid Session' } });
            } 
            else {
              if (error?.response?.data?.error !== 'Not Found') {
                dispatch({ type: sendNotification.type, payload: { message: error?.response?.data?.error ?? error.message, } });
                dispatch(actions.apiCallFailed(error?.response?.data?.error ?? error.message));
                if (onError) dispatch({ type: onError, payload: error.message });
              }
            }
          }
        };

export default api;