import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import { Provider, connect } from 'react-redux';
import { registerServiceWorker } from './serviceWorker'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store()}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

registerServiceWorker()
