import {
  updateAccount as _updateAccount, updateAccountFailed, updateAccountSuccess,
  getPayouts as _getPayouts, getPayoutsFailed, getPayoutsSuccess,
  createPayout as _createPayout, createPayoutFailed, createPayoutSuccess,
  sendPayoutOTP as _sendPayoutOTP, sendPayoutOTPFailed, sendPayoutOTPSuccess,
  validatePayoutOTP as _validatePayoutOTP, validatePayoutOTPFailed, validatePayoutOTPSuccess,
  createRequest as _createRequest, createRequestFailed, createRequestSuccess,
  getRequests as _getRequests, getRequestsFailed, getRequestsSuccess,
  updatePayout as _updatePayout, updatePayoutFailed, updatePayoutSuccess,
  getAccountBalance as _getAccountBalance, getAccountBalanceFailed, getAccountBalanceSuccess,
  updateFCMToken as _updateFCMToken, updateFCMTokenFailed, updateFCMTokenSuccess
   
} from '../reducers/user.reducer';
import { apiCallBegan } from '../api';
import { jsonToUrlParams } from '../../utils/url';

export const updateAccount = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/users/accounts/${data?._id}`,
      data,
      method: 'PUT',
      onStart: _updateAccount.type,
      onSuccess: updateAccountSuccess.type,
      onError: updateAccountFailed.type,
      notificationMessage: 'Account Switched'
    })
  );
};

export const updateFCMToken = (data) => (dispatch) => {
  console.log('data: ', data);
  return dispatch(
    apiCallBegan({
      url: `/users/fcm/web-token`,
      data,
      method: 'POST',
      onStart: _updateFCMToken.type,
      onSuccess: updateFCMTokenSuccess.type,
      onError: updateFCMTokenFailed.type,
    })
  );
};

export const updatePayout = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/payouts/${data?._id}`,
      data,
      method: 'PUT',
      onStart: _updatePayout.type,
      onSuccess: updatePayoutSuccess.type,
      onError: updatePayoutFailed.type,
    })
  );
};


export const createPayout = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: '/payouts',
      data,
      method: 'POST',
      onStart: _createPayout.type,
      onSuccess: createPayoutSuccess.type,
      onError: createPayoutFailed.type,
      notificationMessage: 'Payout request Created.'
    })
  );
};

export const createRequest = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: '/common/requests',
      data,
      method: 'POST',
      onStart: _createRequest.type,
      onSuccess: createRequestSuccess.type,
      onError: createRequestFailed.type,
      notificationMessage: 'Request Created.'
    })
  );
};



export const sendPayoutOTP = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: '/common/payouts/send-otp',
      data,
      method: 'POST',
      onStart: _sendPayoutOTP.type,
      onSuccess: sendPayoutOTPSuccess.type,
      onError: sendPayoutOTPFailed.type,
      notificationMessage: 'OTP Sent.'
    })
  );
};

export const validatePayoutOTP = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: '/common/payouts/otp',
      data,
      method: 'POST',
      onStart: _validatePayoutOTP.type,
      onSuccess: validatePayoutOTPSuccess.type,
      onError: validatePayoutOTPFailed.type,
    })
  );
};

export const getPayouts = (data) => (dispatch) => {
  let url = '/payouts';
  let urlParams = jsonToUrlParams(data);
  return dispatch(
    apiCallBegan({
      url: `${url}?${urlParams}`,
      method: 'GET',
      onStart: _getPayouts.type,
      onSuccess: getPayoutsSuccess.type,
      onError: getPayoutsFailed.type,
    })
  );
};


export const getRequests = (data) => (dispatch) => {
  let url = '/common/requests';
  let urlParams = jsonToUrlParams(data);
  return dispatch(
    apiCallBegan({
      url: `${url}?${urlParams}`,
      method: 'GET',
      onStart: _getRequests.type,
      onSuccess: getRequestsSuccess.type,
      onError: getRequestsFailed.type,
    })
  );
};

export const getAccountBalance = (data) => (dispatch) => {
  let url = `/users/accounts/balances/${data.accountId}`;
  return dispatch(
    apiCallBegan({
      url: url,
      method: 'GET',
      onStart: _getAccountBalance.type,
      onSuccess: getAccountBalanceSuccess.type,
      onError: getAccountBalanceFailed.type,
    })
  );
};


