import React, { useEffect, useState } from 'react';

import { useTheme, Grid, IconButton, TableSortLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { tokens } from '../../../theme';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PAYOUT_STATUS } from '../../../config';
import { getUsers } from '../../../state/actions/admin.actions';
import { getPayouts } from '../../../state/actions/user.actions';

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Payouts = ({ userId, accountId }) => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  function handleSort(property) {
    const direction = sortBy === property && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortBy(property);
    setSortDirection(direction);
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const _getPayouts = () => {
    let params = {};
    if (sortBy && sortDirection) {
      params.sort = `${sortBy}|${sortDirection}`;
    }
    dispatch(getPayouts({
      page: page + 1, limit: rowsPerPage, expand: 'account,user', user: userId, account: accountId
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [payouts, setPayouts] = useState([]);

  const dispatch = useDispatch();
  const payoutsState = useSelector((state) => state.user?.payouts);

  useEffect(() => {
    if (payoutsState?.payouts?.length) {
      setPayouts(payoutsState);
    } else {
      setPayouts([]);
    }
  }, [payoutsState]);


  useEffect(() => {
    _getPayouts(page + 1, rowsPerPage);
  }, [page, rowsPerPage, accountId, userId, sortBy, sortDirection]);

  useEffect(() => {
    dispatch(getUsers({
      page: 1, limit: 100, roles: 'CUSTOMER'
    }));
  }, []);

  function getPayoutStatusColor(status) {
    switch (status) {
    case 1:
      return '#FFC339';
    case 2:
      return colors.green;
    case 3:
      return '#FF4757';
    default:
      return '#FF4757';
    }
  }

  function getPayoutStatus(status) {
    switch (status) {
    case PAYOUT_STATUS.PROCESSED:
      return 'Processed';
    case PAYOUT_STATUS.REJECTED:
      return 'Rejected';
    case PAYOUT_STATUS.REQUESTED:
      return 'Requested';
    default:
      return 'NA';
    }
  }

  function handlePayoutViewOpen(row) {
    navigate('/admin-payout-view', {
      state: {
        payoutData: {
          ...row,
        }
      }
    });
  }

  return (
    <Grid item xs={12} md={12} lg={12} sm={12}>

      <TableContainer sx={{
        boxShadow: '0px 4px 12px 8px rgba(0, 0, 0, 0.1)'
      }} component={Paper}>
        <Table >
          <TableHead>
            <TableRow sx={{
              fontFamily: 'Poppins'
            }}>
              <TableCell sx={{
                pl: 3,
                color: '#747D8C'
              }}><TableSortLabel
                active={sortBy === 'user.username'}
                direction={sortBy === 'user.username' ? sortDirection : 'asc'}
                onClick={() => handleSort('user.username')}
              >
                  Username
                </TableSortLabel></TableCell>
              <TableCell sx={{
                color: '#747D8C'
              }}><TableSortLabel
                active={sortBy === 'user.mobile'}
                direction={sortBy === 'user.mobile' ? sortDirection : 'asc'}
                onClick={() => handleSort('user.mobile')}
              >
                  Mobile Number
                </TableSortLabel></TableCell>
              <TableCell sx={{
                color: '#747D8C'
              }}><TableSortLabel
                active={sortBy === 'account.accountNumber'}
                direction={sortBy === 'account.accountNumber' ? sortDirection : 'asc'}
                onClick={() => handleSort('account.accountNumber')}
              >
                  Account No
                </TableSortLabel></TableCell>
              <TableCell sx={{
                color: '#747D8C'
              }}><TableSortLabel
                active={sortBy === 'account.accountType.label'}
                direction={sortBy === 'account.accountType.label' ? sortDirection : 'asc'}
                onClick={() => handleSort('account.accountType.label')}
              >
                  Account Type
                </TableSortLabel></TableCell>
              <TableCell sx={{
                color: '#747D8C'
              }}><TableSortLabel
                active={sortBy === 'account.amount'}
                direction={sortBy === 'account.amount' ? sortDirection : 'asc'}
                onClick={() => handleSort('account.amount')}
              >
                  Account Size
                </TableSortLabel></TableCell>

              <TableCell sx={{
                color: '#747D8C'
              }}><TableSortLabel
                active={sortBy === 'updatedAt'}
                direction={sortBy === 'updatedAt' ? sortDirection : 'asc'}
                onClick={() => handleSort('updatedAt')}
              >
                  Requested Date
                </TableSortLabel></TableCell>
              <TableCell sx={{
                color: '#747D8C'
              }}><TableSortLabel
                active={sortBy === 'status'}
                direction={sortBy === 'status' ? sortDirection : 'asc'}
                onClick={() => handleSort('status')}
              >
                  Status
                </TableSortLabel></TableCell>
              <TableCell sx={{
                color: '#747D8C'
              }}>Action</TableCell>
            </TableRow>
          </TableHead>
          {payouts?.payouts?.length ? <TableBody>
            {payouts?.payouts?.map((row) => {
              return (
                <TableRow key={row._id}>
                  <TableCell width={180} sx={{
                    pl: 3,
                    fontSize: 13,
                    fontWeight: 500,

                  }}>{row?.user?.username}</TableCell>
                  <TableCell width={180} sx={{
                    fontSize: 13,
                    fontWeight: 500,

                  }}>{'NA'}</TableCell>
                  <TableCell width={220} sx={{
                    fontSize: 13,
                    fontWeight: 500,

                  }}>{row?.account?.accountNumber}</TableCell>
                  <TableCell width={180} sx={{
                    fontSize: 13,
                    fontWeight: 500,

                  }}>{row?.account?.accountType?.label}</TableCell>
                  <TableCell width={180} sx={{
                    fontSize: 13,
                    fontWeight: 500,

                  }}>{row?.account?.accountSize?.label}</TableCell>

                  <TableCell width={180} sx={{
                    fontSize: 13,
                    fontWeight: 500,

                  }}>{dayjs(row.createdAt).format('DD-MM-YYYY')}</TableCell>
                  <TableCell width={180} sx={{
                    fontSize: 13,
                    fontWeight: 500,
                    color: getPayoutStatusColor(row?.status),

                  }}>{getPayoutStatus(row?.status)}</TableCell>
                  <TableCell width={180} >
                    <IconButton size='small'
                      onClick={() => handlePayoutViewOpen(row)}
                      sx={{
                        background: '#60C68929',
                        borderRadius: 2,
                      }}>
                      <VisibilityOutlinedIcon fontSize='small' />
                    </IconButton></TableCell>
                </TableRow>
              );
            })} </TableBody>
            :
            <TableBody>
              <TableRow>
                <TableCell sx={{
                  fontSize: 13,
                  textAlign: 'center'
                }} colSpan={9}>No Payouts Found</TableCell>

              </TableRow>
            </TableBody>
          }

        </Table>
      </TableContainer>
      {payouts?.meta && <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={payouts.meta?.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
        nextIconButtonText="Next Page"
        backIconButtonText="Previous Page"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
        showFirstButton
        showLastButton

      />}
    </Grid>
  );
};

export default Payouts;
