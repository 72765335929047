import {
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TableSortLabel,
  Button,
  useTheme,
  TablePagination
} from '@mui/material';
import { tokens } from '../../theme';
import { getUser } from '../../state/actions/auth.actions';

import { Search as SearchIcon } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Grid
} from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getTradesHistory, downloadTrades } from '../../state/actions/trade.actions';
import CustomerTopbar from '../global/CustomerTopbar';
import InstrumentList from '../instrument/InstrumentList';
import { useUserContext } from '../../UserContext';
const dayjs = require('dayjs');

const History = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');

  const [searchParams, setSearchParams] = useSearchParams({});


  const dispatch = useDispatch();

  const { accountContext, userContext, updateAccountContext, updateUserContext } = useUserContext();

  const auth = useSelector((state) => state.auth);
  const tradesHistoryState = useSelector((state) => state.trade?.tradesHistory);
  const [instruments, setInstruments] = useState([]);


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [user, setUser] = useState(null);
  const userState = useSelector((state) => state.auth?.user);
  const [orders, setOrders] = useState([]);
  const [searchText, setSearchText] = useState('');

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  function handleSort(property) {
    const direction = sortBy === property && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortBy(property);
    setSortDirection(direction);
  }

  useEffect(() => {
    const checkIsMobile = () => {
      const mobileMediaQuery = window.matchMedia('(max-width: 767px)');
      setIsMobile(mobileMediaQuery.matches);
    };

    checkIsMobile();
    const resizeListener = () => {
      checkIsMobile();
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };

  }, []);

  useEffect(() => {
    if (accountContext) {
      setSelectedAccount(accountContext);
      setSearchParams({ accountId: accountContext?._id, userId: userId });
    }
  }, [accountContext])

  useEffect(() => {
    if (userContext) {
      setUser(userContext);
    }
  }, [userContext])

  useEffect(() => {
    if (userState?.user) {
      updateAccountContext(
        userState?.user?.accounts?.find((account) => account?._id == selectedAccount?._id)
      );
    }
  }, [userState])


  useEffect(() => {
    if (tradesHistoryState?.trades?.length) {
      setOrders(tradesHistoryState);
    } else {
      setOrders([]);
    }
  }, [tradesHistoryState]);

  function _getTrades() {
    if (selectedAccount) {
      let params = {};
      if (searchText?.length >= 2) {
        params.q = searchText;
      }
      if (sortBy && sortDirection) {
        params.sort = `${sortBy}|${sortDirection}`;
      }

      dispatch(getTradesHistory({
        page: page + 1, limit: rowsPerPage, userId, accountId: selectedAccount?._id, status: '1,3,4,5,6,7',
        ...params
      }));
    }
  }



  useEffect(() => {
    if (selectedAccount) {
      _getTrades();
    }
  }, [selectedAccount,]);

  useEffect(() => {
    _getTrades();
  }, [page, rowsPerPage, searchText, sortBy, sortDirection]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const onInstrumentsChange = (instruments) => {
    setInstruments(instruments);
  };


  function onBuySellChange() {
    if (selectedAccount) {
      _getTrades();
      dispatch(getUser({ userId }))
    }
  }

  function getTradeStatusColor(status) {
    switch (status) {
      case 1:
        return colors.green;
      case 2:
        return colors.green;
      case 3:
        return colors.green;
      case 4:
        return '#FF4757';
      case 5:
        return '#FF4757';
      case 6:
        return colors.green;
      default:
        return '#FF4757';
    }
  }

  function getTradeStatus(status) {
    switch (status) {
      case 1:
        return 'Completed';
      case 2:
        return 'Open';
      case 3:
        return 'Completed';
      case 4:
        return 'Cancelled';
      case 5:
        return 'Rejected';
      case 6:
        return 'Partially Completed';
      case 7:
        return 'Freezed';
      default:
        return 'NA';
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function downloadHistory() {
    dispatch(downloadTrades({ type: 2, page: page + 1, limit: rowsPerPage, userId, accountId: selectedAccount?._id, status: '1,3,4,5,6,7' }));
  }

  return (
    <>
      {auth?.user?.user && <CustomerTopbar user={auth?.user?.user} account={selectedAccount} />}
      <div style={{
        display: 'flex', height: '100vh', 
        paddingTop: isMobile ? '114px' : '60px',
        backgroundColor: colors.home[100]
      }}>
        <InstrumentList user={user} account={selectedAccount} onInstrumentsChange={onInstrumentsChange} onBuySellChange={onBuySellChange} />
        <Grid
          style={{
            paddingLeft: 18,
            flex: 2,
            overflowX: 'auto',
          }}
          pt={2}
          xs={12}
          sm={12}
          md={8.5}
          lg={8.5}
        >
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'flex-end' }}>
              <Grid container style={{
                paddingRight: 30,
                paddingBottom: 5
              }}>
                <Grid item md={12} xs={12} style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                  <TextField
                    variant="outlined"
                    sx={{
                      width: { md: 200, lg: 200, sm: '100%', xs: '100%' },
                    }}
                    value={searchText}
                    onChange={handleSearchChange}
                    fullWidth
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    onClick={downloadHistory}
                    sx={{
                      width: 170,
                      marginLeft: 2,
                      height: 52,
                      color: colors.teal,
                      borderColor: colors.teal,
                      '&:hover': {
                        borderColor: colors.teal
                      },
                    }} variant="outlined" startIcon={<DownloadIcon />}>
                    Download
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <div style={{
              marginRight: 30
            }} >
              <TableContainer >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow sx={{
                      '&:nth-of-type(odd)': {
                        backgroundColor: theme.palette.action.hover,
                      },
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}>
                      <TableCell sx={{
                        color: colors.tableText,
                        fontWeight: 500,
                        fontSize: 12,
                        width: 100,
                      }} >Order ID</TableCell>
                      <TableCell sx={{
                        color: colors.tableText,
                        fontWeight: 500,
                        fontSize: 12,
                        width: 140,
                      }} ><TableSortLabel
                        active={sortBy === 'createdAt'}
                        direction={sortBy === 'createdAt' ? sortDirection : 'asc'}
                        onClick={() => handleSort('createdAt')}
                      >
                          Time
                        </TableSortLabel></TableCell>
                      <TableCell sx={{
                        color: colors.tableText,
                        fontWeight: 500,
                        width: 40,
                        fontSize: 12,
                      }} ><TableSortLabel
                        active={sortBy === 'type'}
                        direction={sortBy === 'type' ? sortDirection : 'asc'}
                        onClick={() => handleSort('type')}
                      >
                          Type
                        </TableSortLabel></TableCell>
                      <TableCell sx={{
                        color: colors.tableText,
                        fontWeight: 500,
                        fontSize: 12,
                        width: 70,
                      }} ><TableSortLabel
                        active={sortBy === 'instrument'}
                        direction={sortBy === 'instrument' ? sortDirection : 'asc'}
                        onClick={() => handleSort('instrument')}
                      >
                          Symbol
                        </TableSortLabel></TableCell>
                      <TableCell sx={{
                        color: colors.tableText,
                        fontWeight: 500,
                        fontSize: 12,
                        width: 20,
                      }} ><TableSortLabel
                        active={sortBy === 'quantity'}
                        direction={sortBy === 'quantity' ? sortDirection : 'asc'}
                        onClick={() => handleSort('quantity')}
                      >
                          Quantity
                        </TableSortLabel></TableCell>
                      <TableCell sx={{
                        color: colors.tableText,
                        fontWeight: 500,
                        fontSize: 12,
                        width: 30,
                      }} ><TableSortLabel
                        active={sortBy === 'price'}
                        direction={sortBy === 'price' ? sortDirection : 'asc'}
                        onClick={() => handleSort('price')}
                      >
                          Price
                        </TableSortLabel></TableCell>
                      <TableCell sx={{
                        color: colors.tableText,
                        fontWeight: 500,
                        fontSize: 12,
                        width: 30,
                      }} ><TableSortLabel
                        active={sortBy === 'status'}
                        direction={sortBy === 'status' ? sortDirection : 'asc'}
                        onClick={() => handleSort('status')}
                      >
                          Status
                        </TableSortLabel></TableCell>
                      <TableCell sx={{
                        color: colors.tableText,
                        fontWeight: 500,
                        fontSize: 12,
                        width: 30,
                      }} ><TableSortLabel
                        active={sortBy === 'profitLoss'}
                          direction={sortBy === 'profitLoss' ? sortDirection : 'asc'}
                          onClick={() => handleSort('profitLoss')}
                      >
                          P/L
                        </TableSortLabel></TableCell>
                    </TableRow>
                  </TableHead>
                  {orders?.trades?.length ?
                    <TableBody>
                      {orders?.trades?.map((trade) => {
                        return (
                          <TableRow
                            sx={{
                              height: 30
                            }}
                            key={trade._id}>
                            <TableCell sx={{
                              color: colors.tableText,
                              fontWeight: 400,
                              fontSize: 12,
                            }} >{`${trade.identifier}`}</TableCell>
                            <TableCell sx={{
                              color: colors.tableText,
                              fontWeight: 400,
                              fontSize: 12,
                            }} >{trade?.orderType == 1 ? dayjs(trade?.createdAt).format('DD MMM, hh:mm:ss:A') : trade?.limitExecution ? dayjs(trade?.limitExecution).format('DD MMM, hh:mm:ss:A') : dayjs(trade?.createdAt).format('DD MMM, hh:mm:ss:A')}</TableCell>
                            <TableCell sx={{
                              color: trade.type === 1 ? colors.green : '#FF4757',
                              fontWeight: 400,
                              fontSize: 12,
                            }} >{trade.type === 1 ? 'BUY' : 'SELL'}</TableCell>
                            <TableCell sx={{
                              color: colors.tableText,
                              fontWeight: 400,
                              fontSize: 12,
                            }} >{`${trade?.instrument}.x`}</TableCell>
                            <TableCell sx={{
                              color: colors.tableText,
                              fontWeight: 400,
                              fontSize: 12,
                            }} >{trade?.quantity}</TableCell>
                            <TableCell sx={{
                              color: colors.tableText,
                              fontWeight: 400,
                              fontSize: 12,
                            }}
                            >{trade?.price}</TableCell>
                            <TableCell sx={{
                              color: getTradeStatusColor(trade?.status),
                              fontWeight: 400,
                              fontSize: 12,
                            }} >{getTradeStatus(trade?.status)}</TableCell>
                            <TableCell sx={{
                              color: !trade?.profitLoss ? colors.blackWhite :  trade?.profitLoss > 0 ? colors.green : colors.red,
                              fontWeight: 400,
                              fontSize: 12,
                            }} >{!trade?.profitLoss ? 'NA' : trade?.profitLoss > 0 ? `+${trade?.profitLoss}` : trade?.profitLoss}</TableCell>

                          </TableRow>
                        );
                      })}

                    </TableBody> : <TableBody>
                      <TableRow>
                        <TableCell sx={{
                          fontSize: 15,
                          textAlign: 'center'
                        }} colSpan={9}>No History</TableCell>

                      </TableRow>
                    </TableBody>
                  }
                </Table>
              </TableContainer>
              {orders?.meta && <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={orders.meta?.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />}
            </div>

          </Box>
        </Grid>
      </div>
    </>
  );
};

export default History;
