import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    activeAccount: null
  },

  reducers: {
    updateAccount: (auth, action) => {
      auth.loading = true;
    },

    updateAccountSuccess: (auth, action) => {
      auth.updatedAccount = action.payload;
      auth.activeAccount = action.payload;
      auth.loading = false;
    },

    updateAccountFailed: (auth, action) => {
      auth.updateAccountError = action.payload;
      auth.loading = false;
    },

    updateFCMToken: (auth, action) => {
      auth.loading = true;
    },

    updateFCMTokenSuccess: (auth, action) => {
      auth.updatedFCMToken = action.payload;
      auth.loading = false;
    },

    updateFCMTokenFailed: (auth, action) => {
      auth.loading = false;
    },

    updatePayout: (auth, action) => {
      auth.loading = true;
    },

    updatePayoutSuccess: (auth, action) => {
      auth.updatedPayout = action.payload;
      auth.loading = false;
    },

    updatePayoutFailed: (auth, action) => {
      auth.updatePayoutError = action.payload;
      auth.loading = false;
    },

    createPayout: (auth, action) => {
      auth.loading = true;
    },

    createPayoutSuccess: (auth, action) => {
      auth.payout = action.payload;
      auth.loading = false;
    },

    createPayoutFailed: (auth, action) => {
      auth.payoutError = action.payload;
      auth.loading = false;
    },

    createRequest: (auth, action) => {
      auth.loading = true;
    },

    createRequestSuccess: (auth, action) => {
      auth.requests = action.payload;
      auth.loading = false;
    },

    createRequestFailed: (auth, action) => {
      auth.requestsError = action.payload;
      auth.loading = false;
    },

    sendPayoutOTP: (auth, action) => {
      auth.loading = true;
    },

    sendPayoutOTPSuccess: (auth, action) => {
      auth.payoutSendOTP = action.payload;
      auth.loading = false;
    },

    sendPayoutOTPFailed: (auth, action) => {
      auth.payoutSendOTPError = action.payload;
      auth.loading = false;
    },


    validatePayoutOTP: (auth, action) => {
      auth.loading = true;
    },

    validatePayoutOTPSuccess: (auth, action) => {
      auth.payoutOTP = action.payload;
      auth.loading = false;
    },

    validatePayoutOTPFailed: (auth, action) => {
      auth.payoutOTPError = action.payload;
      auth.loading = false;
    },
    getPayouts: (auth, action) => {
      auth.loading = true;
    },

    getPayoutsSuccess: (auth, action) => {
      auth.payouts = action.payload;
      auth.loading = false;
    },

    getPayoutsFailed: (auth, action) => {
      auth.payouts = [];
      auth.loading = false;
    },

    getRequests: (auth, action) => {
      auth.loading = true;
    },

    getRequestsSuccess: (auth, action) => {
      auth.requests = action.payload;
      auth.loading = false;
    },

    getRequestsFailed: (auth, action) => {
      auth.requests = [];
      auth.loading = false;
    },

    getAccountBalance: (auth, action) => {
      auth.loading = true;
    },

    getAccountBalanceSuccess: (auth, action) => {
      auth.accountBalances = action.payload;
      auth.loading = false;
    },

    getAccountBalanceFailed: (auth, action) => {
      auth.accountBalances = [];
      auth.loading = false;
    },
  },
});

const { actions, reducer } = userSlice;
export const { updateAccount, updateAccountFailed, updateAccountSuccess,
  updatePayout, updatePayoutFailed, updatePayoutSuccess,
  getPayouts, getPayoutsFailed, getPayoutsSuccess, createPayout, createPayoutFailed, createPayoutSuccess,
  sendPayoutOTP, sendPayoutOTPFailed, sendPayoutOTPSuccess, validatePayoutOTP,
  validatePayoutOTPFailed, validatePayoutOTPSuccess,
  createRequest, createRequestSuccess, createRequestFailed,
  getRequests, getRequestsFailed, getRequestsSuccess,
  getAccountBalance, getAccountBalanceFailed, getAccountBalanceSuccess,
updateFCMToken, updateFCMTokenFailed, updateFCMTokenSuccess} = actions;
export default reducer;
