
import React from 'react';
// import { useSocket } from '../../SocketContext';
import { io } from 'socket.io-client';
import { SOCKET_URL } from '../../config';

const socket = io(SOCKET_URL, {
    transportOptions: {
        polling: {
            extraHeaders: {},
        },
    },
});


const TermsAndConditions = (props) => {

    return (
        <div style={{
            padding: 70,
            backgroundColor: '#00002a'
        }}>
            <div>
                <div style={{ textAlign: 'start', color: '#FFFFFF', fontSize: '10px', border: '0px' }}>
                    <div style={{ textAlign: 'start', color: '#FFFFFF', fontSize: '10px', border: '0px' }}>
                        <div style={{ border: '0px' }}></div>
                    </div>
                    <div style={{ textAlign: 'start', color: '#FFFFFF', fontSize: '10px', border: '0px' }}>
                        <div style={{ border: '0px' }}>
                            <div style={{ textAlign: 'start', border: '0px' }}>
                                <h1 style={{ color: 'rgb(var(--color_36))', fontSize: '60px', border: '0px' }}>Terms &amp; Conditions<span style={{ color: 'rgb(var(--color_41))', border: '0px' }}>.</span></h1>
                            </div>
                            {/* <div style={{ border: '0px' }}>
                                <a href="https://www.upperciruit.in/_files/ugd/cf04ae_a099125af02f4c298dc01e4c9624db50.pdf" target="_blank" title="website_terms_and_conditions.pdf" style={{ border: '0px' }}>
                                    <div style={{ border: '0px' }}>
                                        <img alt="website_terms_and_conditions.pdf" src="https://static.wixstatic.com/media/40d80b8089c8418a961cb75d96d491f7.png/v1/fill/w_125,h_168,al_c,lg_1,q_85,enc_auto/40d80b8089c8418a961cb75d96d491f7.png" style={{ border: '0px' }} />
                                    </div>
                                </a>
                            </div> */}
                            <div style={{ border: '0px' }}>
                                <div style={{ border: '0px' }}>
                                    <div style={{ border: '0px' }}>
                                        <div style={{ textAlign: 'start', border: '0px' }}>
                                            <h3 style={{ color: 'rgb(var(--color_36))', fontSize: '40px', border: '0px' }}>THE AGREEMENT:</h3>
                                        </div>
                                        <div style={{ textAlign: 'start', border: '0px' }}>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>The use of this website and services on this website provided by Hash Gametech Pvt. Ltd. (hereinafter referred to as "Owner") are subject to the following Terms & Conditions (hereinafter the "Terms of Service"), all parts and subparts of which are specifically incorporated by reference here together with the Privacy Policy. Following are the Terms of Service governing your use of <a href="http://www.uctrader.in/" target="_blank" style={{ color: 'inherit', border: '0px' }}>www.uctrader.in</a> (the "Website"), all pages on the Website and any services provided by or on this Website ("Services").</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>&nbsp;</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>By accessing either directly or through a hyperlink, the Website, and/or purchasing something from us, you engage in our "Service" and agree to be bound by the Terms of Service including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation vendors, buyers, customers, merchants, browsers and/or contributors of content. You acknowledge and agree that the Website may use your personal information in the manner described in our Privacy Policy which sets forth how information collected about you is collected, used and stored.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ border: '0px' }}>
                                <div style={{ border: '0px' }}>
                                    <div style={{ border: '0px' }}>
                                        <div style={{ textAlign: 'start', border: '0px' }}>
                                            <h4 style={{ color: 'rgb(var(--color_36))', fontSize: '30px', border: '0px' }}><span style={{ fontSize: '30px', border: '0px' }}>1) DEFINITIONS</span></h4>
                                        </div>
                                        <div style={{ textAlign: 'start', border: '0px' }}>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>The parties referred to in these Terms of Service shall be defined as follows:</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>&nbsp;</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}><strong><span style={{ border: '0px' }}>a) Owner, Us, We:</span></strong> The Owner, as the creator, operator, and publisher of the Website, makes the Website, and certain Services on it, available to users. Hash Gametech Pvt. Ltd., Owner, Us, We, Our, Ours and other first-person pronouns will refer to the Owner, as well as all employees and affiliates of the Owner.</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}><strong><span style={{ border: '0px' }}>b) You, the User, the Client:</span></strong> You, as the user of the Website, will be referred to throughout these Terms of Service with second-person pronouns such as You, Your, Yours, or as User or Client. For the purpose of these Terms of Service, the term "User" or "you" shall mean any natural or legal person who is accessing the Website. The term '&quot;Your&quot;' shall be construed accordingly.</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}><strong><span style={{ border: '0px' }}>c) Parties:</span></strong> Collectively, the parties to these Terms of Service (the Owner and You) will be referred to as Parties.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ border: '0px' }}>
                                <div style={{ border: '0px' }}>
                                    <div style={{ border: '0px' }}>
                                        <div style={{ textAlign: 'start', border: '0px' }}>
                                            <h4 style={{ color: 'rgb(var(--color_36))', fontSize: '30px', border: '0px' }}><span style={{ fontSize: '30px', border: '0px' }}>2) ASSENT &amp; ACCEPTANCE</span></h4>
                                        </div>
                                        <div style={{ textAlign: 'start', border: '0px' }}>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>By using the Website, You warrant that You have read and reviewed these Terms of Service and that You agree to be bound by it. If You do not agree to be bound by these Terms of Service, please leave the Website immediately. The Owner only agrees to provide use of this Website and Services to You if You assent to these Terms of Service. Further, based on the Services obtained by a User, additional terms and conditions in respect of the specific Services may apply, which shall be deemed an agreement between the Users and the Owner</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ border: '0px' }}>
                                <div style={{ border: '0px' }}>
                                    <div style={{ border: '0px' }}>
                                        <div style={{ textAlign: 'start', border: '0px' }}>
                                            <h4 style={{ color: 'rgb(var(--color_36))', fontSize: '30px', border: '0px' }}><span style={{ fontSize: '30px', border: '0px' }}>3) ABOUT THE SITE</span></h4>
                                        </div>
                                        <div style={{ textAlign: 'start', border: '0px' }}>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>The Website is an online store which carries out sales of the following: Gaming based Virtual Stock Market Trading. We reserve the right to refuse service or refuse to sell the products on the Website at our sole discretion to anyone for any reason at any time.</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>&nbsp;</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>The Website does not screen or censor the users who register on and access the Website. You assume all risks associated with dealing with other users with whom you come in contact through the Website. You agree to use the Website only for lawful purposes without infringing the rights of or restricting the use of this Website by any third party.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ border: '0px' }}>
                                <div style={{ border: '0px' }}>
                                    <div style={{ border: '0px' }}>
                                        <div style={{ textAlign: 'start', border: '0px' }}>
                                            <h4 style={{ color: 'rgb(var(--color_36))', fontSize: '30px', border: '0px' }}><span style={{ fontSize: '30px', border: '0px' }}>4) LICENSE TO USE WEBSITE</span></h4>
                                        </div>
                                        <div style={{ textAlign: 'start', border: '0px' }}>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>The Owner may provide You with certain information as a result of Your use of the Website or Services. Such information may include but is not limited to, documentation, data, or information developed by the Owner, and other materials which may assist in Your use of the Website or Services ("Owner Materials"). Subject to these Terms of Service, the Owner grants You a non-exclusive, limited, nontransferable and revocable license to use the Owner Materials solely in connection with Your use of the Website and Services. The Owner Materials may not be used for any other purpose and this license terminates upon Your cessation of use of the Website or Services or at the termination of these Terms of Service.</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>&nbsp;</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>You agree not to collect the contact information of other Users from the Website or download or copy any information by means of unsolicited access so as to communicate directly with them or for any reason whatsoever.</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>&nbsp;</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>Any unauthorized use by you shall terminate the permission or license granted to you by the Website and You agree that you shall not bypass any measures used by the Owner to prevent or restrict access to the Website.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ border: '0px' }}>
                                <div style={{ border: '0px' }}>
                                    <div style={{ border: '0px' }}>
                                        <div style={{ textAlign: 'start', border: '0px' }}>
                                            <h4 style={{ color: 'rgb(var(--color_36))', fontSize: '30px', border: '0px' }}><span style={{ fontSize: '30px', border: '0px' }}>5) INTELLECTUAL PROPERTY</span></h4>
                                        </div>
                                        <div style={{ textAlign: 'start', border: '0px' }}>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>You agree that the Website and all Services provided by the Owner are the property of the Owner, including all copyrights, trademarks, trade secrets, patents, and other intellectual property ("Owner IP"). You agree that the Owner owns all rights, title, and interest in and to the Owner IP and that You will not use the Owner IP for any unlawful or infringing purpose. You agree not to reproduce or distribute the Owner IP in any way, including electronically or via registration of any new trademarks, trade names, service marks or Uniform Resource Locators (URLs), without express written permission from the Owner.</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>&nbsp;</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>a) In order to make the Website and Services available to You, You hereby grant the Owner a royalty-free, non-exclusive, worldwide license to copy, display, use, broadcast, transmit and make derivative works of any content You publish, upload or otherwise make available to the Website ("Your Content"). The Owner claims no further proprietary rights in Your Content.</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>&nbsp;</p>
                                            <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>b) If You feel that any of Your intellectual property rights have been infringed or otherwise violated by the posting of information or media by another of Our users, please contact Us and let Us know.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'start', color: '#FFFFFF', fontSize: '10px', border: '0px' }}><br /></div>
            </div>

            <div style={{ textAlign: 'start', color: '#FFFFFF', fontSize: '10px', border: '0px' }}>
                <div style={{ textAlign: 'start', color: '#FFFFFF', fontSize: '10px', border: '0px' }}>
                    <div style={{ border: '0px' }}>
                        <div style={{ border: '0px' }}>
                            <div style={{ textAlign: 'start', border: '0px' }}>
                                <div style={{ textAlign: 'start', color: '#FFFFFF', fontSize: '10px', border: '0px' }}>
                                    <div style={{ border: '0px' }}>
                                        <div style={{ border: '0px' }}>
                                            <div style={{ textAlign: 'start', border: '0px' }}>
                                                <h4 style={{ color: 'rgb(var(--color_36))', fontSize: '30px', border: '0px' }}><span style={{ fontSize: '30px', border: '0px' }}>6) ACCEPTABLE USE</span></h4>
                                            </div>
                                            <div style={{ textAlign: 'start', border: '0px' }}>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>You agree not to use the Website or Services for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Website or Services in any way that could damage the Website, Services or general business of the Owner.</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>&nbsp;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>a) You further agree not to use the Website or Services:</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>I) To harass, abuse, or threaten others or otherwise violate any person's legal rights;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>II) To violate any intellectual property rights of the Owner or any third party;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>III) To upload or otherwise disseminate any computer viruses or other software that may damage the property of another;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>IV) To perpetrate any fraud;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>V) To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>VI) To publish or distribute any obscene or defamatory material;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>VII) To publish or distribute any material that incites violence, hate or discrimination towards any group;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>VIII) To unlawfully gather information about others.</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>&nbsp;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>You are prohibited from using the site or its content:</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>(a) for any unlawful purpose;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>(b) to solicit others to perform or participate in any unlawful acts;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>(c) to infringe on any third party's intellectual property or proprietary rights, or rights of publicity or privacy, whether knowingly or unknowingly;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>(d) to violate any local, federal or international law, statute, ordinance or regulation;&nbsp;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>(e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>(f) to submit false or misleading information or any content which is defamatory, libelous, threatening, unlawful, harassing, indecent, abusive, obscene, or lewd and lascivious or pornographic, or exploits minors in any way or assists in human trafficking or content 5/14 that would violate rights of publicity and/or privacy or that would violate any law;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>(g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>(h) to collect or track the personal information of others;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>(i) to damage, disable, overburden, or impair the Website or any other party's use of the Website;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>(j) to spam, phish, pharm, pretext, spider, crawl, or scrape;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>(j) for any obscene or immoral purpose; or</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>(k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>(l) to personally threaten or has the effect of personally threatening other Users. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses. We reserve the full authority to review all content posted by Users on the Website. You acknowledge that the Website is not responsible or liable and does not control the content of any information that may be posted to the Website by You or other User of the Website and you are solely responsible for the same. You agree that You shall not upload, post, or transmit any content that you do not have a right to make available (such as, the intellectual property of another party).</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>&nbsp;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>You agree to comply with all applicable laws, statutes and regulations concerning your use of the Website and further agree that you will not transmit any information, data, text, files, links, software, chats, communication or other materials that are abusive, invasive of another's privacy, harassing, defamatory, vulgar, obscene, unlawful, false, misleading, harmful, threatening, hateful or racially or otherwise objectionable, including without limitation material of any kind or nature that encourages conduct that could constitute a criminal offence, give rise to civil liability or otherwise violate any applicable local, state, provincial, national, or international law or regulation, or encourage the use of controlled substances.</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>&nbsp;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libellous, defamatory, pornographic, obscene or otherwise objectionable or violates any party's intellectual property or these Terms of Service.</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>&nbsp;</p>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ textAlign: 'start', color: '#FFFFFF', fontSize: '10px', border: '0px' }}>
                                    <div style={{ border: '0px' }}>
                                        <div style={{ border: '0px' }}>
                                            <div style={{ textAlign: 'start', border: '0px' }}>
                                                <h4 style={{ color: 'rgb(var(--color_36))', fontSize: '30px', border: '0px' }}><span style={{ fontSize: '30px', border: '0px' }}>7) COMMUNICATION</span></h4>
                                            </div>
                                            <div style={{ textAlign: 'start', border: '0px' }}>
                                                <p style={{ textAlign: 'justify', color: 'rgb(var(--color_36))', fontSize: '16px', border: '0px' }}>You understand that each time uses the Website in any manner, you agree to these Terms. By agreeing to these Terms, you acknowledge that you are interested in availing and purchasing the Services that you have selected and consent to receive communications via phone or electronic records from the Website including e-mail 6/14 messages telling you about products and services offered by the Website (or its affiliates and partners) and understanding your requirements. Communication can also be by posting any notices on the Website. You agree that the communications sent to You by the Website shall not be construed as spam or bulk under any law prevailing in any country where such communication is received.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div> 
    );
};

export default TermsAndConditions;
