import { createSlice } from '@reduxjs/toolkit';

const tradeSlice = createSlice({
  name: 'trade',
  initialState: {
    trades: [],
    openTrades: [],
    tradesDownload: [],
    accounts: [],
    loading: false,
    createdTrade: null,
    updatedTrade: null,
    updatedTargetStoploss: null,
    createTradeError: null,
    updateTradeError: null,
  }, 

  reducers: {
    getTrades: (trade, action) => {
      trade.createdTrade = null;
      trade.updatedTrade = null;
      trade.loading = true;
    },

    getTradesSuccess: (trade, action) => {
      trade.trades = action.payload;
      trade.loading = false;
    },

    getTradesFailed: (trade, action) => {
      trade.tradesError = action.payload;
      trade.loading = false;
    },

    getTradesHistory: (trade, action) => {
      trade.createdTrade = null;
      trade.updatedTrade = null;
      trade.loading = true;
    },

    getTradesHistorySuccess: (trade, action) => {
      trade.tradesHistory = action.payload;
      trade.loading = false;
    },

    getTradesHistoryFailed: (trade, action) => {
      trade.tradesHistory = [];
      trade.loading = false;
    },

    downloadTrades: (trade, action) => {
      trade.createdTrade = null;
      trade.updatedTrade = null;
      trade.loading = true;
    },

    downloadTradesSuccess: (trade, action) => {
      const blob = new Blob([action?.payload], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${Date.now()}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      trade.tradesDownload = action.payload;
      trade.loading = false;
    },

    downloadTradesFailed: (trade, action) => {
      trade.tradesError = action.payload;
      trade.loading = false;
    },

    getPositions: (trade, action) => {
      trade.createdTrade = null;
      trade.updatedTrade = null;
      trade.loading = true;
    },

    getPositionsSuccess: (trade, action) => {
      trade.openPositions = action.payload;
      trade.loading = false;
    },

    getPositionsFailed: (trade, action) => {
      trade.tradesError = action.payload;
      trade.loading = false;
    },

    createTrade: (trade, action) => {
      trade.updatedTrade = null;
      trade.loading = true;
    },

    createTradeSuccess: (trade, action) => {
      trade.createdTrade = action.payload;
      trade.loading = false;
    },

    createTradeFailed: (trade, action) => {
      trade.createTradeError = action.payload;
      trade.loading = false;
    },

    updateTrade: (trade, action) => {
      trade.createdTrade = null;
      trade.loading = true;
    },

    updateTradeSuccess: (trade, action) => {
      trade.updatedTrade = action.payload;
      trade.loading = false;
    },

    updateTradeFailed: (trade, action) => {
      trade.updateTradeError = action.payload;
      trade.loading = false;
    },

    updateTargetStopLoss: (trade, action) => {
      trade.updatedTrade = null;
      trade.createdTrade = null;
      trade.loading = true;
    },

    updateTargetStopLossSuccess: (trade, action) => {
      trade.updatedTargetStoploss = action.payload;
      trade.loading = false;
    },

    updateTargetStopLossFailed: (trade, action) => {
      trade.updatedTargetStoplossError = action.payload;
      trade.loading = false;
    },
  },
});

const { actions, reducer } = tradeSlice;
export const { getTrades, getTradesSuccess, getTradesFailed, createTrade, createTradeFailed, createTradeSuccess, updateTrade, updateTradeFailed, updateTradeSuccess,
  getPositions, getPositionsFailed, getPositionsSuccess, downloadTrades, downloadTradesFailed, downloadTradesSuccess,
  getTradesHistory, getTradesHistoryFailed, getTradesHistorySuccess,
  updateTargetStopLoss, updateTargetStopLossSuccess, updateTargetStopLossFailed } = actions;
export default reducer;
