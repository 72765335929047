import React, { useEffect, useState } from 'react';

import {
  Breadcrumbs,
  Divider,
  Grid,
  InputLabel,
  Link,
  Paper,
  Typography
} from '@mui/material';
import { formatPrice } from '../../../utils/string';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getPlans } from '../../../state/actions/common.actions';
import AdminTopBar from '../../global/AdminTopbar';

import RArrowImage from '../../../assets/r-arrow.png';

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const PositionDetails = () => {
  const location = useLocation();
  const { positionData = null, positionNumber = '' } = location.state ?? {};

  const [declineReasonText, setDeclineReasonText] = useState('');

  const [position, setPosition] = useState({
    positionNumber: positionNumber,
    positionId: '',
    positionname: '',
    plan: '',
    planAlias: '',
    positionType: '',
    positionAlias: '',
    positionSize: '',
    amount: '',
    dll: '',
    tll: '',
    profitTarget: '',
    tradeSquareOff: '',
    validityDays: '',
  });


  const [deleteConfirmationAnchorEl, setDeleteConfirmationAnchorEl] = React.useState(null);

  const dispatch = useDispatch();


  const localUser = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    setPosition(positionData);
  }, [location]);

  useEffect(() => {
    dispatch(getPlans({}));
  }, []);



  function getRemainingDays(account) {
    if (!account?.meta?.firstTraded) {
      return 'NA';
    }
    const currentDate = dayjs.utc().startOf('day');
    const remainingDays = dayjs.utc(account?.meta?.firstTraded).startOf('day').add(account?.validityDays, 'day').diff(currentDate, 'day');
    return remainingDays;
  }



  const open = Boolean(deleteConfirmationAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <AdminTopBar />

      <Grid sx={{
        p: 3,
        display: {
          sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
        },
        alignItems: 'center',
        justifyContent: 'space-between'
      }} item xs={12} md={12} lg={12} sm={12} mb={2}>
        <div>
          <Breadcrumbs sx={{
            pl: 1, pt: 1,
          }} separator={<img alt='img' style={{
            width: 9
          }} src={RArrowImage} />} aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" href="/admin-positions">
              <Typography sx={{
                fontSize: 17,
                fontWeight: 600
              }}>Positions</Typography>
            </Link>
            <Link underline="hover" key="1" color="inherit" href="/admin-positions">
              <Typography sx={{
                fontSize: 17,
                fontWeight: 600,
                color: '#41AAAF'
              }}>Position Details</Typography>
            </Link>
          </Breadcrumbs>
        </div>
      </Grid>

      <div style={{
        paddingLeft: 27,
        paddingRight: 27,
      }}>
        <Paper >
          <Grid container p={3}>

           
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">Email</InputLabel>
              <Typography>{positionData?.user?.email}</Typography>
            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">User Name</InputLabel>
              <Typography>{positionData?.user?.username}</Typography>
            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Account Number
              </InputLabel>
              <Typography>{positionData?.account?.accountNumber}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">Status</InputLabel>
              <Typography>{positionData?.account?.status ==  1 ? 'Active': 'Inactive'}</Typography>

            </Grid>
           
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Account Type
              </InputLabel>
              <Typography>{positionData?.account?.accountType?.label}</Typography>

            </Grid>

            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Account Size
              </InputLabel>
              <Typography>{positionData?.account?.accountSize?.label}</Typography>

            </Grid>
            
            <Grid pb={2} pr={2} item md={12} lg={12} sm={12} xs={12} >
              <Divider orientation />

            </Grid>


            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Date Created
              </InputLabel>

              <Typography>{dayjs(positionData?.account?.createdAt).format('DD MMM YYYY')}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                First Traded Date
              </InputLabel>

              <Typography>{dayjs(positionData?.account?.meta?.firstTraded).format('DD MMM YYYY')}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >

              <InputLabel shrink htmlFor="bootstrap-input">
                Days Remaining
              </InputLabel>

              <Typography>{getRemainingDays(positionData?.account)}</Typography>

            </Grid>

            <Grid pb={2} pr={2} item md={12} lg={12} sm={12} xs={12} >
              <Divider orientation />

            </Grid>

            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Current Profit
              </InputLabel>

              <Typography>{formatPrice(positionData?.profitLoss)}</Typography>

            </Grid>

            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Daily Loss Limit
              </InputLabel>

              <Typography>{formatPrice(positionData?.dailyLossLimit)}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Current Daily Loss
              </InputLabel>

              <Typography>{formatPrice(positionData?.dailyLoss)}</Typography>

            </Grid>

            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Total Loss Limit
              </InputLabel>

              <Typography>{formatPrice(positionData?.totalLossLimit)}</Typography>

            </Grid>

            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Current Total Loss
              </InputLabel>

              <Typography>{formatPrice(positionData?.totalLoss)}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                A/C Opening Balance
              </InputLabel>

              <Typography>{formatPrice(positionData?.account?.credits?.openingBalance)}</Typography>


            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Current A/C Balance
              </InputLabel>
              <Typography>{formatPrice(positionData?.account?.credits?.cashBalance)}</Typography>
            </Grid>
            
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default PositionDetails;
