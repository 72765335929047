import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Box, Link, Divider, FormControlLabel, Checkbox, Typography, TextField, Button, IconButton, InputAdornment, useTheme } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { tokens } from '../../theme';

import LightAppLogo from '../../assets/logo-light.png';
import DarkAppLogo from '../../assets/logo-dark.png';
import UC_IMAGE from '../../assets/uc.png';
import { loginUser } from '../../state/actions/auth.actions';
import { useUserContext } from '../../UserContext';
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_TOKEN } from '../../config'
import { getToken } from 'firebase/messaging';
import { messaging } from '../../messaging';


const Login = () => {

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const colors = tokens(theme.palette.mode);
  const { accountContext, userContext, updateAccountContext, updateUserContext } = useUserContext();
  const [isLoginEnabled, setIsLoginEnabled] = useState(false);
  const [isCaptchaDone, setIsCaptchaDone] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);


  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    let localUser = localStorage.getItem('user');
    let token = localStorage.getItem('token');

    if (auth?.loginResponse) {
      localStorage.setItem('token', auth?.loginResponse?.accessToken);
      localStorage.setItem('user', JSON.stringify(auth?.loginResponse?.user));
      if (['SUPERADMIN', 'ADMIN'].includes(auth?.loginResponse?.user?.role)) {
        navigate('/admin-users'), {
          replace: true
        };
      } else {
        let account = auth?.loginResponse?.user?.accounts?.find(account => account.selected);
        updateUserContext(auth?.loginResponse?.user);
        updateAccountContext(account);
        navigate(`/dashboard?userId=${auth?.loginResponse?.user?._id}&accountId=${account?._id}`, {
          replace: true
        });
      }
    }
    if (localUser && token) {
      localUser = JSON.parse(localUser);
      if (['SUPERADMIN', 'ADMIN'].includes(localUser?.role)) {
        navigate('/admin-users', {
          replace: true
        });
      } else {
        let account = localUser?.accounts?.find(account => account.selected);
        updateUserContext(localUser);
        updateAccountContext(account);
        navigate(`/dashboard?userId=${localUser?._id}&accountId=${account?._id}`, {
          replace: true
        });
      }
    }
  });

  useEffect(() => {
    if (email && isEmailValid && password && isPasswordValid && isCaptchaDone) {
      setIsLoginEnabled(true);
    } else {
      setIsLoginEnabled(false);
    }
  }, [email, password, isCaptchaDone])


  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value));
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setIsPasswordValid(value.length >= 6);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (isEmailValid && isPasswordValid) {
      dispatch(loginUser({
        email,
        password,
      }));
    }
  };

  function onCaptchaChange(captchaToken) {
    if (captchaToken) {
      setIsCaptchaDone(true);
    }
  }

  return <div style={{
    backgroundImage: `linear-gradient(0deg, rgb(0 26 28), rgb(0 0 0 / 44%)), url(${process.env.PUBLIC_URL}/login_bg.png)`,
    backgroundSize: 'cover',
    height: '100%'
  }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          width: isMobile ? '90%' : '500px',
          backgroundColor: colors.white[100],
          textAlign: 'center',
          marginTop: isMobile ? '45%' : '190px',
          marginBottom: '190px',
          border: `0.1px solid grey`,
          borderRadius: isMobile ? 0 : 3,
        }}
      >
        <Box sx={{
          paddingTop: '25px',
          paddingBottom: '25px',
          paddingLeft: '65px',
          paddingRight: '65px'
        }} component="form" noValidate >

          <img alt='img' width={200} src={theme.palette.mode === 'dark' ? LightAppLogo : DarkAppLogo} />
          <Typography sx={{
            paddingTop: '15px',
            fontSize: 26,
            fontWeight: 600,
            color: colors.black[100]
          }} >
            WEBTRADER
          </Typography>

          <TextField
            margin="normal"
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.teal,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: colors.black[100],
              },
              '& .MuiOutlinedInput-root': {
                outline: colors.teal,
              }
            }}
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
            error={!isEmailValid}
            helperText={isEmailValid ? '' : 'Invalid email format'}
          />
          <TextField
            margin="normal"
            required
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.teal,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: colors.black[100],
              },
              '& .MuiOutlinedInput-root': {
                outline: colors.teal,
              }
            }}
            variant='outlined'
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
            error={!isPasswordValid}
            helperText={isPasswordValid ? '' : 'Password must be at least 6 characters'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={handleTogglePassword}
                    aria-label="toggle password visibility"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <Grid container sx={{display:'flex' , justifyContent:'end'}}>
              <Link href="#" variant="body2" sx={{textDecoration:'none'}}>
                <Typography sx={{
                  color: '#FF4757',
                  fontWeight:'600'
                }} >                  Forgot Password ?
                </Typography>
              </Link>
            </Grid> */}

          <ReCAPTCHA
            className="g-recaptcha"
            style={{
              paddingTop: 10,
            }}
            sitekey={CAPTCHA_TOKEN}
            onChange={onCaptchaChange}
          />

          <Button
            type="submit"
            fullWidth
            disabled={!isLoginEnabled}
            onClick={handleLogin}
            size="large"
            variant="contained"
            sx={{
              fontWeight: 600,
              mt: 3, mb: 2, backgroundColor: colors.bluish, color: '#5E5E5E',
              '&:hover': {
                backgroundColor: colors.bluish,
              },
              fontFamily: 'sans-serif',
              fontSize: '15px',
              textTransform: 'capitalize'
            }}
          >
            Login
          </Button>

          <Divider orientation sx={{
            mb: '10px',
            backgroundColor: colors.black[100]
          }} />


          <Typography sx={{
            fontSize: 14,
          }}>New User?</Typography>
          <Typography style={{
            fontWeight: 500
          }}>Sign Up <a style={{
            color: colors.teal,
            textDecoration: 'none'
          }} href='https://www.upperciruit.in' target='_blank'>Here</a>
          </Typography>

          <div style={{
            marginTop: '20px',
            textAlign: 'center',
          }}>
            <Typography style={{
            }}>A venture by Hash Gametech Pvt. Ltd.
            </Typography>

            <Typography style={{
              fontWeight: 500
            }}>Charts powered by <a style={{
              color: colors.teal
            }} href='https://in.tradingview.com/' target='_blank'>TradingView</a>
            </Typography>
          </div>

          <div style={{
            marginTop: '20px',
            textAlign: 'center',
          }}>
            <Typography style={{
              fontWeight: 600,
            }}>Forgot Password?
            </Typography>
            <Typography style={{
            }}>Contact <a style={{
              color: colors.teal,
              fontWeight: 600,
              textDecoration: 'none'
            }} href='https://www.upperciruit.in/contact' target='_blank'>Support</a>
            </Typography>
          </div>

        </Box>
      </Box>
    </Box>
  </div>
};

export default Login;