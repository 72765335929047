import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const ConditionalTooltip = ({ condition, title, children, placement }) => {
  return condition ? (
    <Tooltip placement={placement} title={title} arrow>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export default ConditionalTooltip;