import { Box, Typography, useTheme, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Loading = ({ }) => {
  
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const logoutState = useSelector(
    (state) => state.auth?.logout
  );

  function useNotification() {
    return useSelector(
      (state) => state.notification
    );
  }

  useEffect(() => {
    console.log('logoutState', logoutState);
    if (logoutState) {
      navigate('/login')
    }
  }, [logoutState])


  useEffect(() => {
    let token = localStorage.getItem('token');
    let cachedUser = localStorage.getItem('user');

    if (token && cachedUser) {
      cachedUser = JSON.parse(cachedUser);
      if (cachedUser?.role == 'CUSTOMER') {
        let account = cachedUser?.accounts?.find(account => account.selected);
        navigate(`/${pathname == '/hstry' ? 'history' : pathname == '/op' ? 'orders-positions' : 'dashboard'}?userId=${cachedUser?._id}&accountId=${account?._id}`);
      }
      if (['SUPERADMIN', 'ADMIN'].includes(cachedUser?.role)) {
        navigate('/admin-users');
      }
    } else if (!cachedUser && !token) {
      navigate('/login')
    }
  }, [pathname]);

  return (
    <LinearProgress color="inherit" />
  );
};

export default Loading;
