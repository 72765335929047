export function getAccountStatus(status) {
    switch (status) {
        case 1:
            return 'Active';
        case 2:
            return 'Inactive(Failed)';
        case 3:
            return 'Inactive';
        case 4:
            return 'Inactive(Failed)';
        case 5:
            return 'Inactive(Failed)';
        case 6:
            return 'Inactive(Passed)';
        case 7:
            return 'Inactive(Failed)';
        case 8:
            return 'Inactive(Failed)';

        default:
            break;
    }
}