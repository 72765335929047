import {
  getTrades as _getTrades, getTradesFailed, getTradesSuccess,
  createTrade as _createTrade, createTradeFailed, createTradeSuccess, updateTrade as _updateTrade, updateTradeFailed, updateTradeSuccess,
  createAccount as _createAccount, getAccounts as _getAccounts, getPositions as _getPositions, getPositionsFailed, getPositionsSuccess,
  downloadTrades as _downloadTrades, downloadTradesFailed, downloadTradesSuccess,
  getTradesHistory as _getTradesHistory, getTradesHistoryFailed, getTradesHistorySuccess,
  updateTargetStopLoss as _updateTargetStopLoss, updateTargetStopLossFailed, updateTargetStopLossSuccess
} from '../reducers/trade.reducer';
import { apiCallBegan } from '../api';
import { jsonToUrlParams } from '../../utils/url';

export const getTrades = (data) => (dispatch) => {
  let url = '/trades';
  let urlParams = jsonToUrlParams(data);
  return dispatch(
    apiCallBegan({
      url: `${url}?${urlParams}`,
      data,
      method: 'GET',
      onStart: _getTrades.type,
      onSuccess: getTradesSuccess.type,
      onError: getTradesFailed.type,
    })
  );
};

export const getTradesHistory = (data) => (dispatch) => {
  let url = '/trades';
  let urlParams = jsonToUrlParams(data);
  return dispatch(
    apiCallBegan({
      url: `${url}?${urlParams}`,
      data,
      method: 'GET',
      onStart: _getTradesHistory.type,
      onSuccess: getTradesHistorySuccess.type,
      onError: getTradesHistoryFailed.type,
    })
  );
};

export const downloadTrades = (data) => (dispatch) => {
  let url = '/trades/download';
  let urlParams = jsonToUrlParams(data);
  return dispatch(
    apiCallBegan({
      url: `${url}?${urlParams}`,
      data,
      method: 'GET',
      onStart: _downloadTrades.type,
      onSuccess: downloadTradesSuccess.type,
      onError: downloadTradesFailed.type,
    })
  );
};


export const getPositions = (data) => (dispatch) => {
  let url = '/trades';
  let urlParams = jsonToUrlParams(data);
  return dispatch(
    apiCallBegan({
      url: `${url}?${urlParams}`,
      data,
      method: 'GET',
      onStart: _getPositions.type,
      onSuccess: getPositionsSuccess.type,
      onError: getPositionsFailed.type,
    })
  );
};

export const createTrade = (data) => (dispatch) => {
  let notificationMessage = null;
  if (data?.orderType == 1) {
    notificationMessage = 'Order Executed';
  } else {
    notificationMessage = 'Order Placed';
  }
  let url = '/trades';
  return dispatch(
    apiCallBegan({
      url: `${url}`,
      data,
      method: 'POST',
      onStart: _createTrade.type,
      onSuccess: createTradeSuccess.type,
      onError: createTradeFailed.type,
      notificationMessage

    })
  );
};

export const updateTrade = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `trades/${data._id}`,
      data,
      method: 'PUT',
      onStart: _updateTrade.type,
      onSuccess: updateTradeSuccess.type,
      onError: updateTradeFailed.type,
      notificationMessage: 'Trade Updated'

    })
  );
};


export const updateTargetStopLoss = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `trades/target-stoploss/${data._id}`,
      data,
      method: 'PUT',
      onStart: _updateTargetStopLoss.type,
      onSuccess: updateTargetStopLossSuccess.type,
      onError: updateTargetStopLossFailed.type,
      notificationMessage: 'Stoploss updated'

    })
  );
};

