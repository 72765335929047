import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
    useTheme,
    Box,
    CircularProgress,
    Input,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { sendNotification } from '../../../../state/reducers/notification.reducer'
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from '../../../../components/Transition';

import { S3_BASE_URL, REST_URL } from '../../../../config';
import { downloadFile } from '../../../../services/fn';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import React from 'react';
import { tokens } from '../../../../theme';
import { sendNotifications } from '../../../../state/actions/admin.actions';

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const UsersNotificationDialog = ({
    userNotificationDialog,
    handleUserNotificationDialogClose,
}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [title, setTitle] = useState(undefined);
    const [body, setBody] = useState(undefined);
    const [isTitleValid, setTitleValid] = useState(true);
    const [isBodyValid, setBodyValid] = useState(true);

    const notificationsState = useSelector((state) => state.admin?.notifications);


    useEffect(() => {
        if (notificationsState) {
            setTitle(undefined);
            setBody(undefined);
            handleUserNotificationDialogClose();
        }
    }, [notificationsState]);


    const handleTitleChange = (e) => {
        const value = e.target.value;
        setTitle(value);
        if (value?.length > 0) {
            setTitleValid(true);
        } else {
            setTitleValid(false);
        }
    };

    const handleBodyChange = (e) => {
        const value = e.target.value;
        setBody(value);
        if (value?.length > 0) {
            setBodyValid(true);
        } else {
            setBodyValid(false);
        }
    };



    async function handleSendNotification() {
        dispatch(sendNotifications({
            title, body
        }));
    }

    return (
        <Dialog
            TransitionComponent={Transition}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={userNotificationDialog}
            onClose={handleUserNotificationDialogClose}
        >
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{
                        fontWeight: 700,
                        fontSize: 16
                    }}>{'Send Notification to Users'}</span>
                    <IconButton onClick={handleUserNotificationDialogClose} edge="end" color="inherit" aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <Grid>
                <Divider />
            </Grid>
            <DialogContent>
                <Grid container>
                    <Grid item md={12} xs={12} >
                        <TextField
                            margin="normal"
                            sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: colors.teal,
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: colors.black[100],
                                },
                                '& .MuiOutlinedInput-root': {
                                    outline: colors.teal,
                                }
                            }}
                            required
                            fullWidth
                            id="title"
                            label="Title"
                            name="title"
                            autoFocus
                            value={title}
                            onChange={handleTitleChange}
                            error={!isTitleValid}
                            helperText={isTitleValid ? '' : 'Invalid Title'}
                        />

                        <TextField
                            margin="normal"
                            sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: colors.teal,
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: colors.black[100],
                                },
                                '& .MuiOutlinedInput-root': {
                                    outline: colors.teal,
                                }
                            }}
                            required
                            fullWidth
                            id="body"
                            label="Body"
                            name="body"
                            value={body}
                            onChange={handleBodyChange}
                            error={!isBodyValid}
                            helperText={isBodyValid ? '' : 'Invalid Body'}
                        />

                        <Button
                            sx={{
                                height: 40,
                                marginTop: 2,
                                // marginLeft: 2,
                                backgroundColor: '#41AAAF',
                                '&:hover': {
                                    backgroundColor: '#41AAAF',
                                },
                            }}
                            disabled={!isBodyValid || !isTitleValid}
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            fullWidth
                            onClick={handleSendNotification}
                            variant="contained">
                            Send
                        </Button>

                    </Grid>
                
                </Grid>


            </DialogContent>
        </Dialog>
    );
};

export default UsersNotificationDialog;