import {
  getPlans as _getPlans, getPlansFailed, getPlansSuccess, setNavMenuItem as _setNavMenuItem,
  getInstrumentSnapshots as _getInstrumentSnapshots, getInstrumentSnapshotsFailed, getInstrumentSnapshotsSuccess,
} from '../reducers/common.reducer';
import { apiCallBegan } from '../api';
import { jsonToUrlParams } from '../../utils/url';

export const getPlans = (data) => (dispatch) => {
  let url = '/plans';
  let urlParams = jsonToUrlParams(data);
  return dispatch(
    apiCallBegan({
      url: `${url}?${urlParams}`,
      data,
      method: 'GET',
      onStart: _getPlans.type,
      onSuccess: getPlansSuccess.type,
      onError: getPlansFailed.type,
    })
  );
};


export const getInstrumentSnapshots = (data) => (dispatch) => {
  let url = '/symbols/snapshots';
  return dispatch(
    apiCallBegan({
      url: `${url}`,
      data,
      method: 'GET',
      onStart: _getInstrumentSnapshots.type,
      onSuccess: getInstrumentSnapshotsSuccess.type,
      onError: getInstrumentSnapshotsFailed.type,
    })
  );
};

export const setNavMenuItem = (data) => (dispatch) => {
  return dispatch({ type: _setNavMenuItem.type, payload: { navMenuItem: data } });
};


