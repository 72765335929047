import React, { useEffect, useState } from 'react';

import { Autocomplete, Box, Breadcrumbs, Button, Divider, Grid, InputLabel, Link, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import RArrowImage from '../../../assets/r-arrow.png';
import { createAccount, getAccounts, getUsers, updateAccount } from '../../../state/actions/admin.actions';
import { getPlans } from '../../../state/actions/common.actions';
import AdminTopBar from '../../global/AdminTopbar';

import _ from 'lodash';

const AccountForm = () => {
  const location = useLocation();
  const { accountData = null, editing = true, accountNumber = '' } = location.state ?? {};


  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState({});
  const [users, setUsers] = useState([]);

  const [account, setAccount] = useState({
    accountNumber: accountNumber,
    userId: '',
    username: '',
    plan: '',
    planAlias: '',
    accountType: '',
    accountAlias: '',
    accountSize: '',
    amount: '',
    dll: '',
    tll: '',
    profitTarget: '',
    tradeSquareOff: '',
    validityDays: '',
  });

  const [accountTypes, setAccountTypes] = useState([]);
  const [accountSizes, setAccountSizes] = useState([]);

  const [searchText, setSearchText] = useState('');

  const [amountDisabled, setAmountDisabled] = useState(true);
  const [isAccountNumberValid, setIsAccountNumberValid] = useState(true);
  const [isPlanAliasValid, setIsPlanAliasValid] = useState(true);
  const [isAccountAliasValid, setIsAccountAliasValid] = useState(true);
  const [isAmountValid, setIsAmountValid] = useState(true);
  const [isDllValid, setIsDllValid] = useState(true);
  const [isTllValid, setIsTllValid] = useState(true);
  const [isProfitTargetValid, setIsProfitTargetValid] = useState(true);
  const [isValidityDaysValid, setIsValidityDaysValid] = useState(true);
  const [saveUpdateEnabled, setSaveUpdateEnabled] = useState(true);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const createdAccountState = useSelector((state) => state.admin?.createdAccount);
  const updatedAccountState = useSelector((state) => state.admin?.updatedAccount);
  const usersState = useSelector((state) => state.admin?.users);
  const plansState = useSelector((state) => state.common?.plans);



  useEffect(() => {
    let _plan = plans?.plans?.find(plan => plan._id === accountData?.plan);
    setPlan(_plan);
    setAccountTypes(_plan?.types);
    let _type = _plan?.types?.find(type => type.value === accountData?.accountType.value);
    setAccountSizes(_type?.amounts);

    if (editing && accountData?.accountSize?.value === 'Custom') {
      setAmountDisabled(false);
    }
    setAccount({ ...account, ...accountData });
  }, [location, plans]);


  useEffect(() => {

    if (
      account?.accountNumber?.length > 5 &&
      account?.accountSize?.label?.length > 0 &&
      account?.accountType?.label?.length > 0 &&
      account?.accountAlias?.length > 1 &&
      account?.planAlias?.length > 1 &&
      (account?.userId?.length > 0 || account?.username?.length > 0 || account?.user) &&
      account?.plan?.length > 1 &&
      account?.amount > 0 &&
      account?.dll > 0 &&
      account?.tll > 0 &&
      account?.profitTarget > 0 &&
      account?.tradeSquareOff?.length > 0 &&
      account?.validityDays > 0) {

      setSaveUpdateEnabled(false);
    } else {
      setSaveUpdateEnabled(true);
    }
  }, [account])

  useEffect(() => {
    if (createdAccountState?.user) {
      navigate('/admin-accounts');
    }
  }, [createdAccountState])

  useEffect(() => {
    if (updatedAccountState?.user) {
      navigate('/admin-accounts');
    }
  }, [updatedAccountState])

  useEffect(() => {
    if (usersState?.users?.length) {
      setUsers(usersState);
    }
  }, [usersState])

  useEffect(() => {
    if (plansState?.plans?.length) {
      setPlans(plansState);
    }
  }, [plansState])

  const _getUsers = () => {
    if (searchText?.length >= 1) {
      dispatch(getUsers({
        page: 1, limit: 100, q: searchText, roles: 'CUSTOMER'
      }));
    } else {
      dispatch(getUsers({
        page: 1, limit: 100, roles: 'CUSTOMER'
      }));
    }
  };

  useEffect(() => {
    dispatch(getPlans({}));
    _getUsers();
  }, []);

  useEffect(() => {
    _getUsers();
  }, [searchText]);

  const handleAccountAliashange = (e) => {
    const value = e.target.value;
    setAccount({ ...account, accountAlias: value });
    setIsAccountAliasValid(value.length >= 1);
  };

  const handleAccountNumberChange = (e) => {
    const value = e.target.value;
    setAccount({ ...account, accountNumber: value });
    setIsAccountNumberValid(value.length >= 1);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAccount({ ...account, amount: value });
    setIsAmountValid(value.length >= 1);
  };

  const handleDLLChange = (e) => {
    const value = e.target.value;
    setAccount({ ...account, dll: value });
    setIsDllValid(value.length >= 1);
  };

  const handlePlanAliasChange = (e) => {
    const value = e.target.value;
    setAccount({ ...account, planAlias: value });
    setIsPlanAliasValid(value.length >= 1);
  };


  const handlePlanChange = (e) => {
    const value = e.target.value;
    setAccount({
      ...account, plan: value,
      amount: '',
      dll: '',
      tll: '',
      profitTarget: '',
      validityDays: '',
      accountType: '',
    });
    let _plan = plans?.plans?.find(plan => plan._id === value);
    setPlan(_plan);
    setAccountTypes(_plan?.types);
    setAccountSizes([]);
  };

  const handleAccountTypeChange = (e) => {
    const value = e.target.value;
    let _accountType = plan?.types?.find(type => type?.value === value);
    setAccountSizes(_accountType?.amounts);
    setAccount({
      ...account,
      amount: '',
      tradeSquareOff: _accountType?.tradeSquareOff,
      dll: _accountType?.dllPercent,
      tll: _accountType?.tllPercent,
      profitTarget: _accountType?.profitPercent,
      validityDays: _accountType?.validityDays,
      accountType: {
        value: _accountType?.value,
        label: _accountType?.label,
      }
    });
  };

  const handleAccountSizeChange = (e) => {
    const value = e.target.value;
    let _accountSize = accountSizes?.find(accountSize => accountSize?.value === value);
    if (value === 'Custom') {
      setAmountDisabled(false);
    } else {
      setAmountDisabled(true);
    }
    setAccount({
      ...account, accountSize: {
        value: _accountSize?.value,
        label: _accountSize?.label,
      }, amount: _accountSize?.value
    });
  };



  const handleProfitTargetChange = (e) => {
    const value = e.target.value;
    setAccount({ ...account, profitTarget: value });
    setIsProfitTargetValid(value.length >= 1);
  };


  const handleTradeSquareOffChange = (e) => {
    const value = e.target.value;
    setAccount({ ...account, tradeSquareOff: value });
  };


  const handleTllChange = (e) => {
    const value = e.target.value;
    setAccount({ ...account, tll: value });
    setIsTllValid(value.length >= 1);
  };


  const handleValidityDaysChange = (e) => {
    const value = e.target.value;
    setAccount({ ...account, validityDays: value });
    setIsValidityDaysValid(value.length >= 1);
  };


  const handleAccountSave = () => {
    if (editing) {
      if (account?.accountSize?.value === 'Custom') {
        dispatch(updateAccount({
          ...(_.pick(account, [
            'accountNumber',
            'userId',
            'username',
            'plan',
            'planAlias',
            'accountType',
            'accountAlias',
            'accountSize',
            'amount',
            'status',
            'dll',
            'tll',
            'profitTarget',
            'tradeSquareOff',
            'validityDays',
            'declineReason',
            'active',
            '_id'
          ])),

        }));
      } else {
        dispatch(updateAccount(_.pick(account, [
          'accountNumber',
          'userId',
          'username',
          'plan',
          'planAlias',
          'status',
          'accountType',
          'accountAlias',
          'accountSize',
          'amount',
          'dll',
          'tll',
          'profitTarget',
          'declineReason',
          'tradeSquareOff',
          'validityDays',
          'active',
          '_id'
        ])));
      }
    } else {
      dispatch(createAccount({ accountNumber, ...account }));
    }
  };

  const handleAccountCancel = () => {
    navigate('/admin-accounts');
  };

  return (
    <>
      <AdminTopBar />


      <Grid sx={{
        p: 3,
        display: {
          sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
        },
        alignItems: 'center',
        justifyContent: 'space-between'
      }} item xs={12} md={12} lg={12} sm={12} mb={2}>
        <div>
          <Breadcrumbs sx={{
            pl: 1, pt: 1,
          }} separator={<img alt='img' style={{
            width: 9
          }} src={RArrowImage} />} aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" href="/admin-accounts">
              <Typography sx={{
                fontSize: 17,
                fontWeight: 600
              }}>Accounts</Typography>
            </Link>
            <Link underline="hover" key="1" color="inherit" href="/admin-accounts">
              <Typography sx={{
                fontSize: 17,
                fontWeight: 600,
                color: '#41AAAF'
              }}>{editing ? 'Edit ' : 'Add'} Account</Typography>
            </Link>
          </Breadcrumbs>
        </div>
        <Box sx={{
          display: {
            sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
          },
          alignItems: 'center'
        }}>

        </Box>
      </Grid>

      <div style={{
        paddingLeft: 27,
        paddingRight: 27,
      }}>
        <Paper >
          <Grid container p={3}>

            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Account Number
              </InputLabel>
              <TextField
                sx={{
                  marginRight: 4
                }}
                autoFocus
                margin="dense"
                id="name"
                value={account?.accountNumber}
                onChange={handleAccountNumberChange}
                type="number"
                fullWidth
                variant="outlined"
                error={!isAccountNumberValid}
                helperText={isAccountNumberValid ? '' : 'Invalid Account Number'}
              />
            </Grid>
            <Grid pt={1} pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">User Name</InputLabel>
              {account ?
                users?.users?.length && <Autocomplete
                  value={account?.user}
                  disabled={editing}
                  options={users?.users}
                  onChange={(event, newValue) => {
                    setAccount({ ...account, userId: newValue?._id });
                  }}
                  getOptionLabel={(option) => option.email}
                  getOptionSelected={(option, value) => option._id === value._id}
                  onInputChange={(event, value) => setSearchText(value)}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
                :
                users?.users?.length && <Autocomplete
                  options={users?.users}
                  onChange={(event, newValue) => {
                    setAccount({ ...account, userId: newValue?._id });
                  }}
                  getOptionLabel={(option) => option.email}
                  getOptionSelected={(option, value) => option._id === value._id}
                  onInputChange={(event, value) => setSearchText(value)}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />}

            </Grid>
            <Grid pt={1} pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">Plan</InputLabel>
              <Select
                id="demo-simple-select"
                fullWidth
                value={account?.plan}
                onChange={handlePlanChange}
              >{plans?.plans?.map((plan) => {
                return (
                  < MenuItem key={plan?._id} value={plan?._id
                  }> {plan?.name}</MenuItem>

                );
              })}
              </Select>
            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Plan Display Name
              </InputLabel>
              <TextField
                value={account?.planAlias}
                margin="dense"
                id="name"
                onChange={handlePlanAliasChange}
                placeholder="Plan Display Name"
                type="email"
                fullWidth
                variant="outlined"
                error={!isPlanAliasValid}
                helperText={isPlanAliasValid ? '' : 'Invalid Plan Display Name'}
              />
            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Account Type
              </InputLabel>
              {editing && accountTypes && <Select
                id="demo-simple-select"
                fullWidth
                value={account?.accountType?.value}
                onChange={handleAccountTypeChange}
              >{accountTypes?.map((accountType) => {
                return (
                  < MenuItem key={accountType?.value} value={accountType?.value
                  }> {accountType?.label}</MenuItem>
                );
              })}
              </Select>}
              {!editing && <Select
                id="demo-simple-select"
                fullWidth
                value={account?.accountType?.value}
                onChange={handleAccountTypeChange}
              >{accountTypes?.map((accountType) => {
                return (
                  < MenuItem key={accountType?.value} value={accountType?.value
                  }> {accountType?.label}</MenuItem>
                );
              })}
              </Select>}

            </Grid>
            <Grid pb={2} pr={2} item md={12} lg={12} sm={12} xs={12} >
              <Divider orientation />

            </Grid>
            <Grid pb={2} pr={2} item md={12} lg={12} sm={12} xs={12} >

              <Typography>Configuration</Typography>
            </Grid>


            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Display Name
              </InputLabel>
              <TextField
                sx={{
                  marginRight: 4
                }}
                margin="dense"
                id="name"
                value={account?.accountAlias}
                onChange={handleAccountAliashange}
                placeholder="Display Name"
                type="text"
                fullWidth
                variant="outlined"
                error={!isAccountAliasValid}
                helperText={isAccountAliasValid ? '' : 'Invalid Display Name'}
              />
            </Grid>
            <Grid pt={1} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Account Size
              </InputLabel>

              {editing && accountSizes && <Select
                id="demo-simple-select"
                fullWidth
                value={account?.accountSize?.value}
                onChange={handleAccountSizeChange}
              >{accountSizes?.map((accountSize) => {
                return (
                  < MenuItem key={accountSize?.value} value={accountSize?.value
                  }> {accountSize?.label}</MenuItem>

                );
              })}
              </Select>}
              {!editing && <Select
                id="demo-simple-select"
                fullWidth
                value={account?.accountSize?.value}
                onChange={handleAccountSizeChange}
              >{accountSizes?.map((accountSize) => {
                return (
                  <MenuItem key={accountSize?.value} value={accountSize?.value
                  }> {accountSize?.label}</MenuItem>

                );
              })}
              </Select>}
            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >

              <InputLabel shrink htmlFor="bootstrap-input">
                Amount
              </InputLabel>
              <TextField
                sx={{
                  marginRight: 4
                }}
                disabled={amountDisabled}
                value={account?.amount}
                onChange={handleAmountChange}
                margin="dense"
                id="name"
                placeholder="Amount"
                type="number"
                fullWidth
                variant="outlined"
                error={!isAmountValid}
                helperText={isAmountValid ? '' : 'Invalid Amount'}
              />
            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Daily Loss Limit
              </InputLabel>
              <TextField
                value={account?.dll}
                margin="dense"
                id="name"
                onChange={handleDLLChange}
                placeholder="Daily Loss Limit"
                type="number"
                fullWidth
                variant="outlined"
                error={!isDllValid}
                helperText={isDllValid ? '' : 'Invalid Daily Loss Limit'}
              />
            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Total Loss Limit
              </InputLabel>
              <TextField
                onChange={handleTllChange}
                margin="dense"
                id="name"
                value={account?.tll}
                placeholder="Total Loss Limit"
                type="number"
                fullWidth
                variant="outlined"
                error={!isTllValid}
                helperText={isTllValid ? '' : 'Invalid Total Loss Limit'}
              />

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Profile Target
              </InputLabel>
              <TextField
                onChange={handleProfitTargetChange}
                margin="dense"
                id="name"
                value={account?.profitTarget}
                placeholder="Profile Target"
                type="number"
                fullWidth
                variant="outlined"
                error={!isProfitTargetValid}
                helperText={isProfitTargetValid ? '' : 'Invalid Profile Target'}
              />

            </Grid>
            <Grid pt={1} pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Square Trade-off
              </InputLabel>

              <Select
                fullWidth
                value={account?.tradeSquareOff}
                onChange={handleTradeSquareOffChange}
              >
                < MenuItem value={'Daily'}>Daily</MenuItem>
                < MenuItem value={'Weekly'}>Weekly</MenuItem>
              </Select>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Validity Days
              </InputLabel>
              <TextField
                onChange={handleValidityDaysChange}
                margin="dense"
                id="name"
                value={account?.validityDays}
                placeholder="Validity Days"
                type="text"
                fullWidth
                variant="outlined"
                error={!isValidityDaysValid}
                helperText={isValidityDaysValid ? '' : 'Invalid Validity Days'}
              />

            </Grid>

            <Grid item md={9} lg={9} sm={12} xs={12} >


            </Grid>
            <Grid sx={{
              display: 'flex'
            }} pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <Button
                fullWidth
                onClick={handleAccountCancel}
                sx={{
                  background: '#FF4757',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#FF4757',
                    color: '#ffffff'
                  },
                }} type='contained' >Cancel</Button>
              <Button
                fullWidth
                disabled={saveUpdateEnabled}
                onClick={handleAccountSave}
                sx={{
                  background: '#41AAAF',
                  color: '#ffffff',
                  marginLeft: 5,
                  '&:hover': {
                    backgroundColor: '#41AAAF',
                    color: '#ffffff'

                  },
                  '&:disabled': {
                    backgroundColor: 'gray',
                    color: '#ffffff'
                  },
                }} type='contained' >Submit</Button>

            </Grid>


          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default AccountForm;
