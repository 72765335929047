import { initializeApp } from "firebase/app";

import { getMessaging, onMessage } from "firebase/messaging";
const firebaseConfig = {
    apiKey: "AIzaSyAXLJi6-HZkoxCTiEmbmP34D8VikLduaD4",
    authDomain: "uctrader.firebaseapp.com",
    projectId: "uctrader",
    storageBucket: "uctrader.appspot.com",
    messagingSenderId: "619280984787",
    appId: "1:619280984787:web:46e2679b9f67a17b46c226"
};
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);


