import {
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  Paper,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  useTheme,
  TablePagination
} from '@mui/material';
import { tokens } from '../../theme';

import { Search as SearchIcon } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Grid
} from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getTrades, downloadTrades } from '../../state/actions/trade.actions';
import { getUser, logoutUser } from '../../state/actions/auth.actions';
import { getPayouts } from '../../state/actions/user.actions';
import { formatPrice } from '../../utils/string';
import AccountHistoryDialog from './Elements/AccountHistoryDialog';

import CustomerTopbar from '../global/CustomerTopbar';
import InstrumentList from '../instrument/InstrumentList';
const dayjs = require('dayjs');

const Payout = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const payoutsSelector = useSelector((state) => state.user.payouts);
  const [instruments, setInstruments] = useState([]);


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [payout, setPayout] = useState(null);
  const [accountHistoryDialog, setAccountHistoryDialog] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [user, setUser] = useState(auth?.user?.user);
  const [payouts, setPayouts] = useState([]);
  const [searchText, setSearchText] = useState('');
  

  useEffect(() => {
    if (userId) {
      dispatch(getUser({ userId }));
      dispatch(getPayouts({ user: userId, expand: 'account' }));
    }
  }, [userId]);


  useEffect(() => {
    if (payoutsSelector?.payouts?.length) {
      setPayouts(payoutsSelector?.payouts);
    } else {
      setPayouts([]);
    }
  }, [payoutsSelector]);


  useEffect(() => {
    if (auth?.user?.user) {
      setUser(auth?.user?.user);
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.user?.user) {
      setUser(auth?.user?.user);
      setSelectedAccount(auth?.user?.user?.accounts?.find((account) => account?.selected));
    }
  }, []);

 

  const onChangeTopBar = (event) => {
    setSelectedAccount(event?.account);
  };

 


  
  function getPayoutStatusColor(status) {
    switch (status) {
    case 1:
      return '#FFC107';
    case 2:
      return colors.green;
    case 3:
      return '#FF4757';
    default:
      return '#000000';
    }
  }

  function getTradeStatus(status) {
    switch (status) {
    case 1:
      return 'Completed';
    case 2:
      return 'Open';
    case 3:
      return 'Completed';
    case 4:
      return 'Cancelled';
    case 5:
      return 'Rejected';
    case 6:
      return 'Partially Completed';
    default:
      return 'NA';
    }
  }

  function getPayoutStatus(status, prefix = '', suffix = '') {
    switch (status) {
    case 1:
      return `${prefix}Requested${suffix}`;
    case 2:
      return `${prefix}Processed${suffix}`;
    case 3:
      return `${prefix}Rejected${suffix}`;
    default:
      return '';
    }
  }

  function handlePayoutClick(event, _payout) {
    setPayout(_payout);
    setAccountHistoryDialog(true);
  }

  const handleAccountHistoryDialogClose = (event) => {
    event.stopPropagation();
    setAccountHistoryDialog(false);
    setPayout(null);
  };

  return (
    <>
      {auth?.user?.user && <CustomerTopbar onChangeTopBar={onChangeTopBar} user={auth?.user?.user} />}
      <Grid
        style={{ backgroundColor: colors.home[100], minHeight: '100vh' }}
        columnSpacing={1}
        p={0}
        container
      >
        <Grid
          style={{
            paddingLeft: 28,
          }}
          item
          md={12}
          sm={6}
        >
          <Box sx={{
            marginTop: 10,
            width: '100%',  typography: 'body1'
          }}>
            <Box sx={{ paddingTop: 3, paddingBottom: 2,  borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Account History</Typography> 
            </Box>

            <div style={{
              marginRight: 30,
            }} >
              <Grid columnSpacing={2} rowSpacing={2} container>
                {!payouts?.length ? <Grid item md={12} xs={12} sx={{
                  textAlign: 'center',
                  marginTop: '20px',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Typography style={{
                    
                  }}>No Account History</Typography>
                </Grid> :
                <>{payouts.map((_payout) => {
                  return (
                    <Grid style={{ cursor: 'pointer' }} onClick={(event) => handlePayoutClick(event,_payout)} key={_payout?._id} xs={12} md={6} item >
                      <Paper style={{ padding: 15, backgroundColor: colors.dashboardCards.background, boxShadow: '0px 1px 5px 0px #00000029' }}>
                        <div style={{
                          display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                          paddingRight: 10, paddingLeft: 10
                        }}>
                          <Grid container spacing={2}>
                            <Grid item md={4} xs={12}>
                              <Typography sx={{
                                fontSize: 12,
                                paddingBottom: 1,
                              }}>Account Number</Typography>
                              <Typography sx={{
                                fontSize: 13,
                                fontWeight: 600
                              }}>{_payout?.account?.accountNumber}</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography sx={{
                                paddingBottom: 1,
                              }}>Payout Amount</Typography>
                              <Typography sx={{
                                fontSize: 13,
                                fontWeight: 600
                              }}>{formatPrice(_payout?.account?.credit?.cashBalance)}</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography sx={{
                                fontSize: 12,
                                paddingBottom: 1,
                              }}>Status</Typography>
                              <Typography sx={{
                                fontSize: 13,
                                fontWeight: 600,
                                color: getPayoutStatusColor(_payout?.status)
                              }}>{getPayoutStatus(_payout?.status)}</Typography>
                            </Grid>
                         </Grid>
                        </div>
                        <AccountHistoryDialog
                          accountHistoryDialog={accountHistoryDialog}
                          payout={payout}
                          handleAccountHistoryDialogClose={handleAccountHistoryDialogClose}
                        />
                      </Paper>
                    </Grid>
                  );
                })}</>}
              </Grid>
            </div>

          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Payout;
