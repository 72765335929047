import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tokens } from '../../../theme';

import { useTheme, Autocomplete, Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { getUsers } from '../../../state/actions/admin.actions';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import dayjs from 'dayjs';
import AdminTopBar from '../../global/AdminTopbar';
import Payouts from './Payouts';
import Upgrades from './Upgrades';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Requests = () => {

  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);
  const [tabValue, setTabValue] = React.useState('PAYOUT');
  

  const dispatch = useDispatch();
  const usersState = useSelector((state) => state.admin?.users);

  useEffect(() => {
    dispatch(getUsers({
      page: 1, limit: 100, roles: 'CUSTOMER'
    }));
  }, []);

  useEffect(() => {
    if (usersState?.users?.length) {
      setUsers(usersState);
    } else {
      setUsers([]);
    }
  }, [usersState]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function handleAccountChange(event) {
    setAccount(event?.target?.value);
  }

  return (
    <>
      <AdminTopBar />
      <Grid
        sx={{
          pl: 2,
          pr: 2,
        }}
        item
        pt={2}
        xs={12}
        sm={12}
        md={8.5}
        lg={8.5}
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
              <TabList sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: colors.teal,
                },
              }} onChange={handleTabChange}>
                <Tab sx={{
                  '&.Mui-selected': {
                    color: colors.teal,
                  },
                }} label="Payouts" value="PAYOUT" />
                <Tab sx={{
                  '&.Mui-selected': {
                    color: colors.teal,
                  },
                }} label="Upgrade" value="UPGRADE" />
              </TabList>
              <div style={{
                paddingRight: 30,
                paddingBottom: 5
              }}>
                <Box sx={{
                  display: {
                    sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
                  },
                  alignItems: 'center'
                }}>
                  <Autocomplete
                    sx={{
                      width: 300,
                      mr: 3
                    }}
                    options={users?.users}
                    onChange={(event, newValue) => {
                      if (!newValue) {
                        setUser(newValue);
                        setAccount(newValue);
                      } else {
                        setUser(newValue);
                      }
                    }}
                    getOptionLabel={(option) => option.email}
                    getOptionSelected={(option, value) => option._id === value._id}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="User" />
                    )}
                  />

                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Account</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label='Account'
                      sx={{
                        width: 200,
                      }}
                      onChange={handleAccountChange}
                    >{!user?.accounts?.length ? (
                        <MenuItem disabled>No User Selected</MenuItem>
                      ) : user?.accounts?.map((account) => {
                        return (
                          < MenuItem key={account?._id} value={account
                          }> {account?.accountNumber}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </Box>
            <TabPanel value="PAYOUT">
              <Payouts accountId={account?._id} userId={user?._id} />
            </TabPanel>
            <TabPanel value="UPGRADE">
              <Upgrades accountId={account?._id} userId={user?._id} />
            </TabPanel>
          </TabContext>

          

        </Box>
      </Grid>
    </>
  );
};

export default Requests;
