import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  AppBar, Avatar, Box, Container, FormControl, Grid,
  Hidden, IconButton, Menu, Skeleton,
  MenuItem, Select, Stack, Toolbar, Typography, useTheme,
  Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText,
  Button
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import React, { useContext, useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import DarkAppLogo from '../../assets/logo-dark.png';
import LightAppLogo from '../../assets/logo-light.png';
import { logoutUser, getUser } from '../../state/actions/auth.actions';
import { updateAccount } from '../../state/actions/user.actions';
import { ColorModeContext, tokens } from '../../theme';
import { useSelector } from 'react-redux';
import { ACCOUNT_STATUS, LIQUIDATION_REASON } from '../../config';
import { useUserContext } from '../../UserContext';
import { setNavMenuItem } from '../../state/actions/common.actions';
import { getAccountStatus } from '../../utils/constants';


const pages = [
  {
    name: 'Dashboard',
    navigateTo: 'dashboard',
  },
  {
    name: 'Orders & Positions',
    navigateTo: 'orders-positions',
  },
  {
    name: 'History',
    navigateTo: 'history',
  },
];

const CustomerTopbar = (props) => {
  const { accountContext, userContext, updateAccountContext, updateUserContext } = useUserContext();

  const navMenuItemState = useSelector((state) => state.common?.navMenuItem);

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [userAccounts, setUserAccounts] = useState(null);
  const [accountFailedTitle, setAccountFailedTitle] = useState(undefined);
  const [accountFailedContent, setAccountFailedContent] = useState(undefined);
  const [user, setUser] = useState(null);
  const location = useLocation();
  const [accountCloseDialog, setAccountCloseDialog] = useState(false);

  const [isThemeChecked, setThemeChecked] = useState(
    localStorage.getItem('theme') == 'dark' ? true : false
  );
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPage, setSelectedPage] = useState(pages?.[0]?.name);
  const dispatch = useDispatch();


  function getActiveMenu(pathname) {
    switch (pathname) {
      case '/orders-positions':
        return 'Orders & Positions';
      case '/dashboard':
        return 'Dashboard';
      case '/history':
        return 'History';
      default:
        return '';
    }
  }

  useEffect(() => {
    setSelectedPage(
      getActiveMenu(location?.pathname)
    )
  }, [location?.pathname]);


  useEffect(() => {
    if (accountContext) {
      setSelectedAccount(accountContext);
    }
  }, [accountContext]);

  useEffect(() => {
    if (userContext) {
      setUser(userContext);
      let accounts = JSON.parse(JSON.stringify(userContext?.accounts));
      setUserAccounts(accounts?.sort((a, b) => {
        if (a.status !== b.status) {
          return a.status - b.status;
        }
        return new Date(b?.createdAt) - new Date(a?.createdAt)
      }));
    }

  }, [userContext]);

  const _theme = useTheme();
  const navigate = useNavigate();

  const colors = tokens(_theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const handlePageClick = (event, NAVIGATE_TO, PAGE_NAME) => {
    event.stopPropagation();
    setSelectedPage(PAGE_NAME);
    setAnchorElNav(null);
    navigate(`/${NAVIGATE_TO}?userId=${user?._id}&accountId=${selectedAccount?._id}`, {
      state: {
        page: PAGE_NAME
      }
    });
  };

  const handleThemeChange = (event) => {
    event.stopPropagation();
    setThemeChecked(!isThemeChecked);
    colorMode.toggleColorMode();
  };




  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    updateAccountContext(null);
    updateUserContext(null);
    dispatch(logoutUser());
    dispatch(setNavMenuItem('Users'));
    navigate('/login', {
      replace: true,
    });
  };


  const handleAccountChange = (event) => {
    const selected = user?.accounts?.find((account) => account?.accountNumber == event.target.value);
    if (selected?.status == ACCOUNT_STATUS.RESET) {
      setAccountCloseDialog(true);
    } else if (selected?.status == ACCOUNT_STATUS.LIQUIDATED) {
      setAccountCloseDialog(true);
    } else {
      setAccountCloseDialog(false);
    }
    updateAccountContext(selected);
    dispatch(getUser({ userId: user?._id }));
    dispatch(updateAccount({
      _id: selected?._id,
      selected: true
    }));
  };

  const renderValue = (selected) => {
    const selectedOption = user?.accounts.find((account) => account?.accountNumber === selected);

    return (
      <div style={{
      }}>
        <div style={{
          fontSize: '17px',
          color: colors.black[100],
        }}>
          {selectedOption?.accountNumber}
        </div>
        <div style={{
          fontSize: '12px',
          color: colors.tealBlack,
        }}>
          {selectedOption?.accountAlias}
        </div>
      </div>
    );
  };

  function getUserName(data) {
    return `${data?.firstName}${' '}${data?.lastName}`
  }

  function getAvatarNameChar(data) {
    return `${data?.firstName?.[0]}${data?.lastName?.[0]}`.toUpperCase();
  }

  function homeIconPress() {
    setSelectedPage('Users');
    setSelectedPage('Dashboard');
    setAnchorElNav(null);
    navigate(`/dashboard?userId=${user?._id}&accountId=${selectedAccount?._id}`, {
      state: {
        page: 'Dashboard'
      }
    });
  }

  const handleAccountDialogClose = () => {
    setAccountCloseDialog(false);
  };

  return (
    <AppBar
      style={{
        background: colors.topbar[100],
        boxShadow: colors.appBar.boxShadow,
      }}
      position="fixed"
    >
      <Container maxWidth="xl">
        <Hidden mdUp>
          <div
            onClick={() => homeIconPress()}
            style={{
              cursor: 'pointer',
              textAlign: 'center',
              padding: 6,
            }}
          >
            <img
              style={{ width: '190px' }}
              src={_theme.palette.mode == 'dark' ? LightAppLogo : DarkAppLogo}
            />
          </div>
        </Hidden>
        <Toolbar disableGutters>
          <Grid
            onClick={() => homeIconPress()}
            sx={{
              cursor: 'pointer',
              display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
            }}
          >
            <img
              style={{ width: '200px' }}
              src={_theme.palette.mode == 'dark' ? LightAppLogo : DarkAppLogo}
            />
          </Grid>
          <Grid container>
            <Grid item md={2.5} xs={0.5}>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  sx={{ color: colors.black[100] }}
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {[{ name: 'Symbols', navigateTo: 'dashboard' }, ...pages].map((page) => (
                    <MenuItem
                      key={page.name}
                      onClick={(event) =>
                        handlePageClick(event, page.navigateTo, page.name)
                      }
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Grid>
            <Grid item md={6.5} xs={3.5} >
              <Box
                sx={{
                  justifyContent: 'flex-start',
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={(event) =>
                      handlePageClick(event, page.navigateTo, page.name)
                    }
                    selected={selectedPage === page.name}
                    sx={{
                      '&.Mui-selected': {
                        background: `${colors.topbar.background}!important`,
                        position: 'relative',

                        color: colors.teal,
                        '&:after': {
                          content: '""',
                          position: 'absolute',
                          left: '50%',
                          right: '50%',
                          bottom: 0,
                          transform: 'translateX(-50%)',
                          width: '20%',
                          height: '2px',
                          borderBottom: `1.5px solid ${colors.teal}`,
                        },
                      },
                      '&:hover': {
                        background: `${colors.topbar.background}!important`,
                      },
                      my: 2,
                      color: colors.grey[200],
                      display: 'block',
                      fontWeight: 500,
                      fontSize: '15px',
                      textTransform: 'none',
                    }}
                  >
                    {page.name}
                  </MenuItem>
                ))}
              </Box>
            </Grid>
            <>
              <Grid item md={1.5} xs={4}
                sx={{
                  pr: 3,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box sx={{
                  paddingRight: 3,
                }}>
                  <FormControl fullWidth>
                    {selectedAccount ?
                      <Select
                        MenuProps={{
                          PaperProps: {
                            style: {
                              backgroundColor: colors.accountMenu.background
                            },
                          },
                        }}
                        value={selectedAccount?.accountNumber}
                        variant="standard"
                        disableUnderline
                        sx={{
                          backgroundColor: colors.dashboard.background,
                          border: 'none',
                          width: 120,
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          '& .MuiOutlinedInput-root': {
                            border: 'none',
                          },
                          '& .MuiInputBase-root': {
                            border: 'none',
                          },


                        }}
                        renderValue={() => renderValue(selectedAccount?.accountNumber)}
                        onChange={handleAccountChange}
                      >
                        {userAccounts?.map((account) => (
                          <MenuItem
                            sx={{
                              display: 'inherit',
                            }}
                            key={account.accountNumber}
                            value={account.accountNumber}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>
                                <div>{account.accountNumber}</div>
                                <div
                                  style={{ fontSize: '0.8rem', color: '#A6A6A6' }}
                                >
                                  {account.accountAlias}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: 100,
                                }}
                              ></div>
                              {account.status == ACCOUNT_STATUS.ACTIVE ? (
                                <div>
                                  <Typography sx={{ color: colors.green }}>
                                    Active
                                  </Typography>
                                </div>
                              ) : (
                                <div>
                                    <Typography sx={{ color: [ACCOUNT_STATUS.ACTIVE, ACCOUNT_STATUS.COMPLETED].includes(account?.status) ? colors.green : colors.red }}>
                                    {getAccountStatus(account?.status)}
                                  </Typography>
                                </div>
                              )}
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                      : <div></div>}
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={1.5} xs={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ paddingLeft: 3 }}>
                  <IconButton onClick={handleClick} sx={{ p: 0 }}>
                    <Typography style={{
                      color: colors.black[100]
                    }}>{user?.firstName}</Typography> &nbsp;
                    <Avatar
                      sx={{
                        bgcolor: '#DBAD38',
                      }}
                    >
                      {getAvatarNameChar(user)}
                    </Avatar>
                  </IconButton>

                  <Menu
                    sx={{
                      mt: '45px',
                      '& .MuiPaper-root': {
                        backgroundColor: colors.accountMenu.background,
                      },
                    }}

                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={() => handleClose()}
                  >
                    <MenuItem onClick={() => handleClose()} disableRipple>
                      <Stack
                        sx={{
                          pr: { md: 16, lg: 16, sm: 16, xs: 16 },
                        }}
                        direction="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <Avatar
                          sx={{
                            bgcolor: '#DBAD38',
                          }}
                          alt={'displayName'}
                        >
                          {getAvatarNameChar(user)}
                        </Avatar>
                        <div>
                          <Typography variant="subtitle1">{getUserName(user)}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {user?.email}
                          </Typography>
                        </div>
                      </Stack>
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />

                    <MenuItem onClick={() => handleClose()} disableRipple>
                      <PersonOutlineOutlinedIcon />
                      <Typography pl={1}>My Profile</Typography>
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={() => navigate(`/payout?userId=${user?._id}`)} disableRipple>
                      <PersonOutlineOutlinedIcon />
                      <Typography pl={1}>Account History</Typography>
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={() => handleClose()} disableRipple>
                      <SettingsOutlinedIcon />
                      <Typography pl={1}>Settings</Typography>
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />

                    <MenuItem
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      disableRipple
                    >
                      <span
                        style={{
                          display: 'flex',
                        }}
                      >
                        <DarkModeOutlinedIcon />
                        &nbsp;Theme
                      </span>
                      <div>
                        <Switch
                          sx={{

                            "& .MuiSwitch-switchBase": {
                              "&.Mui-checked": {
                                "+ .MuiSwitch-track": {
                                  backgroundColor: "lightgrey"
                                },
                                ".MuiSwitch-thumb": {
                                  backgroundColor: colors.teal
                                }
                              }
                            }
                          }}
                          checked={isThemeChecked}
                          onChange={handleThemeChange}
                        />

                      </div>
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />

                    <MenuItem onClick={() => handleLogout()} disableRipple>
                      <Typography
                        pl={1}
                        sx={{
                          color: '#FF4757',
                        }}
                      >
                        Logout
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid></>
          </Grid>
        </Toolbar>
        <Dialog
          open={accountCloseDialog}
          onClose={handleAccountDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{
            mb: '10px',
          }} style={{
            fontWeight: 600,
            borderBottom: '0.5px solid grey'
          }} align='center' id="alert-dialog-title">
            {accountContext?.status == ACCOUNT_STATUS.RESET ? 'Account Reset' :
              accountContext?.status == ACCOUNT_STATUS.LIQUIDATED ? 'Account Liquidated' : undefined}
          </DialogTitle>
          <DialogContent>
            
            {accountContext?.status == ACCOUNT_STATUS.RESET
              ?
              <DialogContentText id="alert-dialog-description"> Oops ! Account no. <b>{selectedAccount?.accountNumber}</b> got liquidated as trading cycle is over.Good news, since your account was in profit, you will get a free reset & a new account details sent to your registered Email ID.</DialogContentText>
              :
              accountContext?.status == ACCOUNT_STATUS.LIQUIDATED && accountContext?.liquidationReason == LIQUIDATION_REASON.LOSS ?
                <DialogContentText id="alert-dialog-description">Oops! Account no. <b>{selectedAccount?.accountNumber}</b> got liquidated due to breach of loss limits. <a style={{
                color: '#0ce7e7'
              }} href='https://www.uppercircuit.in' target='blank'> Click here</a> to get a new account and start trading right away</DialogContentText>
                : <DialogContentText id="alert-dialog-description">Oops! Account no. <b>{selectedAccount?.accountNumber}</b> got liquidated as trading cycle is over. <a style={{
                  color: '#0ce7e7'
                }} href='https://www.uppercircuit.in' target='blank'> Click here</a> to get a new account and start trading right away</DialogContentText>
            }
          </DialogContent>
          <DialogActions sx={{
            justifyContent: 'center'
          }}>
            <Button onClick={handleAccountDialogClose} autoFocus
              sx={{
                mr: '10px',
                mb: '10px',
                width: '20%'
              }}
              variant="outlined"
              size="large"
              style={{
                backgroundColor: colors.teal,
              }}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </AppBar>
  );
};

export default CustomerTopbar;
