import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    loading: false,
    open: false,
    message: null,
    key: null
  },

  reducers: {
    sendNotification: (notification, action) => {
      notification.message = action.payload.message;
      notification.open = true;
      notification.key = Date.now();
    },
  },
});

const { actions, reducer } = notificationSlice;
export const { sendNotification, } = actions;
export default reducer;
