import React, { useEffect, useState } from 'react';

import {
  Breadcrumbs,
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid,
  InputLabel,
  Link,
  Paper, Popover,
  TextField,
  Typography
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUsers, updateUser } from '../../../state/actions/admin.actions';
import { getPlans } from '../../../state/actions/common.actions';
import AdminTopBar from '../../global/AdminTopbar';

import RArrowImage from '../../../assets/r-arrow.png';
import AccountsTable from '../accounts/AccountsTable';

const UserDetails = () => {
  const location = useLocation();
  const { userData = null, userNumber = '' } = location.state ?? {};

  const [declineReasonText, setDeclineReasonText] = useState('');

  const [user, setUser] = useState({
    userNumber: userNumber,
    userId: '',
    username: '',
    plan: '',
    planAlias: '',
    userType: '',
    userAlias: '',
    userSize: '',
    amount: '',
    dll: '',
    tll: '',
    profitTarget: '',
    tradeSquareOff: '',
    validityDays: '',
  });


  const [deleteConfirmationAnchorEl, setDeleteConfirmationAnchorEl] = React.useState(null);

  const navigate = useNavigate();


  const dispatch = useDispatch();

  const _getUsers = () => {
    dispatch(getUsers({
      page: 1, limit: 100
    }));
  };

  const localUser = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    setUser(userData);
  }, [location]);

  useEffect(() => {
    dispatch(getPlans({}));
    _getUsers();
  }, []);


  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationAnchorEl(null);
  };

  const handleDeleteUser = (row) => {
    let data = {
      ...row, active: false, deleted: true
    };
    dispatch(updateUser(data));
    setDeleteConfirmationAnchorEl(null);
    navigate('/admin-users');
  };


  const handleUserDeleteOpen = (event, row) => {
    setDeleteConfirmationAnchorEl(event.currentTarget);
  };

  const handleDeclineUser = () => {
    setOpenDeclineDialog(true);
  };


  const handleApproveUser = () => {
    let data = {
      ...user, status: 1
    };
    dispatch(updateUser(data));
    navigate('/admin-users');
  };


  const [openDeclineDialog, setOpenDeclineDialog] = React.useState(false);


  const handleDeclineDialogClose = () => {
    setOpenDeclineDialog(false);
  };

  const handleDeclineConfirm = () => {
    let data = {
      ...user, status: 3, declineReason: declineReasonText
    };
    dispatch(updateUser(data));
    navigate('/admin-users');
  };

  const handleDeclineReasonText = (e) => {
    setDeclineReasonText(e.target.value);
  };

  const open = Boolean(deleteConfirmationAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <AdminTopBar />

      <Grid sx={{
        p: 3,
        display: {
          sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
        },
        alignItems: 'center',
        justifyContent: 'space-between'
      }} item xs={12} md={12} lg={12} sm={12} mb={2}>
        <div>
          <Breadcrumbs sx={{
            pl: 1, pt: 1,
          }} separator={<img alt='img' style={{
            width: 9
          }} src={RArrowImage} />} aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" href="/admin-users">
              <Typography sx={{
                fontSize: 17,
                fontWeight: 600
              }}>Users</Typography>
            </Link>
            <Link underline="hover" key="1" color="inherit" href="/admin-users">
              <Typography sx={{
                fontSize: 17,
                fontWeight: 600,
                color: '#41AAAF'
              }}>User Details</Typography>
            </Link>
          </Breadcrumbs>
        </div>

        <div style={{
          display: 'flex',
          width: localUser.role === 'SUPERADMIN' && [2, 4].includes(user?.status) ? 300 : 180
        }}>
          {localUser.role === 'SUPERADMIN' && user?.status === 2 && <><Button
            fullWidth
            onClick={() => handleApproveUser(user)}
            sx={{
              background: '#41AAAF',
              color: '#ffffff',
              fontSize: 10,
              marginLeft: 2,
              '&:hover': {
                backgroundColor: '#41AAAF',
                color: '#ffffff'

              },
              '&:disabled': {
                backgroundColor: 'gray',
                color: '#ffffff'
              },
            }} type='contained' >Approve</Button>
          <Button
            fullWidth
            onClick={() => handleDeclineUser(user)}
            sx={{
              marginLeft: 2,
              fontSize: 10,

              background: '#FF4757',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#FF4757',
                color: '#ffffff'
              },
            }} type='contained' >Decline</Button>

          <Dialog open={openDeclineDialog} onClose={handleDeclineDialogClose}>
            <DialogTitle>Decline Reason</DialogTitle>
            <DialogContent >
              <TextField
                sx={{
                  width: 470
                }}
                value={declineReasonText}
                onChange={handleDeclineReasonText}
                autoFocus
                margin="dense"
                id="name"
                label="Enter decline reason"
                type="text"
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeclineDialogClose}>Cancel</Button>
              <Button onClick={handleDeclineConfirm}>Confirm</Button>
            </DialogActions>
          </Dialog>
          </>
          }
          {localUser.role === 'SUPERADMIN' && user?.status === 4 && <><Button
            fullWidth
            onClick={() => handleDeleteUser(user)}
            sx={{
              background: '#41AAAF',
              color: '#ffffff',
              fontSize: 10,
              marginLeft: 2,
              '&:hover': {
                backgroundColor: '#41AAAF',
                color: '#ffffff'

              },
              '&:disabled': {
                backgroundColor: 'gray',
                color: '#ffffff'
              },
            }} type='contained' >Confirm Delete</Button>
          </>
          }
          <Button
            fullWidth
            onClick={handleUserDeleteOpen}
            sx={{
              fontSize: 10,
              marginLeft: 2,
              background: '#FF4757',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#FF4757',
                color: '#ffffff'
              },
            }} type='contained' >Delete</Button>
          <Popover
            sx={{
              '& .MuiPaper-root': {
                boxShadow: 'none',
              },
            }}
            id={id}
            open={open}
            anchorEl={deleteConfirmationAnchorEl}
            onClose={handleDeleteConfirmationClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div style={{
              margin: 8
            }}>
              <Typography sx={{
                fontWeight: 600,
                paddingBottom: 1
              }}>Are you sure?</Typography>
              <div style={{

              }}>
                <Button
                  onClick={() => handleDeleteUser(user)}
                  sx={{
                    marginRight: 2,
                    color: '#ffffff',
                    background: '#41aaae',
                    borderColor: 'unset',
                    '&:hover': {
                      backgroundColor: '#41aaae',
                      color: '#ffffff'

                    },
                  }} variant="outlined">Confirm</Button>
                <Button
                  onClick={handleDeleteConfirmationClose}
                  sx={{
                    background: '#FF4757',
                    color: '#ffffff',
                    borderColor: 'unset',
                    '&:hover': {
                      backgroundColor: '#FF4757',
                      color: '#ffffff'

                    },

                  }} variant="outlined">Cancel</Button>
              </div>
            </div>
          </Popover>
        </div>

      </Grid>

      <div style={{
        paddingLeft: 27,
        paddingRight: 27,
      }}>
        <Paper >
          <Grid container p={3}>

            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                                First Name
              </InputLabel>
              <Typography>{user?.firstName}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">Last Name</InputLabel>
              <Typography>{user?.lastName}</Typography>



            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">Email</InputLabel>
              <Typography>{user?.email}</Typography>

            </Grid>
            <Grid pb={5} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                                Username
              </InputLabel>
              <Typography>{user?.username}</Typography>

            </Grid>
            <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                User Type
              </InputLabel>
              <Typography>{user?.role}</Typography>

            </Grid>
            {user?.declineReason && <Grid pb={2} pr={2} item md={3} lg={3} sm={12} xs={12} >
              <InputLabel shrink htmlFor="bootstrap-input">
                Decline Reason
              </InputLabel>
              <Typography>{user?.declineReason}</Typography>

            </Grid>}
          
          </Grid>
          {user?.role == 'CUSTOMER' &&<><Typography sx={{
            ml: 2, 
            fontWeight: 600
        }}>Accounts</Typography>
           <Grid container p={2}>
            <AccountsTable accounts={user?.accounts} />
            </Grid></>}
        </Paper>
      </div>
    </>
  );
};

export default UserDetails;
