// export const SOCKET_URL = 'http://3.109.137.187:4001';
// export const REST_URL = 'http://3.109.137.187:4000/v1/api';

export const SOCKET_URL = 'https://socket-prod.uctrader.in';
export const REST_URL = 'https://api-prod.uctrader.in/v1/api';

// 
// local
// export const SOCKET_URL = 'http://localhost:4001';
// export const REST_URL = 'http://localhost:4000/v1/api';


export const CHARGE = 0;
export const MARGIN = 0.66;

export const CAPTCHA_TOKEN = '6LcMFn0pAAAAAH1WzhjpodAF944qYTbdpc_wK5rT';
export const NOTIFICATION_TYPES = {
    ORDER_BUY_SELL_CHANGE: 'ORDER_BUY_SELL_CHANGE',
    OPEN_ORDER_PLACER: 'OPEN_ORDER_PLACER',
    CLOSED_ORDERS_EOD: 'CLOSED_ORDERS_EOD',
    ACCOUNT_LOSS_REFRESHER: 'ACCOUNT_LOSS_REFRESHER',
    TARGET_STOPLOSS_REFRESHER: 'TARGET_STOPLOSS_REFRESHER',
};

export const REQUEST_STATUS = {
    CREATED: 1,
    COMPLETED: 2,
    REJECTED: 3,
};

export const REQUEST_TYPES = {
    ACCOUNT_UPGRADE: 1,
};

export const USER_STATUS = {
    ACTIVE: 1,
    PENDING: 2,
    INACTIVE: 3,
    PENDING_DELETION: 4,
    DELETED: 5,
};

export const ACCOUNT_STATUS = {
    ACTIVE: 1,
    PENDING: 2,
    INACTIVE: 3,
    PENDING_DELETION: 4,
    LIQUIDATED: 5,
    COMPLETED: 6,
    RESET: 7,
    DELETED: 7,
};

export const PAYOUT_STATUS = {
    REQUESTED: 1,
    PROCESSED: 2,
    REJECTED: 3,
};

export const LIQUIDATION_REASON = {
    LOSS: 1,
    TRADING_CYCLE_OVER: 2,
};

export const MINIMUM_TRADING_DAYS = 5;
export const WAITING_DAYS = 3;
export const S3_BASE_URL = 'https://uctrader-assets.s3.ap-south-1.amazonaws.com';

//dev
// export const AWS_ACCESS_KEY = 'AKIA2WX3KO2PM2KLWB4L';
// export const AWS_SECRET_KEY = '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';


//prod
export const AWS_ACCESS_KEY = 'AKIA47CRXW55KGPFH27G';
export const AWS_SECRET_KEY = '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';

export const S3_BUCKET = 'uctrader-assets';
export const REGION = 'ap-south-1';