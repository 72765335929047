export function generateRandomString(length) {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    result += charset.charAt(randomIndex);
  }

  return result;
}

export function formatPrice(num) {
  if (num) {
    num = Number(num).toFixed(2).replace(/\.0+$/, '');
    return Number(num).toLocaleString('en-IN', {});
  }
  return 0;
}

