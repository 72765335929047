import { configureStore } from '@reduxjs/toolkit';
import auth from './state/reducers/auth.reducer';
import admin from './state/reducers/admin.reducer';
import common from './state/reducers/common.reducer';
import user from './state/reducers/user.reducer';
import notification from './state/reducers/notification.reducer';
import trade from './state/reducers/trade.reducer';
import api from './state/api.middleware';

export default function store() {
  return configureStore({
    reducer: { auth, admin, common, notification, trade, user },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api),
  });
}