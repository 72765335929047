import {
  useTheme,
  Table,
  TableContainer, TableCell, TableSortLabel, Typography, TableRow, TableHead, Paper, TableBody, IconButton, TextField, InputAdornment, Button
} from '@mui/material';
import { tokens } from '../../theme';
import DownloadIcon from '@mui/icons-material/Download';

import {
  Grid
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getTrades, getPositions, downloadTrades, updateTrade, updateTargetStopLoss, createTrade } from '../../state/actions/trade.actions';
import CustomerTopbar from '../global/CustomerTopbar';
import InstrumentList from '../instrument/InstrumentList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DeleteImage from '../../assets/delete.png';
import EditImage from '../../assets/edit.png';
import { Search as SearchIcon } from '@mui/icons-material';
// import { useSocket } from '../../SocketContext';
import { formatPrice } from '../../utils/string';
import { getUser } from '../../state/actions/auth.actions';
import { io } from 'socket.io-client';
import { NOTIFICATION_TYPES, SOCKET_URL } from '../../config';
import BuySell from '../dashboard/Elements/BuySell';
import { CHARGE } from '../../config';
import DailyImage from '../../assets/daily.png';
import TotalImage from '../../assets/total.png';
import ProfitImage from '../../assets/profit.png';
import { useUserContext } from '../../UserContext';
import { getAccountBalance } from '../../state/actions/user.actions';

const socket = io(SOCKET_URL, {
  transportOptions: {
    polling: {
      extraHeaders: {},
    },
  },
});


const Orders = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');
  const accountId = queryParams.get('accountId');
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({});

  const dispatch = useDispatch();
  const { accountContext, userContext, updateAccountContext, updateUserContext } = useUserContext();
  const accountBalancesState = useSelector((state) => state.user?.accountBalances);

  const auth = useSelector((state) => state.auth);
  const userState = useSelector((state) => state.auth?.user);
  const openOrdersState = useSelector((state) => state.trade?.trades);
  const [hoveredOrderRow, setHoveredOrderRow] = useState(null);
  const [hoveredPositionRow, setHoveredPositionRow] = useState(null);
  const [overallBalance, setOverallBalance] = useState(0);
  const [totalProfitLoss, setTotalProfitLoss] = useState(0);
  const [cashBalance, setCashBalance] = useState(0);
  const [instruments, setInstruments] = useState([]);
  const [instrumentName, setInstrumentName] = useState('');
  const [priceDisabled, setPriceDisabled] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(false);
  

  const [price, setPrice] = useState(0);
  const [existingOpenOrderPrice, setExistingOpenOrderPrice] = useState(0);
  
  const [stopLossPrice, setStopLossPrice] = useState(undefined);
  const [priceInfo, setPriceInfo] = useState(0);

  const [lastTradePrice, setLastTradePrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [tradeId, setTradeId] = useState(null);

  const [orderType, setOrderType] = useState('Market');
  const [orderMode, setOrderMode] = useState(1);

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [user, setUser] = useState(auth?.user?.user);
  const [openOrders, setOpenOrders] = useState([]);
  const [positions, setPositions] = useState();
  const [searchText, setSearchText] = useState('');
  const [buySellDialogMode, setBuySellDialogMode] = useState(undefined);
  const [openBuySellDialog, setOpenBuySellDialog] = useState(false);

  const createdTradeState = useSelector((state) => state.trade?.createdTrade);
  const updatedTradeState = useSelector((state) => state.trade?.updatedTrade);
  const updatedTargetStopLossState = useSelector((state) => state.trade?.updatedTargetStoploss);

  const [tabValue, setTabValue] = React.useState('position');
  const [buySellTabValue, setBuySellTabValue] = React.useState();


  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const handleSort = (property) => {
    const direction = sortBy === property && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortBy(property);
    setSortDirection(direction);
  };

  function sortByKey(array, key, direction) {
    return array.slice().sort((a, b) => {
      const compareValue = (direction === 'asc' ? 1 : -1) * (a[key] < b[key] ? -1 : (a[key] > b[key] ? 1 : 0));
      return compareValue;
    });
  }


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSearchText('');
  };

  const handleBuySellTabChange = (newValue) => {
    setStopLossPrice(0);
    setBuySellTabValue(newValue);
  };


  useEffect(() => {
    if (createdTradeState?.trade) {
      let _orderType = `${createdTradeState?.trade?.orderType == 1 ? 'Executed' : 'Placed'}`;
      new Notification(`Order ${_orderType}`, {
        body: `${createdTradeState?.trade?.instrument} | ${createdTradeState?.trade?.quantity} ${_orderType} at ${createdTradeState?.trade?.price}`,
      }).onclick = () => {
        navigate(createdTradeState?.trade?.orderType == 1 ? '/hstry' : '/op');
      };
      resetBuySellValues();
    }
  }, [createdTradeState]);

  useEffect(() => {
    if (updatedTradeState?.trade?.trade) {
      new Notification('Order Updated', {
        body: `${updatedTradeState?.trade?.trade?.instrument} | ${updatedTradeState?.trade?.trade?.quantity} Placed at ${updatedTradeState?.trade?.trade?.price}`,
      }).onclick = () => {
        navigate('/op');
      };
      resetBuySellValues();
    }
  }, [updatedTradeState]);

  useEffect(() => {
    if (updatedTargetStopLossState?.trade) {
      if (instrumentName && stopLossPrice) {
        new Notification('Order Updated', {
          body: `Stop Loss for ${instrumentName} updated at ${stopLossPrice}`,
        }).onclick = () => {
          navigate('/op');
        };
      }
      resetBuySellValues();
    }
  }, [updatedTargetStopLossState]);
  

  useEffect(() => {
    const checkIsMobile = () => {
      const mobileMediaQuery = window.matchMedia('(max-width: 767px)');
      setIsMobile(mobileMediaQuery.matches);
    };

    checkIsMobile();
    const resizeListener = () => {
      checkIsMobile();
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };

  }, []);

  useEffect(() => {
    _getTrades();
    dispatch(getUser({ userId }))
  }, [])

  useEffect(() => {
    if (openOrdersState?.trades?.length) {
      let trades = JSON.parse(JSON.stringify(openOrdersState?.trades));
      for (let trade of trades) {
        let lastTradePrice = getLastTradePrice(trade.instrument);
        trade.lastTradePrice = lastTradePrice;
      }
      let sortedOrders = [];
      sortedOrders = sortByKey(trades, sortBy, sortDirection);
      setOpenOrders(sortedOrders);
    } else {
      setOpenOrders([]);
    }

  }, [openOrdersState, instruments, sortBy, sortDirection, selectedAccount]);

  useEffect(() => {
    if (accountBalancesState) {
      let _openPositions = accountBalancesState?.positions;
      let _positions = [];
      let _totalProfitLoss = 0;
      for (let item of _openPositions) {
        let lastTradePrice = getLastTradePrice(item.instrument);
        let profitLoss = 0;
        let plChange = 0;

        profitLoss = getProfitLoss(item?.instrument, item?.price, item?.quantity, item?.status, item?.executedQuantity, item?.mode, item?.average);
        profitLoss = isNaN(profitLoss) ? 0 : profitLoss;
        plChange = profitLoss / (item?.quantity * item.average);
        _totalProfitLoss += profitLoss;
        _positions.push({
          ...item,
          lastTradePrice,
          plChange,
          profitLoss
        });
      }
      let sortedPositions = [];
      sortedPositions = sortByKey(_positions, sortBy, sortDirection);
      setPositions(sortedPositions);
      setTotalProfitLoss(_totalProfitLoss);
      setCashBalance(accountBalancesState?.cashBalance);
      setOverallBalance(accountBalancesState?.cashBalance +
        _totalProfitLoss +
        accountBalancesState?.positionsTotal +
        accountBalancesState?.openOrdersTotal);
    }
  }, [accountBalancesState, instruments, sortBy, sortDirection, selectedAccount]);


  useEffect(() => {
    if (auth?.user?.user) {
      setUser(auth?.user?.user);
    }
  }, [auth])

  useEffect(() => {
    if (userState?.user) {
      updateAccountContext(
        userState?.user?.accounts?.find((account)=> account?._id == selectedAccount?._id)
      );
    }
  }, [userState])

  

  useEffect(() => {
    if (accountContext) {
      setSelectedAccount(accountContext);
      setSearchParams({ accountId: accountContext?._id, userId: userId });
    }
    _getTrades();
  }, [accountContext])

  useEffect(() => {
    if (userContext) {
      setUser(userContext);
    }
  }, [userContext])

  function _getTrades() {
    let params = {};
    if (searchText?.length >= 2) {
      params.q = searchText;
    }
    if (userId && accountContext?._id) {
      dispatch(getTrades({ userId, accountId: accountContext?._id, status: '2', ...params }));
      dispatch(getAccountBalance({ accountId: accountContext?._id }));
    }
  }

  useEffect(() => {
    _getTrades();
  }, [searchParams, searchText]);


  useEffect(() => {
    _setMarketPrice();
  }, [orderType, openBuySellDialog, instruments]);

  useEffect(() => {
    socket.on(`${userId}`, (data) => {
      if (data?.type == NOTIFICATION_TYPES.ORDER_BUY_SELL_CHANGE) {
        _getTrades();
      }
      if (data?.type == NOTIFICATION_TYPES.OPEN_ORDER_PLACER) {
        _getTrades();
      }
      if (data?.type == NOTIFICATION_TYPES.CLOSED_ORDERS_EOD) {
        _getTrades();
      }
      if (data?.type == NOTIFICATION_TYPES.TARGET_STOPLOSS_REFRESHER) {
        _getTrades();
      }
    });
    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };
    return () => { };
  }, []);

  const handleOrderRowHover = (rowId) => {
    setHoveredOrderRow(rowId);
  };

  const handleOrderRowLeave = () => {
    setHoveredOrderRow(null);
  };

  const handlePositionRowHover = (rowId) => {
    setHoveredPositionRow(rowId);
  };

  const handlePositionRowLeave = () => {
    setHoveredPositionRow(null);
  };

  function getTradeTypeColor(type) {
    switch (type) {
      case 1:
        return colors.green;
      case 2:
        return '#FF4757';
      default:
        return '#FF4757';
    }
  }



  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const onInstrumentsChange = (instruments) => {
    setInstruments(instruments);
  };


  const handleBuySellClose = (event, reason) => {
    resetBuySellValues();
  };

  function resetBuySellValues() {
    setOpenBuySellDialog(false);
    setQuantity(1);
    setOrderType('Market');
    setPrice(0);
    setStopLossPrice(0);
    setPriceInfo(0);
    setExistingOpenOrderPrice(0);
    setHoveredOrderRow(null);
  }



  function getProfitLoss(instrument, praice, quantity, status, executedQuantity, mode, averagePrice) {
    let _instrument = instruments?.find((item) => item?.[0] == instrument);
    if (mode === 1) {
      if (status === 6) {
        let openQuantity = quantity - executedQuantity;
        return (Number(_instrument?.[1]) * openQuantity) - Number(averagePrice * openQuantity);
      } else {
        return (Number(_instrument?.[1]) * quantity) - Number(averagePrice * quantity);
      }
    } else {
      if (status === 6) {
        let openQuantity = quantity - executedQuantity;
        let value = (Number(_instrument?.[1]) * openQuantity) - Number(averagePrice * openQuantity);
        value = value * -1;
        return value;
      } else {
        let value = (Number(_instrument?.[1]) * quantity) - Number(averagePrice * quantity);
        return value * -1;
      }
    }
  }


  function getLastTradePrice(instrument) {
    let _instrument = instruments?.find((item) => item?.[0] == instrument);
    return _instrument?.[1];
  }

  function onBuySellChange() {
    _getTrades();
    dispatch(getUser({ userId }))
    setDeleteDisabled(false);
  }

  function downloadPositions() {
    dispatch(downloadTrades({ type: 1, userId, accountId: accountId, status: '1,6', position: 1 }));
  }

  function handleEditOrder(order) {
    setBuySellDialogMode('Edit');
    setBuySellTabValue(order?.type === 1 ? 'Buy' : 'Sell');
    setOrderType(order?.orderType === 1 ? 'Market' : 'Limit');
    setOrderMode(order?.mode);
    setPriceDisabled(order?.orderType === 1 ? true : false);
    setPrice(order?.price);
    setQuantity(order?.quantity);
    setExistingOpenOrderPrice(order?.price * order?.quantity);
    setTradeId(order?._id);
    setInstrumentName(order?.instrument);
    setStopLossPrice(order?.stopLossPrice);
    setOpenBuySellDialog(true);
  }

  function handlePositionAdd(order) {
    setBuySellDialogMode('Add');
    setBuySellTabValue(order?.type === 1 ? 'Buy' : 'Sell');
    setOrderType('Market');
    setOrderMode(order?.mode);
    setPriceDisabled(true);
    setInstrumentName(order?.instrument);
    setOpenBuySellDialog(true);
  }

  function handlePositionExit(order) {
    setBuySellDialogMode('Exit');
    setBuySellTabValue(order?.type === 1 ? 'Sell' : 'Buy');
    setOrderMode(order?.mode);
    setPriceDisabled(true);
    setOrderType('Market');
    setPrice(order?.average);
    setQuantity(order?.quantity - order?.executedQuantity);
    setInstrumentName(order?.instrument);
    setOpenBuySellDialog(true);
  }

  function handleEditTargetStopLoss(order) {
    setTradeId(order?._id);
    setBuySellDialogMode('Update StopLoss');
    setBuySellTabValue(order?.type === 1 ? 'Buy' : 'Sell');
    setOrderType(order?.orderType === 1 ? 'Market' : 'Limit');
    setOrderMode(order?.mode);
    setPriceDisabled(order?.orderType === 1 ? true : false);
    setPrice(order?.average);
    setQuantity(order?.quantity - order?.executedQuantity);
    setInstrumentName(order?.instrument);
    setStopLossPrice(order?.stopLossPrice);
    setOpenBuySellDialog(true);
  }

  function handleDeleteOrder(order) {
    setDeleteDisabled(true);
    dispatch(updateTrade({
      _id: order?._id,
      status: 4
    }));
    setTimeout(() => onBuySellChange(), 1500);
  }

  function getQuantity(trade) {
    return trade.quantity - trade.executedQuantity;
  }

  function getOrderType() {
    return orderType === 'Market' ? 1 : orderType === 'Limit' ? 2 : 3;
  }

  const handleBuy = (event, reason) => {
    if (tabValue == 'openorder') {
      dispatch(updateTrade({
        'instrument': instrumentName,
        'quantity': quantity,
        status: 2,
        _id: tradeId,
        'type': 1,
        'orderType': getOrderType(),
        'account': accountId,
        'user': user?._id,
        'price': Number(price).toFixed(2),
        'stopLossPrice': stopLossPrice > 0 ? Number(stopLossPrice).toFixed(2) : null,
        chargePercentage: CHARGE
      }));
      setTimeout(() => onBuySellChange(), 1500);
    }
    if (tabValue == 'position') {
      dispatch(createTrade({
        'instrument': instrumentName,
        'quantity': quantity,
        status: 2,
        'type': 1,
        'orderType': getOrderType(),
        'account': accountId,
        'user': user?._id,
        'price': Number(price).toFixed(2),
        'stopLossPrice': stopLossPrice > 0 ? Number(stopLossPrice).toFixed(2) : null,
        chargePercentage: CHARGE
      }));
      setTimeout(() => onBuySellChange(), 1500);
    }
  };

  const handleQuantityChange = (event) => {
    let qValue = event?.target?.value;
    if (/^\d*$/.test(qValue) && qValue != 0) {
      setQuantity(String(Number(qValue)));
    }
    else if (qValue == '') {
      setQuantity('')
    }
  };

  const handleSell = (event, reason) => {
    if (tabValue == 'openorder') {
      dispatch(updateTrade({
        'instrument': instrumentName,
        status: 2,
        _id: tradeId,
        'quantity': quantity,
        'type': 2,
        'orderType': getOrderType(),
        'account': accountId,
        'user': user?._id,
        'price': Number(price).toFixed(2),
        'stopLossPrice': stopLossPrice > 0 ? Number(stopLossPrice).toFixed(2) : null,
        chargePercentage: CHARGE
      }));
      setTimeout(() => onBuySellChange(), 1500);
    }
    if (tabValue == 'position') {
      dispatch(createTrade({
        'instrument': instrumentName,
        status: 2,
        'quantity': quantity,
        'type': 2,
        'orderType': getOrderType(),
        'account': accountId,
        'user': user?._id,
        'price': Number(price).toFixed(2),
        'stopLossPrice': stopLossPrice > 0 ? Number(stopLossPrice).toFixed(2) : null,
        chargePercentage: CHARGE
      }));
      setTimeout(() => onBuySellChange(), 1500);
    }
  };

  const handlePriceChange = (event) => {
    let pValue = event?.target?.value;
    if (/^[0-9]\d*\.?\d*$/.test(pValue) && pValue != 0) {
      setPrice(String(Number(pValue)));
    } else if (pValue == '') {
      setPrice('')
    }
  };

  const handleStopLossPriceChange = (event) => {
    let pValue = event?.target?.value;
    if (/^\d.*$/.test(pValue)) {
      setStopLossPrice(String(Number(pValue)));
    } else if (pValue == '') {
      setStopLossPrice('')
    }
  };

  const handleOrderTypeChange = (event) => {
    let value = event?.target?.value;
    if (value === 'Market') {
      setPriceDisabled(true);
    }
    if (value === 'Limit') {
      setPriceDisabled(false);
    }
    _setMarketPrice();
    setOrderType(value);
  };

  function _setMarketPrice() {
    let _item = instruments?.find((item) => item?.[0] == instrumentName);
    if (openBuySellDialog && orderType == 'Market') {
      setPrice(_item?.[1]);
    }
    setPriceInfo(_item?.[1]);
    setLastTradePrice(_item?.[1]);
  }

  function handleUpdatePosition() {
    dispatch(updateTargetStopLoss({
      _id: tradeId,
      'stopLossPrice': stopLossPrice > 0 ? Number(stopLossPrice).toFixed(2) : null,
    }));
    setTimeout(() => onBuySellChange(), 1500);
  }

  return (
    <>
      {auth?.user?.user && <CustomerTopbar user={auth?.user?.user} account={selectedAccount} />}
      <div style={{
        display: 'flex', height: '100vh',
        paddingTop: isMobile ? '114px' : '60px',
        backgroundColor: colors.home[100]
      }}>
        <InstrumentList user={user} account={selectedAccount} onInstrumentsChange={onInstrumentsChange} onBuySellChange={onBuySellChange} />
        <Grid
          style={{
              paddingLeft: 18,
              flex: 2,
          }}
          pt={2}
          xs={12}
          sm={12}
          md={8.5}
          lg={8.5}
        >
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
                <Grid container>
                  <Grid item md={7} xs={12} >
                    <TabList sx={{
                      '& .MuiTabs-indicator': {
                        backgroundColor: colors.teal,
                      },
                    }} onChange={handleTabChange}>
                      <Tab sx={{
                        '&.Mui-selected': {
                          color: colors.teal,
                        },
                      }} label="Positions" value="position" />
                      <Tab sx={{
                        '&.Mui-selected': {
                          color: colors.teal,
                        },
                      }} label="Open Orders" value="openorder" />

                    </TabList>
                  </Grid>

                  <Grid item md={4.5} xs={12} sx={{
                  }} >
                    <div style={{
                      paddingBottom: 5,
                      display: 'flex', justifyContent: 'flex-end'
                    }}>
                      <TextField
                        variant="outlined"
                        sx={{
                          justifyContent: 'flex-end',
                          width: { md: 250, lg: 250, sm: '100%', xs: '100%' },
                        }}
                        value={searchText}
                        onChange={handleSearchChange}
                        fullWidth
                        placeholder="Search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {tabValue == 'position' && <Button
                        onClick={downloadPositions}
                        sx={{
                          width: 170,
                          marginLeft: 2,
                          marginRight: 2,
                          height: 52,
                          color: colors.teal,
                          borderColor: colors.teal,
                          '&:hover': {
                            borderColor: colors.teal
                          },
                        }} variant="outlined" startIcon={<DownloadIcon />}>
                        Download
                      </Button>}
                    </div>
                  </Grid>
                </Grid>
              </Box>

              <TabPanel  value="position">

                <Grid style={{
                  borderRadius: 10,
                  paddingBottom: 15,
                }} container columnSpacing={2} >
                  <Grid item xs={12} sm={12} md={4}>
                    <Paper style={{ padding: 15, backgroundColor: colors.dashboardCards.background, boxShadow: '0px 1px 5px 0px #00000029' }}>
                      <div style={{
                      }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}><img
                          alt="total-count"
                          src={ProfitImage}></img>&nbsp;<Typography style={{
                            fontWeight: 600,
                            fontSize: 12,
                            padding: 2,
                            color: colors.black[100]
                          }}>Balance</Typography></span>

                        <Typography style={{
                          fontWeight: 600,
                          fontSize: 20,
                          paddingLeft: 2,
                          paddingTop: 5,
                        }}>{formatPrice(overallBalance)}</Typography>
                      </div>
                    </Paper>
                  </Grid>


                  <Grid item xs={12} sm={12} md={4}  >
                    <Paper style={{ padding: 15, backgroundColor: colors.dashboardCards.background, boxShadow: '0px 1px 5px 0px #00000029' }}>
                      <div style={{
                      }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}><img
                          alt="total-count"
                          src={DailyImage}></img>&nbsp;<Typography style={{
                            fontWeight: 600,
                            fontSize: 12,
                            padding: 2,
                            color: colors.black[100]
                          }}>Margin Remaining</Typography></span>

                        <Typography style={{
                          fontWeight: 600,
                          fontSize: 20,
                          paddingLeft: 2,
                          paddingTop: 5,
                        }}>{formatPrice(cashBalance)}</Typography>
                      </div>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}  >
                    <Paper style={{ padding: 15, backgroundColor: colors.dashboardCards.background, boxShadow: '0px 1px 5px 0px #00000029' }}>
                      <div style={{
                      }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}><img
                          alt="total-count"
                          src={TotalImage}></img>&nbsp;<Typography style={{
                            fontWeight: 600,
                            fontSize: 12,
                            padding: 2,
                            color: colors.black[100]
                          }}>P & L</Typography></span>

                        <Typography style={{
                          fontWeight: 600,
                          fontSize: 20,
                          paddingLeft: 2,
                          paddingTop: 5,
                        }}>{formatPrice(totalProfitLoss)}</Typography>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
                <TableContainer  >
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: theme.palette.action.hover,
                        },
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}>
                        <TableCell sx={{
                          color: colors.tableText,
                          fontWeight: 500,
                          fontSize: 12,
                          width: '10px',
                        }} ><TableSortLabel
                          active={sortBy === 'instrument'}
                          direction={sortBy === 'instrument' ? sortDirection : 'asc'}
                          onClick={() => handleSort('instrument')}
                        >
                            Symbol
                          </TableSortLabel></TableCell>
                        <TableCell sx={{
                          color: colors.tableText,
                          fontWeight: 500,
                          fontSize: 12,
                          width: '5px',

                        }} ><TableSortLabel
                          active={sortBy === 'type'}
                          direction={sortBy === 'type' ? sortDirection : 'asc'}
                          onClick={() => handleSort('type')}
                        >
                            Type
                          </TableSortLabel></TableCell>
                        <TableCell sx={{
                          color: colors.tableText,
                          fontWeight: 500,
                          fontSize: 12,
                          width: '20px',
                        }} ><TableSortLabel
                          active={sortBy === 'quantity'}
                          direction={sortBy === 'quantity' ? sortDirection : 'asc'}
                          onClick={() => handleSort('quantity')}
                        >
                            Qty
                          </TableSortLabel></TableCell>
                        <TableCell sx={{
                          color: colors.tableText,
                          fontWeight: 500,
                          fontSize: 12,
                          width: '20px',
                        }} ><TableSortLabel
                          active={sortBy === 'average'}
                          direction={sortBy === 'average' ? sortDirection : 'asc'}
                          onClick={() => handleSort('average')}
                        >
                            Avg
                          </TableSortLabel></TableCell>
                        <TableCell sx={{
                          color: colors.tableText,
                          fontWeight: 500,
                          fontSize: 12,
                          width: '20px',
                        }} ><TableSortLabel
                          active={sortBy === 'lastTradePrice'}
                          direction={sortBy === 'lastTradePrice' ? sortDirection : 'asc'}
                          onClick={() => handleSort('lastTradePrice')}
                        >
                            LTP
                          </TableSortLabel></TableCell>
                        <TableCell sx={{
                          color: colors.tableText,
                          fontWeight: 500,
                          fontSize: 12,
                          width: '20px',
                        }} ><TableSortLabel
                          active={sortBy === 'profitLoss'}
                          direction={sortBy === 'profitLoss' ? sortDirection : 'asc'}
                          onClick={() => handleSort('profitLoss')}
                        >
                            P&L
                          </TableSortLabel></TableCell>
                        <TableCell sx={{
                          color: colors.tableText,
                          width: '20px',
                        }}>
                            SL
                      </TableCell>
                        <TableCell sx={{
                          width: '200px'
                        }}></TableCell>
                      </TableRow>
                    </TableHead>
                    {positions?.length ?
                      <TableBody>
                        {positions?.map((trade) => {
                          return (
                            <TableRow
                              sx={{
                                height: '60px'
                              }}
                              onMouseEnter={() =>
                                handlePositionRowHover(trade?.identifier)
                              }
                              onMouseLeave={handlePositionRowLeave}
                              key={trade._id}>
                              <TableCell sx={{
                                color: colors.tableText,
                                fontWeight: 400,
                                fontSize: 12,
                              }} >{`${trade.instrument}.x`}</TableCell>
                              <TableCell sx={{
                                color: getTradeTypeColor(trade?.type),
                                fontWeight: 400,
                                fontSize: 12,
                              }} >{trade.type === 1 ? 'BUY' : 'SELL'}</TableCell>
                              <TableCell sx={{
                                color: colors.tableText,
                                fontWeight: 400,
                                fontSize: 12,
                              }} >{getQuantity(trade)}</TableCell>
                              <TableCell sx={{
                                color: colors.tableText,
                                fontWeight: 400,
                                fontSize: 12,
                              }} >{trade.average}</TableCell>
                              <TableCell sx={{
                                color: colors.tableText,
                                fontWeight: 400,
                                fontSize: 12,
                              }} >{trade?.lastTradePrice}</TableCell>
                              <TableCell sx={{
                                color: trade?.profitLoss > 0 ? colors.green : '#FF4757',
                                fontWeight: 400,
                                fontSize: 12,
                              }}
                              >{trade?.profitLoss > 0 ? `+${formatPrice(trade?.profitLoss)}` : `${formatPrice(trade?.profitLoss)}`}</TableCell>
                              <TableCell sx={{
                                color: colors.tableText,
                                alignItems: 'center',
                                fontWeight: 400,
                                fontSize: 12,
                              }}
                              >
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}>
                                  {trade?.stopLossPrice ?? 0}
                                  <IconButton
                                    sx={{
                                      marginLeft: '3px',
                                      background: '#41AAAF1A',
                                      borderRadius: 2,
                                    }}
                                    onClick={() => handleEditTargetStopLoss(trade)}
                                    disableRipple
                                  >
                                    <img style={{
                                      width: 16
                                    }} alt='img' src={EditImage} />
                                  </IconButton>
                                </div>
                              </TableCell>
                              <TableCell sx={{
                              }}>
                                {hoveredPositionRow === trade?.identifier ?
                                  <div style={{
                                    display: 'flex'
                                  }}>
                                    <Button
                                      size="small"
                                      onClick={() => handlePositionExit(trade)}
                                      sx={{
                                        background: '#FF4757',
                                        color: '#ffffff',
                                        '&:hover': {
                                          backgroundColor: '#FF4757',
                                          color: '#ffffff'
                                        },
                                      }} type='outlined' >Exit</Button>
                                    <Button
                                      size="small"
                                      onClick={() => handlePositionAdd(trade)}
                                      sx={{
                                        background: colors.teal,
                                        color: '#ffffff',
                                        marginLeft: 2,
                                        '&:hover': {
                                          backgroundColor: colors.teal,
                                          color: '#ffffff'

                                        },
                                        '&:disabled': {
                                          backgroundColor: 'gray',
                                          color: '#ffffff'
                                        },
                                      }} type='outlined' >Add</Button>
                                  </div>
                                  : <div>

                                  </div>
                                }
                              </TableCell>
                              
                            </TableRow>
                          );
                        })}

                      </TableBody> : <TableBody>
                        <TableRow>
                          <TableCell sx={{
                            fontSize: 15,
                            textAlign: 'center'
                          }} colSpan={9}>No Positions</TableCell>

                        </TableRow>
                      </TableBody>
                    }
                </Table>
                <BuySell
                  tab={buySellTabValue}
                  handleBuySellClose={handleBuySellClose}
                  openBuySellDialog={openBuySellDialog}
                  handleBuy={handleBuy}
                  handleQuantityChange={handleQuantityChange}
                  handleTabChange={handleBuySellTabChange}
                  handleSell={handleSell}
                  instrumentName={instrumentName}
                  quantity={quantity}
                  buySellDialogMode={buySellDialogMode}
                  price={price}
                  priceInfo={priceInfo}
                  orderMode={orderMode}
                  availableMargin={selectedAccount?.credits?.cashBalance}
                  lastTradePrice={lastTradePrice}
                  tradeId={tradeId}
                  handleStopLossPriceChange={handleStopLossPriceChange}
                  stopLossPrice={stopLossPrice}
                  handlePriceChange={handlePriceChange}
                  orderType={orderType}
                  handleOrderTypeChange={handleOrderTypeChange}
                  priceDisabled={priceDisabled}
                  handleUpdatePosition={handleUpdatePosition}
                  existingOpenOrderPrice={existingOpenOrderPrice}
                />
                </TableContainer>
              </TabPanel>
              <TabPanel value="openorder">
                <TableContainer  >
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: theme.palette.action.hover,
                        },
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}>
                        <TableCell sx={{
                          color: colors.tableText,
                          fontWeight: 500,
                          fontSize: 12,
                        }}>Order ID</TableCell>

                        <TableCell sx={{
                          color: colors.tableText,
                          fontWeight: 500,
                          fontSize: 12,
                        }} ><TableSortLabel
                          active={sortBy === 'type'}
                          direction={sortBy === 'type' ? sortDirection : 'asc'}
                          onClick={() => handleSort('type')}
                        >
                            Type
                          </TableSortLabel></TableCell>
                        <TableCell sx={{
                          color: colors.tableText,
                          fontWeight: 500,
                          fontSize: 12,
                        }} ><TableSortLabel
                          active={sortBy === 'instrument'}
                          direction={sortBy === 'instrument' ? sortDirection : 'asc'}
                          onClick={() => handleSort('instrument')}
                        >
                            Symbol
                          </TableSortLabel></TableCell>
                        <TableCell sx={{
                          color: colors.tableText,
                          fontWeight: 500,
                          fontSize: 12,
                        }} >
                          <TableSortLabel
                            active={sortBy === 'quantity'}
                            direction={sortBy === 'quantity' ? sortDirection : 'asc'}
                            onClick={() => handleSort('quantity')}
                          >
                            Quantity
                          </TableSortLabel>
                        </TableCell>
                        <TableCell  sx={{
                          color: colors.tableText,
                        fontWeight: 500,
                          fontSize: 12,
                        }} > <TableSortLabel
                          active={sortBy === 'price'}
                          direction={sortBy === 'price' ? sortDirection : 'asc'}
                          onClick={() => handleSort('price')}
                        >
                            Limit Price
                          </TableSortLabel></TableCell>
                        <TableCell sx={{
                          color: colors.tableText,
                          fontWeight: 500,
                          fontSize: 12,
                        }}>
                          SL
                        </TableCell>
                        <TableCell sx={{
                          color: colors.tableText,
                          fontWeight: 500,
                          fontSize: 12,
                        }} > <TableSortLabel
                          active={sortBy === 'lastTradePrice'}
                          direction={sortBy === 'lastTradePrice' ? sortDirection : 'asc'}
                          onClick={() => handleSort('lastTradePrice')}
                        >
                            LTP
                          </TableSortLabel></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    {openOrders?.length ?
                      <TableBody>
                        {openOrders?.map((order) => {
                          return (
                            <TableRow
                              sx={{
                                height: 30
                              }}
                              onMouseEnter={() =>
                                handleOrderRowHover(order?.identifier)
                              }
                              onMouseLeave={handleOrderRowLeave}
                              key={order._id}>
                              <TableCell sx={{
                                color: colors.tableText,
                                fontWeight: 400,
                                fontSize: 12,
                              }} >{order.identifier}</TableCell>
                              <TableCell sx={{
                                color: colors.tableText,
                                fontWeight: 400,
                                fontSize: 12,
                              }}  >{order.type === 1 ? 'BUY' : 'SELL'}</TableCell>
                              <TableCell sx={{
                                color: colors.tableText,
                                fontWeight: 400,
                                fontSize: 12,
                              }} >{`${order.instrument}.x`}</TableCell>
                              <TableCell sx={{
                                color: colors.tableText,
                                fontWeight: 400,
                                fontSize: 12,
                              }} >{order.quantity}</TableCell>
                              <TableCell   sx={{
                                color: order?.type === 1 ? colors.green : '#FF4757',
                                fontWeight: 400,
                                fontSize: 12, 
                              }} >{order.price}</TableCell>
                              <TableCell sx={{
                                fontWeight: 400,
                                fontSize: 12,
                                color: colors.tableText,
                              }} >{order?.stopLossPrice?? 0}</TableCell>
                              <TableCell sx={{
                                color: colors.tableText,
                                fontWeight: 400,
                                fontSize: 12,
                                width: 40
                              }} >{order?.lastTradePrice}</TableCell>
                              <TableCell
                                sx={{
                                  width: 100,
                                  padding: 0
                                }}
                              >
                                {hoveredOrderRow === order?.identifier &&
                                  <div>
                                    <IconButton
                                      sx={{
                                        margin: 0,
                                        background: '#41AAAF1A',
                                        borderRadius: 2,
                                      }}
                                      onClick={() => handleEditOrder(order)}
                                      disableRipple
                                    >
                                      <img style={{
                                        width: 16
                                      }} alt='img' src={EditImage} />
                                    </IconButton>
                                    &nbsp;
                                    <IconButton
                                      disabled={deleteDisabled}
                                      onClick={() => {
                                        handleDeleteOrder(order)
                                      }}
                                      sx={{
                                        margin: 0,
                                        background: '#41AAAF1A',
                                        borderRadius: 2,
                                      }}
                                      disableRipple>
                                      <img style={{
                                        width: 16
                                      }} alt='img' src={DeleteImage} />

                                    </IconButton>
                                  </div>
                                }
                               
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody> : <TableBody>
                        <TableRow>
                          <TableCell sx={{
                            fontSize: 15,
                            textAlign: 'center'
                          }} colSpan={9}>No Orders</TableCell>

                        </TableRow>
                      </TableBody>}
                </Table>
                <BuySell
                  tab={buySellTabValue}
                  handleBuySellClose={handleBuySellClose}
                  openBuySellDialog={openBuySellDialog}
                  handleBuy={handleBuy}
                  handleQuantityChange={handleQuantityChange}
                  handleTabChange={handleBuySellTabChange}
                  handleSell={handleSell}
                  instrumentName={instrumentName}
                  quantity={quantity}
                  buySellDialogMode={buySellDialogMode}
                  price={price}
                  orderMode={orderMode}
                  priceInfo={priceInfo}
                  availableMargin={selectedAccount?.credits?.cashBalance}
                  lastTradePrice={lastTradePrice}
                  tradeId={tradeId}
                  handleStopLossPriceChange={handleStopLossPriceChange}
                  stopLossPrice={stopLossPrice}
                  handlePriceChange={handlePriceChange}
                  orderType={orderType}
                  handleOrderTypeChange={handleOrderTypeChange}
                  priceDisabled={priceDisabled}
                  handleUpdatePosition={handleUpdatePosition}
                  existingOpenOrderPrice={existingOpenOrderPrice}

                />
                </TableContainer>
              </TabPanel>
            </TabContext>
        </Grid>
        </div>
    </>
  );
};

export default Orders;
