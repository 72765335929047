import React, { useEffect, useState } from 'react';

import { Box, Button, Autocomplete, FormControl, InputLabel, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { Search as SearchIcon } from '@mui/icons-material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, getUsers, updateUser, getAdminPositions } from '../../../state/actions/admin.actions';
import AdminTopBar from '../../global/AdminTopbar';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useNavigate } from 'react-router-dom';

import DeleteImage from '../../../assets/delete.png';
import EditImage from '../../../assets/edit.png';
import { Transition } from '../../../components/Transition';
import { formatPrice } from '../../../utils/string';

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Positions = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const _getPositions = () => {
    dispatch(getAdminPositions({
      page: page + 1, limit: rowsPerPage, status: '1,6', position: 1, expand: 'account,user', pl: true, userId: user?._id, accountId: account?._id
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [positions, setPositions] = useState([]);
  const [searchText, setSearchText] = useState('');


  const dispatch = useDispatch();
  const positionTradesState = useSelector((state) => state.admin?.positionTrades);
  const usersState = useSelector((state) => state.admin?.users);


  useEffect(() => {
    dispatch(getUsers({
      page: 1, limit: 100, roles: 'CUSTOMER'
    }));
  }, []);



  
  useEffect(() => {
    if (positionTradesState?.positions?.length) {
      setPositions(positionTradesState);
    } else {
      setPositions([]);
    }
  }, [positionTradesState]);


  useEffect(() => {
    if (usersState?.users?.length) {
      setUsers(usersState);
    } else {
      setUsers([]);
    }
  }, [usersState]);
  useEffect(() => {
    _getPositions(page + 1, rowsPerPage);
  }, [page, rowsPerPage, account, user]);



  const handlePositionViewOpen = (row) => {
    navigate('/admin-position-view', {
      state: {
        positionData: {
          ...row,
        }
      }
    });
  };

  function getProfitTargetValue(account) {
    return (account?.profitTarget / 100) * account?.amount;
  }

  function getRemainingDays(account) {
    if (!account?.meta?.firstTraded) {
      return 'NA';
    }
    const currentDate = dayjs.utc().startOf('day');
    const remainingDays = dayjs.utc(account?.meta?.firstTraded).startOf('day').add(account?.validityDays, 'day').diff(currentDate, 'day');
    return remainingDays;
  }

  function handleAccountChange(event) {
    setAccount(event?.target?.value);
  }

  return (
    <>
      <AdminTopBar />
      <Grid container p={3}>

        <Grid sx={{
          display: {
            sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
          },
          alignItems: 'center',
          justifyContent: 'space-between'
        }} item xs={12} md={12} lg={12} sm={12} mb={2}>
          <div>
            <Typography sx={{
              fontSize: 18,
              color: '#656565',
              mt: 2,
              ml: 1,
              fontWeight: 600
            }}>Positions</Typography>
          </div>
          <Box sx={{
            display: {
              sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
            },
            alignItems: 'center'
          }}>
            <Autocomplete
              sx={{
                width: 300,
                mr: 3
              }}
              options={users?.users}
              onChange={(event, newValue) => {
                console.log('newValue: ', newValue);
                if (!newValue) {
                  setUser(newValue);
                  setAccount(newValue);
                } else {
                  setUser(newValue);
                }
              }}
              getOptionLabel={(option) => option.email}
              getOptionSelected={(option, value) => option._id === value._id}
              onInputChange={(event, value) => setSearchText(value)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="User" />
              )}
            />

            <FormControl>
              <InputLabel id="demo-simple-select-label">Account</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label='Account'
                sx={{
                  width: 200,
                }}
                onChange={handleAccountChange}
              >{!user?.accounts?.length ? (
                  <MenuItem disabled>No User Selected</MenuItem>
                ) : user?.accounts?.map((account) => {
                  return (
                    < MenuItem key={account?._id} value={account
                    }> {account?.accountNumber}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <TableContainer sx={{
            boxShadow: '0px 4px 12px 8px rgba(0, 0, 0, 0.1)'
          }} component={Paper}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                    pl: 3,
                    color: '#747D8C'
                  }}>Account Number</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Username</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Plan Type</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Account Size</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>1st Traded Date</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Days Remaining</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Profit Target</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Current Profit</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Action</TableCell>
                </TableRow>
              </TableHead>
              {positions?.positions?.length ? <TableBody>
                {positions?.positions?.map((row) => {
                  let profitTarget = getProfitTargetValue(row?.account);
                  let remainingDays = getRemainingDays(row?.account);
                  return (
                    <TableRow key={row._id}>
                      <TableCell width={180} sx={{
                        pl: 3,
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{row?.account?.accountNumber}</TableCell>
                      <TableCell width={180} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{row?.user?.username}</TableCell>
                      <TableCell width={180} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{row.account?.accountType?.label}</TableCell>
                      <TableCell width={180} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{row?.account?.accountSize?.label}</TableCell>
                      <TableCell width={180} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{dayjs(row?.account?.meta?.firstTraded).format('DD MMM YYYY')}</TableCell>
                      <TableCell width={180} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{remainingDays}</TableCell>
                      <TableCell width={180} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{formatPrice(profitTarget)}</TableCell>
                      <TableCell width={180} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{formatPrice(row?.profitLoss ?? 0)}</TableCell>
                      <TableCell width={180} >
                        <IconButton size='small' onClick={() => handlePositionViewOpen(row)} sx={{
                          background: '#60C68929',
                          borderRadius: 2,
                        }}>
                          <VisibilityOutlinedIcon fontSize='small' />
                        </IconButton></TableCell>



                    </TableRow>
                  );
                })} </TableBody>
                :
                <TableBody>
                  <TableRow>
                    <TableCell sx={{
                      fontSize: 13,
                      textAlign: 'center'
                    }} colSpan={9}>No Positions Found</TableCell>

                  </TableRow>
                </TableBody>
              }

            </Table>
          </TableContainer>
          {positions?.meta && <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={positions.meta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page:"
            nextIconButtonText="Next Page"
            backIconButtonText="Previous Page"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
            showFirstButton
            showLastButton

          />}
        </Grid>
      </Grid>
    </>
  );
};

export default Positions;
