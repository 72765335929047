


import {
  ButtonBase,
  Grid,
  Divider,
  Typography,
  useTheme,
  Dialog,
  Button,
  IconButton,
  TextField,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { formatPrice } from '../../../utils/string';
import { S3_BASE_URL } from '../../../config';
import { downloadFile } from '../../../services/fn';

import React, { useEffect, useState, useRef } from 'react';
import AccountCardImage from '../../../assets/acc-card.png';
import { tokens } from '../../../theme';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useDispatch, useSelector } from 'react-redux';
import { validatePayoutOTP } from '../../../state/actions/user.actions';

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const AccountHistoryDialog = ({
  accountHistoryDialog,
  handleAccountHistoryDialogClose,
  payout,
}) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');


  function getPayoutStatusColor(status) {
    switch (status) {
    case 1:
      return '#FFC107';
    case 2:
      return colors.green;
    case 3:
      return '#FF4757';
    default:
      return '#000000';
    }
  }
    
  function getPayoutStatus(status, prefix = '', suffix = '') {
    switch (status) {
    case 1:
      return `${prefix}Requested${suffix}`;
    case 2:
      return `${prefix}Processed${suffix}`;
    case 3:
      return `${prefix}Rejected${suffix}`;
    default:
      return '';
    }
  }

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={accountHistoryDialog}
      onClose={handleAccountHistoryDialogClose}
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{
            fontWeight: 700,
            fontSize: 16
          }}>{'Account History'}</span>
          <IconButton onClick={handleAccountHistoryDialogClose} edge="end" color="inherit" aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <Grid>
        <Divider />
      </Grid>
      <DialogContent>
        <Grid container>
          <Grid item md={3} xs={6} sx={{
            paddingBottom: 2
          }}>
           
            <Typography sx={{
              fontSize: 12,
              
              paddingBottom: 1,
            }}>Account Number</Typography>
            <Typography sx={{
              fontSize: 13,
              fontWeight: 600
            }}>{payout?.account?.accountNumber}</Typography>
          </Grid>
          <Grid item md={3} xs={6} sx={{
            paddingBottom: 2
          }}>
            <Typography sx={{
              fontSize: 12,
              
              paddingBottom: 1,
            }}>Account Type</Typography>
            <Typography sx={{
              fontSize: 13,
              fontWeight: 600
            }}>{payout?.account?.accountAlias}</Typography>
          </Grid>
                  
          <Grid item md={3} xs={6} sx={{
            paddingBottom: 2
          }}>
            <Typography sx={{
              fontSize: 12,
              
              paddingBottom: 1,
            }}>Account Size</Typography>
            <Typography sx={{
              fontSize: 13,
              fontWeight: 600,
            }}>{formatPrice(payout?.account?.accountSize?.value)}</Typography>
          </Grid>
          <Grid item md={3} xs={6} sx={{
            paddingBottom: 2
          }}>
            <Typography sx={{
              fontSize: 12,
              
              paddingBottom: 1,
            }}>Payout Amount</Typography>
            <Typography sx={{
              fontSize: 13,
              fontWeight: 600,
            }}>{formatPrice(payout?.account?.credit?.cashBalance)}</Typography>
          </Grid>
          <Grid item md={12}>
            <Divider/>
          </Grid>
          <Grid item md={3} xs={6} sx={{
            paddingTop: 1
          }}>
            <Typography sx={{
              fontSize: 12,
              
              paddingBottom: 1,
            }}>Requested Date</Typography>
            <Typography sx={{
              fontSize: 13,
              fontWeight: 600
            }}>{dayjs(payout?.createdAt).format('DD-MM-YYYY')}</Typography>
          </Grid>
          <Grid item md={3}  xs={6} sx={{
            paddingTop: 1

          }}>
            <Typography sx={{
              fontSize: 12,
              
              paddingBottom: 1,
            }}>Approved Date</Typography>
            <Typography sx={{
              fontSize: 13,
              fontWeight: 600,
            }}>{dayjs(payout?.createdAt).format('DD-MM-YYYY')}</Typography>
          </Grid>
        <Grid item md={3} xs={6} sx={{
            paddingTop: 1

          }}>
            <Typography sx={{
              fontSize: 12,
              
              paddingBottom: 1,
            }}>Status</Typography>
            <Typography sx={{
              fontSize: 13,
              fontWeight: 600,
              color: getPayoutStatusColor(payout?.status)
            }}>{getPayoutStatus(payout?.status)}</Typography>
          </Grid>
          
          <Grid item md={3} xs={6} sx={{
            paddingTop: 1
          }}>
          </Grid>
                  
          <Grid item md={12}  sx={{
            pt:2
          }}>
            <Divider />
          </Grid>

          <Grid md={12} xs={6} item>
            <Typography sx={{
              fontSize: 13,
              fontWeight: 700,
              pt: 1,
              pb: 1,
            }} >Remarks</Typography>
            <Typography>{payout?.remarks?.length ? payout?.remarks : 'NA'}</Typography>
          </Grid>

          <Grid md={2} xs={6} item>
          </Grid>

          <Grid item md={3} xs={12} sx={{
            textAlign: 'center',
            pb: 3,
            pt: 3,

          }}>
            <Button
              fullWidth
              disabled={payout?.paymentReceiptFile?.length ? false : true}
              onClick={() => downloadFile(`${S3_BASE_URL}/${payout?.paymentReceiptFile}`, payout?.paymentReceiptFile)}
              sx={{
                background: '#41AAAF',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#41AAAF',
                  color: '#ffffff'
                },
              }}  variant='contained'>Download Certificate </Button>
          </Grid>
          <Grid item md={2}>

          </Grid>
          <Grid item md={3} xs={12} sx={{
            textAlign: 'center',
            pb: 3,
            pt: 3,

          }}>
            <Button
              fullWidth
              disabled={payout?.certificateFile?.length ? false : true}
              onClick={() => downloadFile(`${S3_BASE_URL}/${payout?.certificateFile}`, payout?.certificateFile)}
              sx={{
                background: '#41AAAF',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#41AAAF',
                  color: '#ffffff'
                },
              }}  variant='contained'>Download Payment Proof </Button>
          </Grid>

          <Grid md={2} xs={6} item>
          </Grid>
        </Grid>


      </DialogContent>
    </Dialog>
  );
};

export default AccountHistoryDialog;