import { Grid, Paper, Typography, useTheme } from '@mui/material';
import CurrentBalImage from '../../../assets/current_bal.png';
import OpeningBalImage from '../../../assets/opening_bal.png';
import TotalImage from '../../../assets/total.png';
import AreaChartComponent from '../../../charts/Area';
import { tokens } from '../../../theme';
import { formatPrice } from '../../../utils/string';
import { useEffect, useState } from 'react';

const TargetSection = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { account, user, currentPositionAndOpenOrderBalance, cashBalance } = props;
  const [overallCurrentBalance, setOverallCurrentBalance] = useState(0);

  useEffect(() => {
    if (isNaN(currentPositionAndOpenOrderBalance)) {
      setOverallCurrentBalance(Number(cashBalance));
    } else {
      setOverallCurrentBalance(Number(cashBalance + currentPositionAndOpenOrderBalance));
    }
  }, [currentPositionAndOpenOrderBalance, cashBalance]);

  return (
    <Grid style={{ backgroundColor: colors.dashboard.background }} container spacing={2}>
      <Grid style={{
        paddingRight: 16
      }} item xs={12} sm={12} md={6}  >
        <Paper style={{ height: '100%', display: 'flex', borderRadius: '10px', padding: '14px', paddingTop: '25px', paddingBottom: '25px', backgroundColor: colors.dashboardCards.background, boxShadow: '0px 1px 5px 0px #00000029' }}>
          <Grid container>
            <Grid item xs={3} sm={3} md={2.5} lg={2.5} style={{
              alignItems: 'center',
              display: 'flex'
            }}>
              <img
                style={{
                  width: '60px',
                  height: '60px',
                }}
                alt="opening-bal"
                src={OpeningBalImage}></img>
            </Grid>
            <Grid item xs={9} sm={9} md={9.5} lg={9.5}>
              <Typography style={{
                
              }}>
                Opening Balance
              </Typography>
              <Typography style={{
                fontSize: '24px',
                fontWeight: 600
              }}>{formatPrice(account?.credits?.openingBalance ?? 0)}</Typography>
            </Grid>
         </Grid>
        </Paper>
      </Grid>


      <Grid style={{
        paddingRight: 16
      }} item xs={12} sm={12} md={6}  >
        <Paper style={{ height: '100%', display: 'flex', borderRadius: '10px', padding: '14px', paddingTop: '25px', paddingBottom: '25px', backgroundColor: colors.dashboardCards.background, boxShadow: '0px 1px 5px 0px #00000029' }}>
          <Grid container>
            <Grid item xs={3} sm={3} md={2.5} lg={2.5} style={{
              alignItems: 'center',
              display: 'flex'
            }}>
              <img
                style={{
                  width: '60px',
                  height: '60px',
                }}
                alt="current-bal"
                src={CurrentBalImage}></img>
            </Grid>
            <Grid item xs={9} sm={9} md={9.5} lg={9.5}>
              <Typography style={{
              }}>
                Current Balance
              </Typography>
              <Typography style={{
                fontSize: '24px',
                fontWeight: 600
              }}>{formatPrice(overallCurrentBalance)}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

    </Grid>
  );
};

export default TargetSection;