import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';


export function Notification() {

  let notification = useSelector(
    (state) => state.notification);

  let { open, message } = notification;

  const [notiOpen, setNotiOpen] = useState(open);

  useEffect(() => {
    setNotiOpen(true);
  }, [notification]);

  if (!message) {
    return null;
  }
   

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={notiOpen}
      autoHideDuration={2000}
      onClose={() => setNotiOpen(false)}
      message={message}
      action={
        <React.Fragment>
          <Button
            color="secondary"
            size="small"
            onClick={() => setNotiOpen(false)}
          >
                        Close
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setNotiOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
}