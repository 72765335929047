import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { format, isEqual, isWeekend, parse } from "date-fns";
import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css"; 
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button"; 
import Box from "@mui/material/Box"; 

function DateRangePickerPopup({ startDate, endDate, onChange, holidays, closed }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDate, setShowDate] = useState("");
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: startDate ? new Date(startDate) : new Date(),
      endDate: endDate ? new Date(endDate) : new Date(),
      key: "selection",
    },
  ]);
  const [publicHolidays, setPublicHolidays] = useState([]);

  useEffect(() => {
    setPublicHolidays(
      holidays?.map((el) => parse(el || "", "dd/MM/yyyy", new Date())) || []
    );
  }, []);

  useEffect(() => {
    setSelectedRange([
      {
        startDate: startDate ? new Date(startDate) : new Date(),
        endDate: endDate ? new Date(endDate) : new Date(),
        key: "selection",
      },
    ]);
    const formattedDate = `${format(new Date(startDate), "dd/MM/yyyy")} - ${format(
      new Date(endDate),
      "dd/MM/yyyy"
    )}`;
    setShowDate(formattedDate);
  }, [startDate, endDate]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    closed();
  };

  const setSelectedDateRange = (item) => {
    const { startDate, endDate } = item.selection;
    const formattedDate = `${format(startDate, "dd/MM/yyyy")} - ${format(
      endDate,
      "dd/MM/yyyy"
    )}`;
    setShowDate(formattedDate);
    setSelectedRange([item.selection]);
    onChange ? onChange(item.selection) : null;
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const customDayContent = (day) => {
    return (
      <div>
        <span
          style={
            isWeekend(day) ||
            publicHolidays.some((holiday) => isEqual(holiday, day))
              ? { color: "red" }
              : null
          }
        >
          {format(day, "d")}
        </span>
      </div>
    );
  };

  return (
    <div>
      <TextField
        sx={{
          width: '150%'
        }}
        placeholder="DD/MM/YYYY - DD/MM/YYYY"
        className="w-100"
        value={showDate}
        onClick={handleClick}
        readOnly
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <DateRange
          onChange={(item) => setSelectedDateRange(item)}
          ranges={selectedRange}
          direction="horizontal"
          dayContentRenderer={customDayContent}
        />
        <Box sx={{ textAlign: 'center', paddingBottom: '10px'  }}>
          <Button variant="outlined" onClick={handleClose} color="primary" aria-label="close">
            Apply
          </Button>
        </Box>
      </Popover>
    </div>
  );
}

export default DateRangePickerPopup;
