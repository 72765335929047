import React, { useEffect, useState } from 'react';

import { Box, Breadcrumbs, TableSortLabel, Button, Grid, IconButton, InputAdornment, Link, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Search as SearchIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAccounts, updateAccount, updateAccountCredit } from '../../../state/actions/admin.actions';
import AdminTopBar from '../../global/AdminTopbar';
import { S3_BASE_URL, REST_URL } from '../../../config';
import axios from 'axios';
import CalculateIcon from '@mui/icons-material/Calculate';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import _ from 'lodash';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';

import DeleteImage from '../../../assets/delete.png';
import EditImage from '../../../assets/edit.png';
import { formatPrice } from '../../../utils/string';
import AccountsImportDialog from './Elements/AccountsImportDialog';
import {
  ACCOUNT_STATUS
} from '../../../config';
const dayjs = require('dayjs');


function getDaysRemaining(date, days) {
  const currentDate = dayjs();
  const remainingDays = dayjs(date).add(days, 'day').diff(currentDate, 'day');
  return `${remainingDays > 0 ? remainingDays : 0} Days`;
}

const Accounts = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [adjustedAmount, setAdjustedAmount] = useState(0);
  const [sortBy, setSortBy] = useState(null);
  const [account, setAccount] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [accountImportDialog, setAccountImportDialog] = useState(false);
  const [adjustedAmountDialog, setAdjustedAmountDialog] = useState(false);


  function handleSort(property) {
    const direction = sortBy === property && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortBy(property);
    setSortDirection(direction);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const _getAccounts = () => {
    let params = {};
    if (searchText?.length >= 2) {
      params.q = searchText;
    }

    if (sortBy && sortDirection) {
      params.sort = `${sortBy}|${sortDirection}`;
    }
    dispatch(getAccounts({
      page: page + 1, limit: rowsPerPage, status: '1,2,3,4,5,6,7', ...params, pl: 1
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    _getAccounts();
  };

  function getAccountStatus(status) {
    switch (status) {
      case ACCOUNT_STATUS.COMPLETED:
        return 'Completed';
      case ACCOUNT_STATUS.INACTIVE:
        return 'In Active';
      case ACCOUNT_STATUS.LIQUIDATED:
        return 'Liquidated';
      case ACCOUNT_STATUS.RESET:
        return 'Reset';
      case ACCOUNT_STATUS.PENDING:
        return 'Pending';
      case ACCOUNT_STATUS.ACTIVE:
        return 'Active';
      default:
        return 'In Active';
    }
  }

  function getAccountStatusColor(status) {
    return status === 1 ? '#39D66A' : status === 2 ? '#FFC339'
      : status === 3 ? 'red' : status === 4 ? '#FFC339' : 'NA';
  }

  const [accounts, setAccounts] = useState([]);
  const [searchText, setSearchText] = useState('');

  const [deleteConfirmationAnchorEl, setDeleteConfirmationAnchorEl] = React.useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const accountsState = useSelector((state) => state.admin?.accounts);
  const admin = useSelector((state) => state.admin);
  const updatedAccount = useSelector((state) => state.admin?.updatedAccount);

  useEffect(() => {
    if (accountsState?.accounts?.length) {
      setAccounts(accountsState);
    } else {
      setAccounts([]);
    }
  }, [accountsState]);

  useEffect(() => {
    _getAccounts();
  }, [page, rowsPerPage, searchText, sortBy, sortDirection, accountImportDialog]);


  useEffect(() => {
    setAdjustedAmountDialog(false);
    _getAccounts();
  }, [updatedAccount]);


  const handleAddAccountForm = () => {
    navigate('/admin-account', {
      state: {
        editing: false,
        accountNumber: `100${String(Date.now()).substring(3, 10)}`
      }
    });
  };


  const handleAccountEditOpen = (row) => {
    navigate('/admin-account', {
      state: {
        editing: true,
        accountData: row
      }
    });
  };

  const handleAccountViewOpen = (row) => {
    navigate('/admin-account-view', {
      state: {
        accountData: row
      }
    });
  };

  const handleClickAdjustedAmount = (row) => {
    setAccount(row);
    setAdjustedAmountDialog(true);
  }

  const handleAccountDeleteOpen = (event, row) => {
    setDeleteConfirmationAnchorEl(event.currentTarget);
    setAccount(row);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationAnchorEl(null);
  };

  const handleAdjustedAmountDialogClose = () => {
    setAdjustedAmountDialog(false);
  }

  const handleDeleteAccount = () => {
    let data = {
      ...account, active: false, deleted: true
    };
    dispatch(updateAccount(_.pick(data, [
      'accountNumber',
      'userId',
      'username',
      'plan',
      'planAlias',
      'accountType',
      'accountAlias',
      'accountSize',
      'amount',
      'dll',
      'tll',
      'profitTarget',
      'tradeSquareOff',
      'validityDays',
      'active',
      'deleted',
      '_id'
    ])));
    setDeleteConfirmationAnchorEl(null);
  };

  const handleUpdateAdjustedAmount = () => {
    dispatch(updateAccountCredit({
      _id: account?._id,
      adjustedAmount
    }));
  };

  const open = Boolean(deleteConfirmationAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/admin-accounts">
      <Typography sx={{
        fontSize: 17,
        fontWeight: 600
      }}>Accounts</Typography>
    </Link>
  ];


  function handleAccountImportDialogClose(event) {
    setAccountImportDialog(false);
  }

  function handleImportAccounts() {
    setAccountImportDialog(true);
  }

  async function downloadAccounts() {
    try {
      const accessToken = localStorage.getItem('token');
      const response = await axios.get(`${REST_URL}/users/accounts/download`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'UserAccounts.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading XLSX file:', error);
    }
  }

  return (
    <>
      <AdminTopBar />
      <Grid container p={3}>

        <Grid sx={{
          display: {
            sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
          },
          alignItems: 'center',
          justifyContent: 'space-between'
        }} item xs={12} md={12} lg={12} sm={12} mb={2}>
          <div>
            <Breadcrumbs sx={{
              pl: 1, pt: 1
            }} separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
          <Box sx={{
            display: {
              sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
            },
            alignItems: 'center'
          }}>
            <TextField
              variant="outlined"
              sx={{
                width: { md: 300, lg: 300, sm: '100%', xs: '100%' },
                pb: { xs: 2, sm: 2, md: 0, lg: 0 },
              }}
              value={searchText}
              onChange={handleSearchChange}
              fullWidth
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{
                height: 35,
                width: { md: 150, lg: 150, sm: '75%', xs: '75%' },
                marginLeft: 5,
                backgroundColor: '#41AAAF',
                '&:hover': {
                  backgroundColor: '#41AAAF',
                },
              }}
              fullWidth
              onClick={handleAddAccountForm}
              variant="contained" >
              New
            </Button>

            <Button
              sx={{
                height: 35,
                width: { md: 150, lg: 150, sm: '75%', xs: '75%' },
                marginLeft: 5,
                backgroundColor: '#41AAAF',
                '&:hover': {
                  backgroundColor: '#41AAAF',
                },
              }}
              fullWidth
              onClick={downloadAccounts}
              startIcon={<DownloadIcon />}
              variant="contained" >
              Download
            </Button>
            <Button
              sx={{
                height: 35,
                width: { md: 150, lg: 150, sm: '75%', xs: '75%' },
                marginLeft: 5,
                backgroundColor: '#41AAAF',
                '&:hover': {
                  backgroundColor: '#41AAAF',
                },
              }}
              component="label"
              role={undefined}
              tabIndex={-1}
              fullWidth
              onClick={handleImportAccounts}
              variant="contained"
              startIcon={<CloudUploadIcon />}>
              Import
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <TableContainer sx={{
            boxShadow: '0px 4px 12px 8px rgba(0, 0, 0, 0.1)'
          }} component={Paper}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Account Number</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                    active={sortBy === 'user.username'}
                    direction={sortBy === 'user.username' ? sortDirection : 'asc'}
                    onClick={() => handleSort('user.username')}
                  >
                      Username
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                    active={sortBy === 'accountType.label'}
                    direction={sortBy === 'accountType.label' ? sortDirection : 'asc'}
                    onClick={() => handleSort('accountType.label')}
                  >
                      Plan-Account Type
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                    active={sortBy === 'amount'}
                    direction={sortBy === 'amount' ? sortDirection : 'asc'}
                    onClick={() => handleSort('amount')}
                  >
                      Account Size
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                    active={sortBy === 'meta.firstTraded'}
                    direction={sortBy === 'meta.firstTraded' ? sortDirection : 'asc'}
                    onClick={() => handleSort('meta.firstTraded')}
                  >
                      1st Traded Date
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Days Remaining</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Profit Target</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Current Profit/Loss</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                    active={sortBy === 'status'}
                    direction={sortBy === 'status' ? sortDirection : 'asc'}
                    onClick={() => handleSort('status')}
                  >
                      Status
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Adjusted PL</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Action</TableCell>

                </TableRow>
              </TableHead>
              {accounts?.accounts?.length ? <TableBody>
                {accounts?.accounts?.map((row) => {
                  let currentProfitLoss = 0;
                  return (
                    <TableRow key={row._id}>
                      <TableCell width={200} sx={{
                        fontSize: 13,
                        fontWeight: 500,
                      }}>{row?.accountNumber} </TableCell>
                      <TableCell width={180} sx={{
                        fontSize: 13,
                        fontWeight: 500,
                      }}>{row?.user?.username}</TableCell>
                      <TableCell width={250} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{row?.accountType?.label}</TableCell>
                      <TableCell width={180} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{formatPrice(Number(row?.amount))}</TableCell>
                      <TableCell width={240} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{row?.meta?.firstTraded ? dayjs(row?.meta?.firstTraded).format('YYYY-MM-DD') : 'NA'}</TableCell>
                      <TableCell width={240} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{row?.meta?.firstTraded ? getDaysRemaining(row?.meta?.firstTraded, row?.validityDays) : 'NA'}</TableCell>
                      <TableCell width={240} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{row?.profitTarget}</TableCell>
                      <TableCell width={240} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}>{formatPrice(row?.currentBalance + row?.credits?.cashBalance)}</TableCell>
                      <TableCell width={220} sx={{
                        fontSize: 13,
                        fontWeight: 500,

                      }}> <span style={{
                        color: getAccountStatusColor(row?.status)
                      }}>  &#9679; </span>{getAccountStatus(row?.status)}</TableCell>
                      <TableCell width={230} align='center' style={{
                      }} >
                        <div style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                          <IconButton size='small' onClick={() => handleClickAdjustedAmount(row)} sx={{
                            background: '#60C68929',
                            borderRadius: 2,
                            mr: 2
                          }}>
                            <CalculateIcon />
                          </IconButton>
                          {row?.adjustedAmount ?? 0}


                        </div>

                      </TableCell>
                      <TableCell width={220} align='center' sx={{
                        display: 'flex',
                      }}>

                        <IconButton size='small' onClick={() => handleAccountViewOpen(row)} sx={{
                          background: '#60C68929',
                          borderRadius: 2,
                          mr: 2

                        }}>
                          <VisibilityOutlinedIcon />
                        </IconButton>
                        <IconButton size='small' onClick={() => handleAccountEditOpen(row)} sx={{
                          background: '#41AAAF1A',
                          borderRadius: 2,
                          mr: 2

                        }}>
                          <img alt='img' src={EditImage} />
                        </IconButton>
                        <IconButton size='small' aria-describedby={id} onClick={(event) => handleAccountDeleteOpen(event, row)} sx={{
                          background: '#FF6A761A',
                          borderRadius: 2,
                        }}>
                          <img alt='img' src={DeleteImage} />

                        </IconButton>
                        <Popover
                          sx={{
                            '& .MuiPaper-root': {
                              boxShadow: 'none',
                            },
                          }}
                          id={id}
                          open={open}
                          anchorEl={deleteConfirmationAnchorEl}
                          onClose={handleDeleteConfirmationClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <div style={{
                            margin: 8
                          }}>
                            <Typography sx={{
                              fontWeight: 600,
                              paddingBottom: 1
                            }}>Are you sure?</Typography>
                            <div style={{

                            }}>
                              <Button
                                onClick={() => handleDeleteAccount()}
                                sx={{
                                  color: '#ffffff',
                                  borderColor: 'unset',
                                  marginRight: 2,
                                  background: '#41aaae',
                                  '&:hover': {
                                    backgroundColor: '#41AAAF',
                                  },
                                }} variant="outlined">Confirm</Button>
                              <Button
                                onClick={handleDeleteConfirmationClose}
                                sx={{
                                  color: '#ffffff',
                                  borderColor: 'unset',
                                  background: '#ff6a76',
                                  '&:hover': {
                                    backgroundColor: '#ff6a76',
                                  },
                                }} variant="outlined">Cancel</Button>
                            </div>
                          </div>
                        </Popover>
                      </TableCell>

                    </TableRow>
                  )
                })} </TableBody>
                : <TableBody>
                  <TableRow>
                    <TableCell sx={{
                      fontSize: 13,
                      textAlign: 'center'
                    }} colSpan={9}>No Accounts Found</TableCell>

                  </TableRow>
                </TableBody>
              }


            </Table>
          </TableContainer>
          {accounts?.meta && <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={accounts.meta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
          <AccountsImportDialog
            accountImportDialog={accountImportDialog}
            handleAccountImportDialogClose={handleAccountImportDialogClose}
          />
          <Dialog
            open={adjustedAmountDialog}
            onClose={handleAdjustedAmountDialogClose}
          >
            <DialogTitle style={{
              borderBottom: `1.5px solid #000000`,
              marginBottom: 10
            }}>Adjust Amount for Account: <b>{account?.accountNumber}</b></DialogTitle>
            <DialogContent>
              <DialogContentText>
                The Amount entered will be adjusted against the total balance of the account. <b>Example 1</b>: If A/C Balance is 2,10,000 and entered value is -1,000, then the balance for the account will be 2,09,000 which will be reflected in user's account as well.
                <br/>
                <b>Example 2</b>: If A/C Balance is 2,10,000 and entered value is 2,000, then the balance for the account will be 2,12,000 which will be reflected in user's account as well.
              </DialogContentText>
              <TextField
                style={{
                  marginTop: 40
                }}
                onChange={(event) => setAdjustedAmount(Number(event.target.value))}
                value={adjustedAmount}
                autoFocus
                required
                margin="dense"
                id="name"
                name="adjustedAmount"
                label="Amount"
                type="number"
                fullWidth
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAdjustedAmountDialogClose}>Cancel</Button>
              <Button onClick={handleUpdateAdjustedAmount}>Update</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
};

export default Accounts;
