import React, { useEffect, useState } from 'react';

import { Box, Breadcrumbs, TableSortLabel, Button, Grid, IconButton, InputAdornment, Link, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { Search as SearchIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAccounts, updateAccount } from '../../../state/actions/admin.actions';
import AdminTopBar from '../../global/AdminTopbar';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import _ from 'lodash';
import DeleteImage from '../../../assets/delete.png';
import EditImage from '../../../assets/edit.png';
import { formatPrice } from '../../../utils/string';
import {
  ACCOUNT_STATUS
} from '../../../config';
const dayjs = require('dayjs');


function getDaysRemaining(date, days) {
  const currentDate = dayjs();
  const remainingDays = dayjs(date).add(days, 'day').diff(currentDate, 'day');
  return `${remainingDays > 0 ? remainingDays : 0} Days`;
}

const AccountsTable = ({
  accounts
}) => {

  const [deleteConfirmationAnchorEl, setDeleteConfirmationAnchorEl] = React.useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const navigate = useNavigate();

  function handleSort(property) {
    const direction = sortBy === property && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortBy(property);
    setSortDirection(direction);
  }

  function getAccountStatusColor(status) {
    return status === 1 ? '#39D66A' : status === 2 ? '#FFC339'
      : status === 3 ? 'red' : status === 4 ? '#FFC339' : 'NA';
  }

  function getAccountStatus(status) {
    switch (status) {
      case ACCOUNT_STATUS.COMPLETED:
        return 'Completed';
      case ACCOUNT_STATUS.INACTIVE:
        return 'In Active';
      case ACCOUNT_STATUS.LIQUIDATED:
        return 'Liquidated';
      case ACCOUNT_STATUS.RESET:
        return 'Reset';
      case ACCOUNT_STATUS.PENDING:
        return 'Pending';
      case ACCOUNT_STATUS.ACTIVE:
        return 'Active';
      default:
        return 'In Active';
    }
  }

  const handleAddAccountForm = () => {
    navigate('/admin-account', {
      state: {
        editing: false,
        accountNumber: `100${String(Date.now()).substring(3, 10)}`
      }
    });
  };


  const handleAccountEditOpen = (row) => {
    navigate('/admin-account', {
      state: {
        editing: true,
        accountData: row
      }
    });
  };

  const handleAccountViewOpen = (row) => {
    navigate('/admin-account-view', {
      state: {
        accountData: row
      }
    });
  };

  const handleAccountDeleteOpen = (event, row) => {
    setDeleteConfirmationAnchorEl(event.currentTarget);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationAnchorEl(null);
  };

  const handleDeleteAccount = (row) => {
    let data = {
      ...row, active: false, deleted: true
    };
    dispatch(updateAccount(_.pick(data, [
      'accountNumber',
      'userId',
      'username',
      'plan',
      'planAlias',
      'accountType',
      'accountAlias',
      'accountSize',
      'amount',
      'dll',
      'tll',
      'profitTarget',
      'tradeSquareOff',
      'validityDays',
      'active',
      'deleted',
      '_id'
    ])));
    setDeleteConfirmationAnchorEl(null);
  };

  const open = Boolean(deleteConfirmationAnchorEl);
  const deleteOpenId = open ? 'simple-popover' : undefined;
  

  return (
   
          <TableContainer sx={{
            boxShadow: '0px 4px 12px 8px rgba(0, 0, 0, 0.1)'
          }} component={Paper}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Account Number</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                      active={sortBy === 'user.username'}
                    direction={sortBy === 'user.username' ? sortDirection : 'asc'}
                      onClick={() => handleSort('user.username')}
                  >
                      Username
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                    active={sortBy === 'accountType.label'}
                    direction={sortBy === 'accountType.label' ? sortDirection : 'asc'}
                    onClick={() => handleSort('accountType.label')}
                  >
                      Plan-Account Type
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                      active={sortBy === 'amount'}
                      direction={sortBy === 'amount' ? sortDirection : 'asc'}
                      onClick={() => handleSort('amount')}
                  >
                      Account Size
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                      active={sortBy === 'meta.firstTraded'}
                      direction={sortBy === 'meta.firstTraded' ? sortDirection : 'asc'}
                      onClick={() => handleSort('meta.firstTraded')}
                  >
                      1st Traded Date
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Days Remaining</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Profit Target</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Current Profit</TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                    active={sortBy === 'status'}
                    direction={sortBy === 'status' ? sortDirection : 'asc'}
                    onClick={() => handleSort('status')}
                  >
                      Status
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Action</TableCell>

                </TableRow>
              </TableHead>
              {accounts?.length ? <TableBody>
                {accounts?.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell width={200} sx={{
                      fontSize: 13,
                      fontWeight: 500,
                    }}>{row?.accountNumber} </TableCell>
                    <TableCell width={180} sx={{
                      fontSize: 13,
                      fontWeight: 500,
                    }}>{row?.user?.username}</TableCell>
                    <TableCell width={250} sx={{
                      fontSize: 13,
                      fontWeight: 500,

                    }}>{row?.accountType?.label}</TableCell>
                    <TableCell width={180} sx={{
                      fontSize: 13,
                      fontWeight: 500,

                    }}>{formatPrice(Number(row?.amount))}</TableCell>
                    <TableCell width={240} sx={{
                      fontSize: 13,
                      fontWeight: 500,

                    }}>{dayjs(row?.meta?.firstTraded).format('YYYY-MM-DD')}</TableCell>
                    <TableCell width={240} sx={{
                      fontSize: 13,
                      fontWeight: 500,

                    }}>{getDaysRemaining(row?.meta?.firstTraded, row?.validityDays)}</TableCell>
                    <TableCell width={240} sx={{
                      fontSize: 13,
                      fontWeight: 500,

                    }}>{row?.profitTarget}</TableCell>
                    <TableCell width={240} sx={{
                      fontSize: 13,
                      fontWeight: 500,

                    }}>{row?.currentTarget ?? 'NA'}</TableCell>
                    <TableCell width={220} sx={{
                      fontSize: 13,
                      fontWeight: 500,

                    }}> <span style={{
                      color: getAccountStatusColor(row?.status)
                    }}>  &#9679; </span>{getAccountStatus(row?.status)}</TableCell>
                    <TableCell align='center' sx={{
                      display: 'flex',
                    }}>
                      <IconButton size='small' onClick={() => handleAccountViewOpen(row)} sx={{
                        background: '#60C68929',
                        borderRadius: 2,
                        mr: 2

                      }}>
                        <VisibilityOutlinedIcon />
                      </IconButton>
                      <IconButton size='small' onClick={() => handleAccountEditOpen(row)} sx={{
                        background: '#41AAAF1A',
                        borderRadius: 2,
                        mr: 2

                      }}>
                        <img alt='img' src={EditImage} />
                      </IconButton>
                      <IconButton size='small' aria-describedby={deleteOpenId} onClick={(event) => handleAccountDeleteOpen(event, row)} sx={{
                        background: '#FF6A761A',
                        borderRadius: 2,
                      }}>
                        <img alt='img' src={DeleteImage} />

                      </IconButton>
                      <Popover
                        sx={{
                          '& .MuiPaper-root': {
                            boxShadow: 'none',
                          },
                        }}
                        id={deleteOpenId}
                        open={open}
                        anchorEl={deleteConfirmationAnchorEl}
                        onClose={handleDeleteConfirmationClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <div style={{
                          margin: 8
                        }}>
                          <Typography sx={{
                            fontWeight: 600,
                            paddingBottom: 1
                          }}>Are you sure?</Typography>
                          <div style={{

                          }}>
                            <Button
                              onClick={() => handleDeleteAccount(row)}
                              sx={{
                                color: '#ffffff',
                                borderColor: 'unset',
                                marginRight: 2,
                                background: '#41aaae',
                                '&:hover': {
                                  backgroundColor: '#41AAAF',
                                },
                              }} variant="outlined">Confirm</Button>
                            <Button
                              onClick={handleDeleteConfirmationClose}
                              sx={{
                                color: '#ffffff',
                                borderColor: 'unset',
                                background: '#ff6a76',
                                '&:hover': {
                                  backgroundColor: '#ff6a76',
                                },
                              }} variant="outlined">Cancel</Button>
                          </div>
                        </div>
                      </Popover>
                    </TableCell>
                  </TableRow>
                ))} </TableBody>
                : <TableBody>
                  <TableRow>
                    <TableCell sx={{
                      fontSize: 13,
                      textAlign: 'center'
                    }} colSpan={9}>No Accounts Found</TableCell>

                  </TableRow>
                </TableBody>
              }


            </Table>
          </TableContainer>
  );
};

export default AccountsTable;
