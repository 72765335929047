import { loginRequested, loginSuccess, loginFailed, logout, getUser as _getUser, getUserFailed, getUserSuccess } from '../reducers/auth.reducer';
import { apiCallBegan } from '../api';

export const loginUser = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: '/users/login',
      data,
      method: 'POST',
      onStart: loginRequested.type,
      onSuccess: loginSuccess.type,
      onError: loginFailed.type,
      notificationMessage: 'Logged In!'

    })
  );
};

export const getUser = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/users/${data.userId}`,
      data,
      method: 'GET',
      onStart: _getUser.type,
      onSuccess: getUserSuccess.type,
      onError: getUserFailed.type,
    })
  );
};

export const logoutUser = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      onStatic: logout.type,
    })
  );
};