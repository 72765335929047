import { createSlice } from '@reduxjs/toolkit';

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    users: [],
    accounts: [],
    loading: false,
    updatedAccount: null,
    createdAccount: null,
    updatedUser: null,
    createdUser: null,
    createUserError: null,
    updateUserError: null,
    createAccountError: null,
    updateAccountError: null,
    notifications: null
  },

  reducers: {
    getUsers: (auth, action) => {
      auth.loading = true;
      auth.updatedAccount = null;
      auth.createdAccount = null;
      auth.updatedUser = null;
      auth.createdUser = null;
      auth.notifications = null;
    },

    getUsersSuccess: (auth, action) => {
      auth.users = action.payload;
      auth.loading = false;
    },

    getUsersFailed: (auth, action) => {
      auth.usersError = action.payload;
      auth.loading = false;
    },

    getAdminPositions: (auth, action) => {
      auth.loading = true;
      auth.updatedAccount = null;
      auth.createdAccount = null;
      auth.updatedUser = null;
      auth.createdUser = null;
    },

    getAdminPositionsSuccess: (auth, action) => {
      auth.positionTrades = action.payload;
      auth.loading = false;
    },

    getAdminPositionsFailed: (auth, action) => {
      auth.positionTrades = [];
      auth.loading = false;
    },

    getAdminOrderTrades: (auth, action) => {
      auth.loading = true;
      auth.updatedAccount = null;
      auth.createdAccount = null;
      auth.updatedUser = null;
      auth.createdUser = null;
    },

    getAdminOrderTradesSuccess: (auth, action) => {
      auth.orderTrades = action.payload;
      auth.loading = false;
    },

    getAdminOrderTradesFailed: (auth, action) => {
      auth.orderTrades = [];
      auth.loading = false;
    },

    getAccounts: (auth, action) => {
      auth.loading = true;
      auth.updatedAccount = null;
      auth.createdAccount = null;
      auth.updatedUser = null;
      auth.createdUser = null;
    },

    getAccountsSuccess: (auth, action) => {
      auth.accounts = action.payload;
      auth.loading = false;
    },

    getAccountsFailed: (auth, action) => {
      auth.accountsError = action.payload;
      auth.loading = false;
    },

    createUser: (auth, action) => {
      auth.loading = true;
    },

    createUserSuccess: (auth, action) => {
      auth.createdUser = action.payload;
      auth.loading = false;
    },

    createUserFailed: (auth, action) => {
      auth.createUserError = action.payload;
      auth.loading = false;
    },

    updateUser: (auth, action) => {
      auth.loading = true;
    },

    updateUserSuccess: (auth, action) => {
      auth.updatedUser = action.payload;
      auth.loading = false;
    },

    updateUserFailed: (auth, action) => {
      auth.updateUserError = action.payload;
      auth.loading = false;
    },

    createAccount: (auth, action) => {
      auth.loading = true;
    },

    createAccountSuccess: (auth, action) => {
      auth.createdAccount = action.payload;
      auth.loading = false;
    },

    createAccountFailed: (auth, action) => {
      auth.createAccountError = action.payload;
      auth.loading = false;
    },

    updateAccount: (auth, action) => {
      auth.loading = true;
    },

    updateAccountSuccess: (auth, action) => {
      auth.updatedAccount = action.payload;
      auth.loading = false;
    },

    updateAccountFailed: (auth, action) => {
      auth.updateAccountError = action.payload;
      auth.loading = false;
    },

    sendNotifications: (auth, action) => {
      auth.loading = true;
    },

    sendNotificationsSuccess: (auth, action) => {
      auth.notifications = action.payload;
      auth.loading = false;
    },

    sendNotificationsFailed: (auth, action) => {
      auth.loading = false;
    },

  },
});

const { actions, reducer } = adminSlice;
export const { getUsers, getUsersSuccess, getUsersFailed, createUser, createUserFailed, createUserSuccess, updateUser, updateUserFailed, updateUserSuccess,
  getAccounts, getAccountsFailed, getAccountsSuccess, createAccount, createAccountFailed, createAccountSuccess, updateAccount, updateAccountFailed, updateAccountSuccess,
  getAdminPositions, getAdminPositionsSuccess, getAdminPositionsFailed,
  getAdminOrderTrades, getAdminOrderTradesFailed, getAdminOrderTradesSuccess,
  sendNotifications, sendNotificationsSuccess, sendNotificationsFailed} = actions;
export default reducer;
