import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useTheme,
  Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText,
} from '@mui/material';
import { tokens } from '../../theme';

import DeleteForeverOutlined from '@mui/icons-material/Delete';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import AddImage from '../../assets/add.png';
import GreenArrowImage from '../../assets/green-arrow.png';
import RedArrowImage from '../../assets/red-arrow.png';
import TrendUpImage from '../../assets/trendup.png';
import { SOCKET_URL } from '../../config';
import RealTimeInstrumentTableLoader from '../../loaders/RealTimeInstrumentTableLoader';
import BuySell from '../dashboard/Elements/BuySell';

import { updateUser } from '../../state/actions/admin.actions';
import { getInstrumentSnapshots } from '../../state/actions/common.actions';
import { createTrade } from '../../state/actions/trade.actions';
import { tableCellClasses } from '@mui/material/TableCell';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../state/actions/auth.actions';
import { CHARGE, NOTIFICATION_TYPES } from '../../config';
let dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
dayjs.extend(utc);
dayjs.extend(timezone);



const InstrumentList = (props) => {
  const { onInstrumentsChange, onBuySellChange, user, account, isMobile } = props;
  const theme = useTheme();
  const socketRef = useRef(null);

  const searchInstrumentRef = useRef(null);
  const navigate = useNavigate();
  const [priceDisabled, setPriceDisabled] = useState(true);
  const [onSearch, setOnSearch] = useState(false);
  const [price, setPrice] = useState(0);
  const [lastTradePrice, setLastTradePrice] = useState(0);
  const [stopLossPrice, setStopLossPrice] = useState(0);
  const [priceInfo, setPriceInfo] = useState(0);
  const [instrumentName, setInstrumentName] = useState('');
  const [watchlistInstrumentsNames, setWatchlistInstrumentsNames] = useState([]);
  const [orderType, setOrderType] = useState('Market');
  const [communicationMethod, setCommunicationMethod] = useState('socket');

  const [instrumentsList, setInstrumentsList] = useState([]);

  const [accountCloseDialog, setAccountCloseDialog] = useState(false);

  const colors = tokens(theme.palette.mode);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');

  const dispatch = useDispatch();

  const instrumentSnapshotsState = useSelector((state) => state.common?.instrumentSnapshots);
  const createdTradeState = useSelector((state) => state.trade?.createdTrade);
  const updatedTradeState = useSelector((state) => state.trade?.updatedTrade);

  const [hoveredRow, setHoveredRow] = useState(null);
  const [searchHoveredRow, setSearchHoveredRow] = useState(null);


  useEffect(() => {
    if (createdTradeState?.trade) {
      let _orderType = `${createdTradeState?.trade?.orderType == 1 ? 'Executed' : 'Placed'}`;
      new Notification(`Order ${_orderType}`, {
        body: `${createdTradeState?.trade?.instrument} | ${createdTradeState?.trade?.quantity} ${_orderType} at ${createdTradeState?.trade?.price}`,
      }).onclick = () => {
        navigate(createdTradeState?.trade?.orderType == 1 ? '/hstry' : '/op');
      };
      resetBuySellValues();
    }
  }, [createdTradeState]);

  useEffect(() => {
    if (updatedTradeState?.trade?.trade) {
      new Notification('Order Updated', {
        body: `${updatedTradeState?.trade?.trade?.instrument} | ${updatedTradeState?.trade?.trade?.quantity} Placed at ${updatedTradeState?.trade?.trade?.price}`,
      }).onclick = () => {
        navigate('/op');
      };
      resetBuySellValues();
    }
  }, [updatedTradeState]);

  const [tab, setTab] = useState('Buy');

  const handleTabChange = (value) => {
    setStopLossPrice(0);
    setTab(value);
  };



  const handleAccountDialogClose = () => {
    setAccountCloseDialog(false);
    window.location.reload();
  };

  function getOrderType() {
    return orderType === 'Market' ? 1 : orderType === 'Limit' ? 2 : 3;
  }

  const handleBuy = (event, reason) => {
    dispatch(createTrade({
      'instrument': instrumentName,
      'quantity': quantity,
      'type': 1,
      'orderType': getOrderType(),
      'account': account?._id,
      'user': user?._id,
      'price': Number(price).toFixed(2),
      'stopLossPrice': stopLossPrice > 0 ? Number(stopLossPrice).toFixed(2) : null,
      chargePercentage: CHARGE
    }));
    setTimeout(() => onBuySellChange(), 800);
  };

  function resetBuySellValues() {
    setQuantity(1);
    setPrice(0);
    setOrderType('Market');
    setPriceInfo(0);
    setStopLossPrice(0);
    setOpenBuySellDialog(false);
    setHoveredRow(null);
  }

  const handleSell = (event, reason) => {
    dispatch(createTrade({
      'instrument': instrumentName,
      'quantity': quantity,
      'type': 2,
      'orderType': getOrderType(),
      'account': account?._id,
      'user': user?._id,
      'price': Number(price).toFixed(2),
      'stopLossPrice': stopLossPrice > 0 ? Number(stopLossPrice).toFixed(2) : null,
      chargePercentage: CHARGE
    }));
    setTimeout(() => onBuySellChange(), 800);
  };


  const [openBuySellDialog, setOpenBuySellDialog] = useState(false);


  const handleInstrumentClickActions = (event, type, instrument) => {
    setTab(type);
    setPrice(instrument?.[1]);
    setPriceDisabled(true);
    setLastTradePrice(instrument?.[1]);
    setPriceInfo(instrument?.[1]);
    setInstrumentName(instrument?.[0]);
    setOpenBuySellDialog(true);
  };

  const handleBuySellClose = (event, reason) => {
    setOpenBuySellDialog(false);
    resetBuySellValues();
  };

  const [quantity, setQuantity] = useState(1);


  const handleRowHover = (rowId) => {
    if (account?.status == 1) {
      setHoveredRow(rowId);
    }
  };

  const handleRowLeave = () => {
    if (!openBuySellDialog) {
      setHoveredRow(null);
    }
  };

  const handleSearchRowHover = (rowId) => {
    setSearchHoveredRow(rowId);
  };

  const handleSearchRowLeave = () => {
    if (!openBuySellDialog) {
      setSearchHoveredRow(null);
    }
  };

  const handleQuantityChange = (event) => {
    let qValue = event?.target?.value;
    if (/^\d*$/.test(qValue) && qValue != 0) {
      setQuantity(String(Number(qValue)));
    }
    else if (qValue == '') {
      setQuantity('')
    }
  };

  const [searchTerm, setSearchTerm] = useState('');

  const handleInstrumentSearch = (event) => {
    setSearchTerm(event?.target?.value);
  };

  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const [allInstruments, setAllInstruments] = useState([]);
  const [niftyData, setNiftyData] = useState(undefined);
  const [bankNiftyData, setBankNiftyData] = useState(undefined);

  const handlePriceChange = (event) => {
    let pValue = event?.target?.value;
    if (/^[0-9]\d*\.?\d*$/.test(pValue) && pValue != 0) {
      setPrice(String(Number(pValue)));
    } else if (pValue == '') {
      setPrice('')
    }
  };

  const handleStopLossPriceChange = (event) => {
    let pValue = event?.target?.value;
    if (/^\d.*$/.test(pValue)) {
      setStopLossPrice(String(Number(pValue)));
    } else if (pValue == '') {
      setStopLossPrice('')
    }
  };

  const handleOrderTypeChange = (event) => {
    let value = event?.target?.value;
    if (value === 'Market') {
      setPriceDisabled(true);
    }
    if (value === 'Limit') {
      setPriceDisabled(false);
    }
    _setMarketPrice();
    setOrderType(value);
  };



  function sorted(_instruments) {
    return _instruments?.sort((a, b) => a[0].localeCompare(b[0]));
  }


  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(SOCKET_URL);
    }

    const socket = socketRef.current;

    const handleInstrumentsData = (instrumentsData) => {
      if (instrumentsData?.length > 4) {
        const filteredInstruments = instrumentsData?.filter((item) =>
          watchlistInstrumentsNames?.includes(item?.[0])
        );
        setSelectedInstruments(sorted(filteredInstruments));
        setAllInstruments(instrumentsData);
        setNiftyData(instrumentsData?.find((item) => 'NIFTY 50' === item?.[0]));
        setBankNiftyData(instrumentsData?.find((item) => 'NIFTY BANK' === item?.[0]));
        if (onInstrumentsChange) {
          onInstrumentsChange(instrumentsData);
        }
      }
    };

    socket.on('message', handleInstrumentsData);

    socket.on(`${userId}`, (data) => {
      if (data?.type === NOTIFICATION_TYPES.ACCOUNT_LOSS_REFRESHER) {
        setAccountCloseDialog(true);
      }
    });

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    if (!socket.connected) {
      console.log('Not connected.');
      dispatch(getInstrumentSnapshots({}));
      setCommunicationMethod('api');
    }

    return () => {
      socket.off('message', handleInstrumentsData); 
      socket.disconnect();
      socketRef.current = null;  
    };
  }, [watchlistInstrumentsNames, userId]); 


  useEffect(() => {
    if (user) {
      const currentTimeUTC = dayjs().utc();
      const startTime = dayjs().utc().set('hour', 3).set('minute', 45).set('second', 0);
      const endTime = dayjs().utc().set('hour', 10).set('minute', 0).set('second', 0);
      let day = new Date().getDay();

      const isBetweenMarketTime = currentTimeUTC.isBetween(startTime, endTime);

      if (!isBetweenMarketTime || [0,6].includes(day)) {
        let instrumentSnapshots = instrumentSnapshotsState?.instruments;
        let filteredInstrumentSnapshots = instrumentSnapshots?.filter((item) =>
          watchlistInstrumentsNames?.includes(item?.[0])
        );
        setSelectedInstruments(sorted(filteredInstrumentSnapshots));
        setAllInstruments(instrumentSnapshots);
        setNiftyData(instrumentSnapshots?.find((item) => 'NIFTY 50' == item?.[0]));
        setBankNiftyData(instrumentSnapshots?.find((item) => 'NIFTY BANK' == item?.[0]));
        if (onInstrumentsChange) {
          onInstrumentsChange(instrumentSnapshots);
        }
      }
    }
  }, [communicationMethod, instrumentSnapshotsState, tab, user, openBuySellDialog, orderType, watchlistInstrumentsNames]);


  useEffect(() => {
    if (user) {
      setWatchlistInstrumentsNames(user?.watchlists);
    }
  }, [user]);

  useEffect(() => {
    _setMarketPrice();
  }, [orderType, openBuySellDialog, selectedInstruments]);

  function _setMarketPrice() {
    let _item = allInstruments?.find((item) => item?.[0] == instrumentName);
    if (openBuySellDialog && orderType == 'Market') {
      setPrice(_item?.[1]);
    }
    setPriceInfo(_item?.[1]);
    setLastTradePrice(_item?.[1]);
  }

  useEffect(() => {
    if (userId) {
      dispatch(getUser({ userId }));
    }
  }, []);

  useEffect(() => {

    if (searchTerm?.length > 0) {
      let filtered = allInstruments?.filter((item) => item?.[0].toLowerCase().includes(searchTerm.toLowerCase()));
      setInstrumentsList(filtered);
    } else {
      setInstrumentsList(allInstruments);
    }
  }, [searchTerm]);


  const handleAddToWatchlist = (instrument) => {
    let updatedSelectedInstruments = [...watchlistInstrumentsNames, instrument?.[0]];
    dispatch(updateUser({
      _id: user?._id,
      watchlists: updatedSelectedInstruments
    }, `${instrument?.[0]} Added.`));
    setWatchlistInstrumentsNames(updatedSelectedInstruments);
    searchInstrumentRef.current.focus();
  };

  const handleDeleteFromWatchlist = (instrument, dismissSearch = false) => {
    let filtered = watchlistInstrumentsNames.filter(
      (item) => item !== instrument?.[0]
    );
    dispatch(updateUser({
      _id: user?._id,
      watchlists: filtered
    }, `${instrument?.[0]} Removed.`));
    setWatchlistInstrumentsNames(filtered);
    if (dismissSearch) {
      searchInstrumentRef.current.focus();
    }
  };


  function formatDigits(num) {
    if (!num) {
      return 0;
    }
    num = Number(num)
    return num.toFixed(2);
  }

  return (
    <Grid style={{
      flex: 1,
      padding: 20,
      paddingTop: '25px',
      paddingLeft: 20,
      paddingRight: 20,
    }}
      sx={{
        display: {
          xs: isMobile ? 'block' : 'none', md: 'block'
        }
      }}

    >

      <TableContainer
        style={{
          border: colors.instrumentTableBorder,
          borderBottom: 'none',

          borderTopRightRadius: 20,
          borderTopLeftRadius: 20,
          background: colors.instrumentTable[100],
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        sx={{ borderRadius: '0px', boxShadow: 'none' }}
      >
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
          }}
          stickyHeader
        >
          <Grid style={{

            paddingBottom: '10%'
          }} container sx={{ padding: '16px 2px 10px 2px' }} spacing={2} >
            <Grid item md={1} sm={1} xs={1} >
            </Grid>
            <Grid item md={5} sm={5} xs={5} >
              <div style={{
                textAlign: 'center',
                position: 'absolute'
              }}>
                <span >
                  <Typography sx={{ fontWeight: '600', fontSize: '11px' }}>NIFTY 50.x
                    <span style={{ color: niftyData?.[3] > 0 ? colors.green : colors.red, fontWeight: '600', fontSize: '11px', paddingLeft: 8 }}>
                      {niftyData?.[1]}
                    </span>
                  </Typography>
                </span>
                <Typography sx={{ fontWeight: '600', color: '#a3a3a3', fontSize: '10px', position: 'absolute' }} >
                  {formatDigits(niftyData?.[2])}{'    '}
                  <span>
                    ({formatDigits(niftyData?.[3])}%)
                  </span>
                </Typography>              </div>
            </Grid>
            <Grid item md={5} sm={5} xs={5} style={{
            }}>
              <div style={{
                textAlign: 'center',
                position: 'absolute',

              }}>
                <span >
                  <Typography sx={{ fontWeight: '600', fontSize: '11px' }}>NIFTY BANK.x
                    <span style={{ color: bankNiftyData?.[3] > 0 ? colors.green : colors.red, fontWeight: '600', fontSize: '11px', paddingLeft: 8 }}>
                      {bankNiftyData?.[1]}
                    </span>
                  </Typography>
                </span>
                <Typography sx={{ fontWeight: '600', color: '#a3a3a3', fontSize: '10px', position: 'absolute' }} >
                  {formatDigits(bankNiftyData?.[2])}{'    '} ({formatDigits(bankNiftyData?.[3])}%)
                </Typography>              </div>
            </Grid>

          </Grid>
        </Table>

      </TableContainer>
      <div style={{
        border: colors.instrumentTableBorder,
        borderBottom: 'none',
        paddingTop: '8px',
        paddingLeft: '10px',
        paddingRight: '10px',
        backgroundColor: colors.instrumentTable[100],
      }}>
        <TextField
          sx={{
            paddingTop: '12px',
            borderBottom: 'none',
            paddingBottom: '12px',
            backgroundColor: colors.searchBg,
            marginBottom: '8px',
            borderRadius: '10px',
            '& input::placeholder': {
              color: colors.black[100]
            },
          }}

          inputRef={searchInstrumentRef}
          placeholder="Search by name. Eg: Reliance.x, Infosys.x, TCS.x..."
          variant="standard"
          value={searchTerm}
          onFocus={() => setOnSearch(true)}
          onBlur={() => {
            if (!searchHoveredRow) {
              setOnSearch(false);
              setSearchTerm('');
            }
          }}
          onChange={handleInstrumentSearch}
          fullWidth
          InputProps={{
            startAdornment: <SearchOutlined sx={{ marginLeft: 1.5 }} />,
            disableUnderline: true,
            endAdornment: <CloseOutlined style={{
              cursor: 'pointer'
            }} onClick={() => {
              setOnSearch(false);
              setSearchTerm('');
            }} sx={{ marginRight: 1.5 }} />
          }}
        />
      </div>
      <TableContainer
        style={{
          border: colors.instrumentTableBorder,
          maxHeight: 'calc(100vh - 10em)',
          minHeight: 'calc(100vh - 10em)',
          background: colors.instrumentTable[100],
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        sx={{ borderRadius: '0px', boxShadow: 'none' }}

      >

        <Table
          style={{
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 2em)',
          }}
          size='small'
          sx={{
            [`& .${tableCellClasses.root}`]: {
            },
          }}
        >
          {onSearch && instrumentsList?.length ?
            <TableBody >
              {(
                instrumentsList?.map((instrument) => {
                  return (
                    <TableRow
                      onMouseEnter={() =>
                        handleSearchRowHover(instrument?.[0])
                      }
                      sx={{
                        height: 45
                      }}
                      onMouseLeave={handleSearchRowLeave}
                      key={instrument?.[0]}>
                      {searchHoveredRow === instrument?.[0] ? (
                        <TableCell>
                          <Box
                            sx={{
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              display: { xs: 'flex', md: 'flex' },
                              marginRight: { xs: 0, md: 0 },
                            }}
                          >
                            <div>
                              <Typography sx={{
                                fontSize: 12,
                                color: colors.black[100]
                              }}> {`${instrument?.[0]}.x`}</Typography>
                            </div>
                            <div style={{
                              display: 'flex',
                            }}>
                              {account?.status == 1 &&
                                <>
                                  <Button
                                    sx={{
                                      height: 25,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    onClick={(event) =>
                                      handleInstrumentClickActions(event, 'Buy', instrument)
                                    }
                                    style={{
                                      border: 'none',
                                      borderRadius: '12px',
                                      backgroundColor: colors.green,
                                      color: 'white',
                                    }}
                                  >
                                    Buy
                                  </Button>
                                  <BuySell
                                    tab={tab}
                                    handleBuySellClose={handleBuySellClose}
                                    openBuySellDialog={openBuySellDialog}
                                    handleBuy={handleBuy}
                                    handleQuantityChange={handleQuantityChange}
                                    handleTabChange={handleTabChange}
                                    handleSell={handleSell}
                                    instrumentName={instrumentName}
                                    quantity={quantity}
                                    price={price}
                                    lastTradePrice={lastTradePrice}
                                    stopLossPrice={stopLossPrice}
                                    handleStopLossPriceChange={handleStopLossPriceChange}
                                    priceInfo={priceInfo}
                                    availableMargin={account?.credits?.cashBalance}
                                    handlePriceChange={handlePriceChange}
                                    orderType={orderType}
                                    handleOrderTypeChange={handleOrderTypeChange}
                                    priceDisabled={priceDisabled}
                                  />
                                  &nbsp;
                                  <Button
                                    sx={{
                                      height: 25,
                                    }}
                                    variant="outlined"
                                    onClick={(event) =>
                                      handleInstrumentClickActions(event, 'Sell', instrument)
                                    }
                                    size="small"
                                    style={{
                                      border: 'none',
                                      borderRadius: '12px',
                                      backgroundColor: '#FF6A76',
                                      color: 'white',
                                    }}
                                  >
                                    Sell
                                  </Button></>}
                              &nbsp;
                              <IconButton onClick={() => {
                                window.open(`https://in.tradingview.com/chart/?symbol=NSE%3A${hoveredRow
                                  .replace(/ /g, '')
                                  .replace('-', '_')
                                  .replace('NIFTYBANK', 'BANKNIFTY')
                                  .replace('NIFTY50', 'NIFTY')
                                  }`, "_blank")
                              }} variant="outlined" size="small">
                                <img src={TrendUpImage} style={{ width: 20 }} />
                              </IconButton>
                              &nbsp;
                              {watchlistInstrumentsNames?.find(
                                (item) =>
                                  item === instrument?.[0]
                              ) ? (
                                <div>
                                  <IconButton variant="outlined" size="small">
                                    <DeleteForeverOutlined
                                      sx={{
                                        color: '#FF6A76',
                                      }}
                                      onClick={() =>
                                        handleDeleteFromWatchlist(instrument, true)
                                      }
                                    />
                                  </IconButton>
                                </div>
                              ) : (
                                <div>
                                  <IconButton
                                    variant="outlined"
                                    size="small"
                                    onClick={() =>
                                      handleAddToWatchlist(instrument)
                                    }
                                  >
                                    <img alt="add-image" src={AddImage} style={{ width: 20 }} />
                                  </IconButton>
                                </div>
                              )}
                            </div>
                          </Box>
                        </TableCell>
                      ) :
                        <TableCell key={instrument?.[0]}>{`${instrument?.[0]}.x`}</TableCell>
                      }
                    </TableRow>
                  );
                })
              )}
            </TableBody> :
            onSearch && instrumentsList?.length === 0 ?
              <TableBody>
                <TableRow>
                  <TableCell sx={{
                    fontSize: 15,
                    textAlign: 'center'
                  }} colSpan={9}>No Symbol Found</TableCell>

                </TableRow>
              </TableBody> :
              !onSearch && selectedInstruments?.length ? (
                <TableBody>
                  {Object.entries(selectedInstruments).map(
                    ([_, instrument]) => {
                      return (
                        <TableRow
                          sx={{
                            height: '48px',
                            background: hoveredRow === instrument?.[0]
                              ? colors.instrumentTable.hover
                              : '',

                            borderBottom: colors.instrumentTableBorder
                          }}
                          onMouseEnter={() =>
                            handleRowHover(instrument?.[0])
                          }
                          onMouseLeave={handleRowLeave}
                          key={instrument?.[0]}
                        >
                          <TableCell
                            sx={{
                              border: 'none',
                              marginRight: 10,
                              width: '30%',
                            }}
                            align="left"
                          >
                            <Typography
                              style={{
                                color: instrument?.[3] > 0 ? colors.green : colors.red,
                                fontSize: 12,
                              }}
                            >
                              {`${instrument?.[0]}.x`}
                            </Typography>

                          </TableCell>

                          {/* Starting Hover */}
                          {hoveredRow === instrument?.[0] ? (
                            <TableCell sx={{
                              border: 'none',

                              width: '80px',
                              display: 'flex',
                              textAlign: 'right',
                              justifyContent: 'flex-end'
                            }}>
                              <Box
                                sx={{
                                  width: 20,
                                  marginTop: '4px',
                                  display: { xs: 'flex', md: 'flex' },
                                  marginRight: { xs: 0, md: 0 },
                                }}
                              >
                                <Button
                                  sx={{
                                    height: '25px',
                                  }}
                                  variant="outlined"
                                  size="small"
                                  onClick={(event) =>
                                    handleInstrumentClickActions(event, 'Buy', instrument)
                                  }
                                  style={{
                                    border: 'none',
                                    borderRadius: '12px',
                                    backgroundColor: colors.green,
                                    color: 'white',
                                  }}
                                >
                                  Buy
                                </Button>
                                <BuySell
                                  tab={tab}
                                  handleBuySellClose={handleBuySellClose}
                                  openBuySellDialog={openBuySellDialog}
                                  handleBuy={handleBuy}
                                  handleQuantityChange={handleQuantityChange}
                                  handleTabChange={handleTabChange}
                                  handleSell={handleSell}
                                  instrumentName={instrumentName}
                                  quantity={quantity}
                                  price={price}
                                  lastTradePrice={lastTradePrice}
                                  stopLossPrice={stopLossPrice}
                                  handleStopLossPriceChange={handleStopLossPriceChange}
                                  priceInfo={priceInfo}
                                  availableMargin={account?.credits?.cashBalance}
                                  handlePriceChange={handlePriceChange}
                                  orderType={orderType}
                                  handleOrderTypeChange={handleOrderTypeChange}
                                  priceDisabled={priceDisabled}
                                />
                                &nbsp;
                                <Button
                                  sx={{
                                    height: '25px',
                                  }}
                                  variant="outlined"
                                  onClick={(event) =>
                                    handleInstrumentClickActions(event, 'Sell', instrument)
                                  }
                                  size="small"
                                  style={{
                                    border: 'none',
                                    borderRadius: '12px',
                                    backgroundColor: '#FF6A76',
                                    color: 'white',
                                  }}
                                >
                                  Sell
                                </Button>
                                &nbsp;
                                <IconButton onClick={() => {
                                  window.open(`https://in.tradingview.com/chart/?symbol=NSE%3A${hoveredRow
                                    .replace(/ /g, '')
                                    .replace('-', '_')
                                    .replace('NIFTYBANK', 'BANKNIFTY')
                                    }`, "_blank")
                                }} variant="outlined" size="small">
                                  <img src={TrendUpImage} style={{ width: 20 }} />
                                </IconButton>
                                &nbsp;
                                <div>
                                  <IconButton variant="outlined" size="small">
                                    <DeleteForeverOutlined
                                      sx={{
                                        color: '#FF6A76',
                                      }}
                                      onClick={() =>
                                        handleDeleteFromWatchlist(instrument)
                                      }
                                    />
                                  </IconButton>
                                </div>
                              </Box>
                            </TableCell>
                          ) : (
                            // Ended Hover
                            <TableCell sx={{
                              border: 'none'
                            }}>
                              <div style={{
                                display: 'flex',
                                textAlign: 'right',
                                justifyContent: 'flex-end'
                              }}>
                                <div style={{
                                  width: '40px',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  // marginRight: '',
                                }}>
                                  <Typography
                                    style={{
                                      fontSize: '110%',
                                      color: instrument?.[3]  > 0
                                        ? colors.green
                                        : colors.red,
                                    }}
                                  >
                                    {instrument?.[3]  > 0 ? `+${formatDigits(instrument?.[2] )}` : `${formatDigits(instrument?.[2] )}`}{'    '}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '70px',
                                  }}
                                >
                                  <Typography style={{
                                    fontSize: '110%',
                                    color: colors.black[100]
                                  }}
                                  >
                                    {instrument?.[3]  > 0 ? `+${formatDigits(instrument?.[3] )}` : formatDigits(instrument?.[3] )}%
                                  </Typography>


                                </div>
                                <div style={{
                                  alignContent: 'center',
                                  width: '40px',
                                }}>
                                  <img alt="arrow"
                                    src={instrument?.[3]  > 0 ? GreenArrowImage : RedArrowImage}
                                  />
                                </div>
                                <div style={{
                                  width: '70px',
                                  marginRight: '2%',
                                }}>
                                  <Typography style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}>
                                    {instrument?.[1]?.toFixed(2)}
                                  </Typography>
                                </div>
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              ) : selectedInstruments?.length === 0 && searchTerm?.length > 0 ? (
                <div
                  style={{
                    textAlign: 'center',
                  }}
                >
                  No result{' '}
                </div>
              ) : (
                <RealTimeInstrumentTableLoader />
              )}
        </Table>
      </TableContainer>

      <Dialog
        open={accountCloseDialog}
        onClose={handleAccountDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{
          mb: '10px'
        }} style={{
          fontWeight: 600,
          borderBottom: '0.5px solid grey'
        }} align='center' id="alert-dialog-title">
          {"Account Liquidated"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Oops! Account got liquidated due to breach of loss limits. Lucky for you, did not lose your precious capital. <a style={{
              color: '#0ce7e7'
            }} href='https://www.uppercircuit.in' target='blank'> Click here</a> to get a new account and start trading right away
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{
          justifyContent: 'center'
        }}>
          <Button onClick={handleAccountDialogClose} autoFocus
            sx={{
              mr: '10px',
              mb: '10px',
              width: '20%'
            }}
            variant="outlined"
            size="large"
            style={{
              backgroundColor: colors.teal,
            }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default InstrumentList;
