import { createContext, useState, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === 'dark'
    ? {
      grey: {
        100: '#e0e0e0',
        200: '#c2c2c2',
        300: '#a3a3a3',
        400: '#858585',
        500: '#666666',
        600: '#525252',
        700: '#3d3d3d',
        800: '#292929',
        900: '#141414',
      },
      primary: {
        100: '#d0d1d5',
        200: '#a1a4ab',
        300: '#727681',
        400: '#1F2A40',
        500: '#141b2d',
        600: '#101624',
        700: '#0c101b',
        800: '#080b12',
        900: '#040509',
      },
      greenAccent: {
        100: '#dbf5ee',
        200: '#b7ebde',
        300: '#94e2cd',
        400: '#70d8bd',
        500: '#4cceac',
        600: '#3da58a',
        700: '#2e7c67',
        800: '#1e5245',
        900: '#0f2922',
      },
      redAccent: {
        100: '#f8dcdb',
        200: '#f1b9b7',
        300: '#e99592',
        400: '#e2726e',
        500: '#db4f4a',
        600: '#af3f3b',
        700: '#832f2c',
        800: '#58201e',
        900: '#2c100f',
      },
      blueAccent: {
        100: '#e1e2fe',
        200: '#c3c6fd',
        300: '#a4a9fc',
        400: '#868dfb',
        500: '#6870fa',
        600: '#535ac8',
        700: '#3e4396',
        800: '#2a2d64',
        900: '#151632',
      },
      white: {
        100: '#000000'
      },
      black: {
        100: '#FFFFFF'
      },
      instrumentTable: {
        100: '#131B24',
        boxShadowRight: '0px 0px 14px 0px #00000040',
        hover: '#001115E5'

      },
      accountCard: {
        backgroundColor: '#131B24'
      },
      dashboardCards: {
        background: '#131B24'
      },
      dashboard: {
        background: '#001115'
      },
      home: {
        100: '#001115',
        background: '#001115'
      },
      topbar: {
        100: '#001115',
        background: '#001115'

      },
      appBar: {
        boxShadow: '0px 3px 13px 4px #5EF3FB1A'
      },
      bluish: '#21dfe8',
      buyButton: '#2ED573',
      sellButton: '#FF6A76',
      buyBackground: '#131B24',
      sellBackground: '#131B24',
      accountMenu: {
        background: '#131B24'
      },
      piechart: {
        red: '#FF4757',
        greenishGrey: '#587564',
        redishGrey: '#6C6C6C',
        green: '#2ED573',
      },
      tableText: '#FFFFFF',
      green: '#2ED573',
      red: "#FF4757",
      darkBlue: "#001115",
      teal: '#5EF3FB',
      tealBlack: '#5EF3FB',
      textLabel: '#C9C9C9',
      upgradeBtn: 'linear-gradient(95.91deg, rgba(255, 255, 255, 0) 0.55%, rgba(255, 255, 255, 0.1) 97.39%)',
      searchBg: '#00111580',
      instrumentTableBorder: '1px solid #F2F2F229'

    }
    : {
      grey: {
        100: '#141414',
        200: '#292929',
        300: '#3d3d3d',
        400: '#525252',
        500: '#666666',
        600: '#858585',
        700: '#a3a3a3',
        800: '#c2c2c2',
        900: '#e0e0e0',
      },
      primary: {
        100: '#040509',
        200: '#080b12',
        300: '#0c101b',
        400: '#f2f0f0',
        500: '#141b2d',
        600: '#1F2A40',
        700: '#727681',
        800: '#a1a4ab',
        900: '#d0d1d5',
      },
      greenAccent: {
        100: '#0f2922',
        200: '#1e5245',
        300: '#2e7c67',
        400: '#3da58a',
        500: '#4cceac',
        600: '#70d8bd',
        700: '#94e2cd',
        800: '#b7ebde',
        900: '#dbf5ee',
      },
      redAccent: {
        100: '#2c100f',
        200: '#58201e',
        300: '#832f2c',
        400: '#af3f3b',
        500: '#db4f4a',
        600: '#e2726e',
        700: '#e99592',
        800: '#f1b9b7',
        900: '#f8dcdb',
      },
      blueAccent: {
        100: '#151632',
        200: '#2a2d64',
        300: '#3e4396',
        400: '#535ac8',
        500: '#6870fa',
        600: '#868dfb',
        700: '#a4a9fc',
        800: '#c3c6fd',
        900: '#e1e2fe',
      },
      white: {
        100: '#FFFFFF'
      },
      black: {
        100: '#000000'
      },
      instrumentTable: {
        100: '#F7F7F7',
        boxShadowRight: '0px 0px 14px 0px rgba(0, 0, 0, 0.1)',
        hover: '#0011150D'
      },
      accountCard: {
        backgroundColor: 'rgba(94, 243, 251, 0.09)'
      },
      dashboardCards: {
        background: '#FFFFFF'
      },
      dashboard: {
        background: '#FFFFFF',
        boxShadow: '0px 1px 5px 0px #00000029'
      },
      home: {
        100: '#FFFFFF'
      },
      topbar: {
        100: '#FFFFFF',
        background: '#FFFFFF'
      },
      appBar: {
        boxShadow: '0px 3px 13px 4px #5EF3FB1A'
      },
      bluish: '#5df3fb',
      buyButton: '#2ED573',
      sellButton: '#FF6A76',
      buyBackground: '#F1F3F4',
      sellBackground: '#F1F3F4',
      accountMenu: {
        background: '#FFFFFF'
      },
      piechart: {
        red: '#FF4757',
        greenishGrey: '#587564',
        redishGrey: '#755858',
        green: '#2ED573',
      },
      tableText: '#000000',
      green: '#2ED573',
      red: "#FF4757",
      darkBlue: "#5EF3FB80",
      teal: '#5EF3FB',
      tealBlack: '#000000',
      textLabel: '#5E5E5E',
      upgradeBtn: 'linear-gradient(95.82deg, #FFFFFF 3.3%, rgba(94, 243, 251, 0.2) 100%)',
      searchBg: '#0011151A',
      instrumentTableBorder: '1px solid #5E5E5E33',
      // tableTo
    }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === 'dark'
        ? {
          // palette values for dark mode
          primary: {
            main: colors.primary[500],
          },
          secondary: {
            main: colors.greenAccent[500],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          background: {
            default: '#001115',
          },
        }
        : {
          // palette values for light mode
          primary: {
            main: colors.primary[100],
          },
          secondary: {
            main: colors.greenAccent[500],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          background: {
            default: '#fcfcfc',
          },
        }),
    },
    typography: {
      fontFamily: ['Poppins'].join(','),
      fontSize: 12,
      h1: {
        fontFamily: ['Poppins'].join(','),
        fontSize: 40,
      },
      h2: {
        fontFamily: ['Poppins'].join(','),
        fontSize: 32,
      },
      h3: {
        fontFamily: ['Poppins'].join(','),
        fontSize: 24,
      },
      h4: {
        fontFamily: ['Poppins'].join(','),
        fontSize: 20,
      },
      h5: {
        fontFamily: ['Poppins'].join(','),
        fontSize: 16,
      },
      h6: {
        fontFamily: ['Poppins'].join(','),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => { },
});

export const useMode = () => {
  const [mode, setMode] = useState(localStorage.getItem('theme') ?? 'light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === 'light' ? 'dark' : 'light')),
    }),
    []
  );
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  localStorage.setItem('theme', mode);

  return [theme, colorMode];
};
