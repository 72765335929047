import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    plans: [],
    symbols: [],
    loading: false,
    instrumentSnapshots: [],
  },

  reducers: {
    getPlans: (common, action) => {
      common.loading = true;
    },

    getPlansSuccess: (common, action) => {
      common.plans = action.payload;
      common.loading = false;
    },

    getPlansFailed: (common, action) => {
      common.plansError = action.payload;
      common.loading = false;
    },
    getInstrumentSnapshots: (common, action) => {
      common.loading = true;
    },

    getInstrumentSnapshotsSuccess: (common, action) => {
      common.instrumentSnapshots = action.payload;
      common.loading = false;
    },

    getInstrumentSnapshotsFailed: (common, action) => {
      common.instrumentSnapshots = [];
      common.loading = false;
    },

    
    setNavMenuItem: (common, action) => {
      common.navMenuItem = action?.payload?.navMenuItem;
    },

  },
});

const { actions, reducer } = commonSlice;
export const { getPlans, getPlansSuccess, getPlansFailed, setNavMenuItem,
  getInstrumentSnapshots, getInstrumentSnapshotsFailed, getInstrumentSnapshotsSuccess} = actions;
export default reducer;
