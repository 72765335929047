import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Typography,
    useTheme,
    Box,
    CircularProgress,
    Input,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { sendNotification } from '../../../../state/reducers/notification.reducer'
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from '../../../../components/Transition';
import { S3_BASE_URL, REST_URL } from '../../../../config';
import { downloadFile } from '../../../../services/fn';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import React from 'react';
import { tokens } from '../../../../theme';

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const AccountsImportDialog = ({
    accountImportDialog,
    handleAccountImportDialogClose,
}) => {

    const dispatch = useDispatch();

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [uploadDisabled, setUploadDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setUploadDisabled(false);
    };


    async function downloadTemplate() {
        try {
            const accessToken = localStorage.getItem('token');
            const response = await axios.get(`${REST_URL}/users/download/accounts/import-template`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ImportAccountsTemplate.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading XLSX file:', error);
        }
    }

    async function handleImportAccounts() {
        setLoading(true);
        setUploadDisabled(true);
        try {
            const accessToken = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('file', file);
            const response = await axios.post(`${REST_URL}/users/accounts/import`, formData, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data',
                }
            });

            const contentType = response.headers['content-type'];
            console.log('contentType: ', contentType);
            if (contentType && contentType.includes('application/octet-stream')) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ErrorAccountsList.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                dispatch({ type: sendNotification.type, payload: { message: `Some accounts were not uploaded. Use the downloaded file to fix the incorrect rows and re-upload the file` } });
            } else {
                console.log('esponse?.data: ', response);
                dispatch({ type: sendNotification.type, payload: { message: response?.data?.message, } });
                handleAccountImportDialogClose();
            }
            setLoading(false);
            setFile(null);

        } catch (error) {
            console.error('Error uploading file:', error);
        }

    }

    function handleRemoveFile() {
        setFile(null);
        setUploadDisabled(true);
    }

    return (
        <Dialog
            TransitionComponent={Transition}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={accountImportDialog}
            onClose={handleAccountImportDialogClose}
        >
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{
                        fontWeight: 700,
                        fontSize: 16
                    }}>{'Import Accounts'}</span>
                    <IconButton onClick={handleAccountImportDialogClose} edge="end" color="inherit" aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <Grid>
                <Divider />
            </Grid>
            <DialogContent>
                <Grid container>
                    <Grid item md={4} xs={12} >
                        <div>
                            {!file && <Button
                                fullWidth
                                sx={{
                                    height: 40,
                                    marginTop: 2,
                                    marginLeft: 2,
                                    backgroundColor: '#41AAAF',
                                    '&:hover': {
                                        backgroundColor: '#41AAAF',
                                    },
                                }}
                                component="label"
                                aria-label="upload picture"
                                disabled={!!file}
                                variant="contained"
                                startIcon={<CloudUploadIcon />}
                            >

                                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                                Upload XLSX File
                            </Button>}
                            {file && (
                                <div style={{
                                    display: 'flex',
                                    marginLeft: '17px'
                                }}>
                                    <Typography>
                                        {file.name?.length > 15 ? file.name.substring(0, 15) + '...' : file.name}
                                    </Typography>
                                    <Button
                                        sx={{
                                            ml: 2
                                        }}
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        startIcon={<DeleteIcon />}
                                        onClick={handleRemoveFile}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            )}
                            {file && <Button
                                sx={{
                                    height: 40,
                                    marginTop: 2,
                                    marginLeft: 2,
                                    backgroundColor: '#41AAAF',
                                    '&:hover': {
                                        backgroundColor: '#41AAAF',
                                    },
                                }}
                                disabled={uploadDisabled}
                                component="label"
                                role={undefined}
                                tabIndex={-1}
                                fullWidth
                                onClick={handleImportAccounts}
                                variant="contained"
                                startIcon={<CloudUploadIcon />}>
                                {loading ? <CircularProgress size={24} color="inherit" /> : 'Upload'}
                            </Button>}
                        </div>
                        {!file && <Button
                            sx={{
                                height: 40,
                                marginTop: 2,
                                marginLeft: 2,
                                backgroundColor: '#41AAAF',
                                '&:hover': {
                                    backgroundColor: '#41AAAF',
                                },
                            }}
                            onClick={downloadTemplate}
                            fullWidth
                            variant="contained"
                            startIcon={<DownloadIcon />}>
                            Download Template
                        </Button>}
                    </Grid>
                    <Grid item md={8} xs={12} pl={5}>
                        <ul>
                            <li><Typography>Supports only xlsx file format.</Typography></li>
                            <li><Typography>When any error occurs, you should be able to download the file containing failed accounts records. Update and upload again.</Typography></li>
                            <li><Typography>Do not rename or rearrange headers.</Typography></li>
                        </ul>

                    </Grid>
                </Grid>


            </DialogContent>
        </Dialog>
    );
};

export default AccountsImportDialog;