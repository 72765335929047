import React, { useEffect, useState } from 'react';

import { Box, Button, TableSortLabel, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Search as SearchIcon } from '@mui/icons-material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, getUsers, updateUser } from '../../../state/actions/admin.actions';
import AdminTopBar from '../../global/AdminTopbar';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useNavigate } from 'react-router-dom';
import { USER_STATUS } from '../../../config';

import DeleteImage from '../../../assets/delete.png';
import EditImage from '../../../assets/edit.png';
import { Transition } from '../../../components/Transition';
import { generateRandomString } from '../../../utils/string';


const AdminUsers = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [sortBy, setSortBy] = useState('updatedAt');
  const [sortDirection, setSortDirection] = useState('desc');

  function handleSort(property) {
    const direction = sortBy === property && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortBy(property);
    setSortDirection(direction);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const _getUsers = () => {
    let params = {};
    if (searchText?.length >= 2) {
      params.q = searchText;
    }

    if (sortBy && sortDirection) {
      params.sort = `${sortBy}|${sortDirection}`;
    }
      dispatch(getUsers({
        page: page + 1, limit: rowsPerPage, roles: 'ADMIN', status: '1,2,3,4', ...params
      }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getUserStatus(status) {
    switch (status) {
    case USER_STATUS.ACTIVE:
      return 'Approved';
    case USER_STATUS.PENDING:
      return 'Pending';
    case USER_STATUS.INACTIVE:
      return 'In Active';
    case USER_STATUS.PENDING_DELETION:
      return 'Pending Deletion';
    case USER_STATUS.DELETED:
      return 'Deleted';
    default:
      return 'NA';
    }
  }

  function getUserStatusColor(status) {
    return status === 1 ? '#39D66A' : status === 2 ? '#FFC339'
      : status === 3 ? 'red' : status === 4 ? '#FFC339' : '#000000';
  }

  const [openUserForm, setOpenUserForm] = useState(false);

  const [isEdit, setIsEdit] = useState(true);
  const [user, setUser] = useState({
    firstName: null,
    lastName: null,
    username: null,
    email: null,
    password: null,
  });
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [deleteConfirmationAnchorEl, setDeleteConfirmationAnchorEl] = React.useState(null);

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [saveUpdateEnabled, setSaveUpdateEnabled] = useState(true);

  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const updatedUser = useSelector((state) => state.admin?.updatedUser);

  useEffect(() => {
    _getUsers(page + 1, rowsPerPage);
  }, [updatedUser]);

  useEffect(() => {
    if (admin?.users?.users?.length) {
      setUsers(admin?.users);
    } else {
      setUsers([]);
    }
  }, [admin]);

  useEffect(() => {
    _getUsers(page + 1, rowsPerPage);
  }, [page, rowsPerPage, searchText, openUserForm, sortBy, sortDirection]);

  useEffect(() => {
    if (user?.firstName?.length >= 2 &&
      user?.lastName?.length >= 2 &&
      user?.username?.length >= 4 &&
      /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(user?.email) &&
      user?.password?.length >= 6) {
      setSaveUpdateEnabled(false);
    } else {
      console.log();
      setSaveUpdateEnabled(true);
    }
  }, [user]);


  const handleAddUserForm = () => {
    setUser({ firstName: '', lastName: '', username: '', email: '', password: generateRandomString(6) });
    setIsEdit(false);
    setOpenUserForm(true);
  };

  const handleUserForm = () => {
    if (isEdit) {
      dispatch(updateUser({
        _id: user?._id, email: user.email, password: user.password, username: user.username, firstName: user.firstName, lastName: user.lastName
      }));
    } else {
      dispatch(createUser({ ...user, status: 2, role: 'ADMIN' }));
    }
    setOpenUserForm(false);
  };

  const handleUserFormClose = () => {
    setOpenUserForm(false);
  };

  const handleUserEditOpen = (row) => {
    setOpenUserForm(true);
    setIsEdit(true);
    setUser(row);
  };

  const handleUserDeleteOpen = (event, row) => {
    setUser(row);
    setDeleteConfirmationAnchorEl(event.currentTarget);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setUser({ ...user, email: value });
    setIsEmailValid(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value));
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setUser({ ...user, password: value });
    setIsPasswordValid(value.length >= 6);
  };

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setUser({ ...user, firstName: value, username: value });
    setIsFirstNameValid(value.length >= 2);
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setUser({ ...user, lastName: value });
    setIsLastNameValid(value.length >= 2);
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUser({ ...user, username: value });
    setIsUsernameValid(value.length >= 4);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationAnchorEl(null);
  };

  const handleDeleteUser = () => {
    dispatch(updateUser({
      _id: user?._id, active: false, deleted: true
    }));
    setDeleteConfirmationAnchorEl(null);
  };


  const handleUserViewOpen = (row) => {
    navigate('/admin-user-view', {
      state: {
        userData: {
          ...row,
        }
      }
    });
  };
  const open = Boolean(deleteConfirmationAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <AdminTopBar />
      <Grid container p={3}>

        <Grid sx={{
          display: {
            sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
          },
          alignItems: 'center',
          justifyContent: 'space-between'
        }} item xs={12} md={12} lg={12} sm={12} mb={2}>
          <div>
            <Typography sx={{
              fontSize: 18,
              color: '#656565',
              mt: 2,
              ml: 1,
              fontWeight: 600
            }}>Users</Typography>
          </div>
          <Box sx={{
            display: {
              sm: 'row', xs: 'row', md: 'flex', lg: 'flex'
            },
            alignItems: 'center'
          }}>
            <TextField
              variant="outlined"
              sx={{
                width: { md: 350, lg: 350, sm: '100%', xs: '100%' },
                pb: { xs: 2, sm: 2, md: 0, lg: 0 },
              }}
              value={searchText}
              onChange={handleSearchChange}
              fullWidth
              placeholder="Search by name, username, email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{
                height: 35,
                width: { md: 150, lg: 150, sm: '75%', xs: '75%' },
                marginLeft: 5,
                backgroundColor: '#41AAAF',
                '&:hover': {
                  backgroundColor: '#41AAAF',
                },
              }}
              fullWidth
              onClick={handleAddUserForm}
              variant="contained"
              startIcon={<PersonAddAltIcon />}>
              Add User
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <TableContainer sx={{
            boxShadow: '0px 4px 12px 8px rgba(0, 0, 0, 0.1)'
          }} component={Paper}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                    pl: 3,
                    color: '#747D8C'
                  }}><TableSortLabel
                    active={sortBy === 'firstName'}
                      direction={sortBy === 'firstName' ? sortDirection : 'asc'}
                      onClick={() => handleSort('firstName')}
                  >
                      First Name
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                    active={sortBy === 'lastName'}
                      direction={sortBy === 'lastName' ? sortDirection : 'asc'}
                      onClick={() => handleSort('lastName')}
                  >
                      Last Name
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                    active={sortBy === 'username'}
                      direction={sortBy === 'username' ? sortDirection : 'asc'}
                      onClick={() => handleSort('username')}
                  >
                      Username
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                    active={sortBy === 'email'}
                      direction={sortBy === 'email' ? sortDirection : 'asc'}
                      onClick={() => handleSort('email')}
                  >
                      Email
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}><TableSortLabel
                    active={sortBy === 'status'}
                      direction={sortBy === 'status' ? sortDirection : 'asc'}
                      onClick={() => handleSort('status')}
                  >
                      Status
                    </TableSortLabel></TableCell>
                  <TableCell sx={{
                    color: '#747D8C'
                  }}>Action</TableCell>
                </TableRow>
              </TableHead>
              {users?.users?.length ? <TableBody>
                {users?.users?.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell width={180} sx={{
                      fontSize: 13,
                      fontWeight: 500,
                      pl: 3

                    }}>{row.firstName}</TableCell>
                    <TableCell width={180} sx={{
                      fontSize: 13,
                      fontWeight: 500,

                    }}>{row.lastName}</TableCell>
                    <TableCell width={180} sx={{
                      fontSize: 13,
                      fontWeight: 500,

                    }}>{row.username}</TableCell>
                    <TableCell width={240} sx={{
                      fontSize: 13,
                      fontWeight: 500,

                    }}>{row.email}</TableCell>
                    <TableCell width={220} sx={{
                      fontSize: 13,
                      fontWeight: 500,

                    }}>

                      <span style={{
                        color: getUserStatusColor(row.status)
                      }}>  &#9679; </span>{getUserStatus(row.status)}</TableCell>
                    <TableCell width={200} >
                      <IconButton size='small'  onClick={() => handleUserViewOpen(row)} sx={{
                        background: '#60C68929',
                        borderRadius: 2,
                        mr: 2

                      }}>
                        <VisibilityOutlinedIcon />
                      </IconButton>
                      <IconButton size='small' onClick={() => handleUserEditOpen(row)} sx={{
                        background: '#41AAAF1A',
                        borderRadius: 2,
                        mr: 2

                      }}>
                        <img alt='img' src={EditImage} />
                      </IconButton>
                      <IconButton size='small' aria-describedby={id} onClick={(event) => handleUserDeleteOpen(event, row)} sx={{
                        background: '#FF6A761A',
                        borderRadius: 2,
                      }}>
                        <img alt='img' src={DeleteImage} />

                      </IconButton>
                      <Popover
                        sx={{
                          '& .MuiPaper-root': {
                            boxShadow: 'none',
                          },
                        }}
                        id={id}
                        open={open}
                        anchorEl={deleteConfirmationAnchorEl}
                        onClose={handleDeleteConfirmationClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <div style={{
                          margin: 8
                        }}>
                          <Typography sx={{
                            fontWeight: 600,
                            paddingBottom: 1
                          }}>Are you sure?</Typography>
                          <div style={{

                          }}>
                            <Button
                              onClick={handleDeleteUser}
                              sx={{
                                marginRight: 2,
                                color: '#ffffff',
                                borderColor: 'unset',
                                background: '#41aaae',
                                '&:hover': {
                                  backgroundColor: '#41aaae',
                                },
                              }} variant="outlined">Confirm</Button>
                            <Button
                              onClick={handleDeleteConfirmationClose}
                              sx={{
                                background: '#ff6a76',
                                color: '#ffffff',
                                borderColor: 'unset',
                                '&:hover': {
                                  backgroundColor: '#ff6a76',
                                },
                              }} variant="outlined">Cancel</Button>
                          </div>
                        </div>
                      </Popover>

                    </TableCell>
                  </TableRow>
                ))} </TableBody>
                :
                <TableBody>
                  <TableRow>
                    <TableCell sx={{
                      fontSize: 13,
                      textAlign: 'center'
                    }} colSpan={9}>No Users Found</TableCell>

                  </TableRow>
                </TableBody>
              }

            </Table>
          </TableContainer>
          {users?.meta && <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.meta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page:"
            nextIconButtonText="Next Page"
            backIconButtonText="Previous Page"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
            showFirstButton
            showLastButton

          />}
        </Grid>
        <Dialog
          maxWidth="md"
          TransitionComponent={Transition}
          fullWidth
          sx={{ '& .MuiDialog-paper': { width: '70%' } }}
          open={openUserForm} onClose={handleUserFormClose}>
          <DialogTitle sx={{
            fontSize: 13, fontWeight: 600
          }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {isEdit ? 'Edit User' : 'Add New User'}
          
            <IconButton onClick={handleUserFormClose} edge="end" color="inherit" aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item sx={{
                display: 'flex'
              }} md={12} lg={12} sm={12} xs={12} >
                <TextField
                  sx={{
                    marginRight: 4
                  }}
                  autoFocus
                  margin="dense"
                  id="name"
                  value={user?.firstName}
                  onChange={handleFirstNameChange}
                  label="First Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={!isFirstNameValid}
                  helperText={isFirstNameValid ? '' : 'Invalid Firstname'}
                />
                <TextField
                  value={user?.lastName}
                  margin="dense"
                  id="name"
                  onChange={handleLastNameChange}
                  label="Last Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={!isLastNameValid}
                  helperText={isLastNameValid ? '' : 'Invalid Lastname'}
                />
              </Grid>

              <Grid item sx={{
                display: 'flex'
              }} md={12} lg={12} sm={12} xs={12} >
                <TextField
                  sx={{
                    marginRight: 4
                  }}
                  value={user?.username}
                  onChange={handleUsernameChange}
                  margin="dense"
                  id="name"
                  InputLabelProps={{
                    shrink: user?.username !== ''
                  }}
                  label="Username"
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={!isUsernameValid}
                  helperText={isUsernameValid ? '' : 'Invalid Username'}
                />
                <TextField
                  value={user?.email}
                  margin="dense"
                  id="name"
                  onChange={handleEmailChange}
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="outlined"
                  error={!isEmailValid}
                  helperText={isEmailValid ? '' : 'Invalid email format'}
                />
              </Grid>
              <Grid item sx={{
                display: 'flex'
              }} md={12} lg={12} sm={12} xs={12} >
                <TextField
                  onChange={handlePasswordChange}
                  margin="dense"
                  id="name"
                  value={user?.password}
                  label="Password"
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={!isPasswordValid}
                  helperText={isPasswordValid ? '' : 'Invalid password'}
                />

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button sx={{
              background: '#41AAAF',
              mt: 3,
              mb: 2,
              ml: 3,
              mr: 3,
              width: 160,
              '&:hover': {
                backgroundColor: '#41AAAF',
              },
            }}
              disabled={saveUpdateEnabled}
              variant='contained' onClick={handleUserForm}>{isEdit ? 'UPDATE' : 'SAVE'}
            </Button>
          </DialogActions>
        </Dialog>

      </Grid>
    </>
  );
};

export default AdminUsers;
