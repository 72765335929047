import React, { useState, useEffect } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import { Button, Grid } from '@mui/material';
import axios from 'axios';
import DateRangePickerPopup from '../../../components/DateRangePicker';
import { REST_URL } from '../../../config';
import AdminTopBar from '../../global/AdminTopbar';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

const dayjs = require('dayjs');
const timezone = require('dayjs/plugin/timezone');
const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);
dayjs.extend(timezone);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Other = () => {
  const [filterApply, setFilterApply] = useState(false);
  const [inActiveAccountStartDate, setInActiveAccountStartDate] = useState(dayjs.utc());
  const [inActiveAccountEndDate, setInActiveAccountEndDate] = useState(dayjs.utc());
  const [file, setFile] = useState(null);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(dayjs().tz('Asia/Kolkata'));

  useEffect(() => {
    // downloadAccounts();
  }, [])

  async function downloadAccounts() {
    console.log('inActiveAccountStartDate: ', inActiveAccountStartDate);

    try {
      const accessToken = localStorage.getItem('token');
      const response = await axios.get(`${REST_URL}/users/accounts/in-active/download?startDate=${inActiveAccountStartDate.format('YYYY-MM-DD')}&endDate=${inActiveAccountEndDate.format('YYYY-MM-DD')}`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'InActiveAccounts.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading XLSX file:', error);
    }
  }

  const handleFileChange = async (event) => {
    setFile(event.target.files[0]);
    try {
      const accessToken = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      formData.append('date', lastUpdatedAt);
      const response = await axios.post(`${REST_URL}/common/import/leaderboard`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        }
      });
      if (response?.data?.success) {
        alert(`Upload Success`);
      } else {
        alert(`Upload Failed`);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <>
      <AdminTopBar />
      <Grid style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }} container spacing={2} p={3}>

        <Grid item xs={2}>
          <div style={{
            display: 'flex'
          }}>

            <p style={{
              fontSize: 18,
              fontWeight: 700
            }}> Download Inactive Accounts</p>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{
            display: 'flex'
          }}>

            <DateRangePickerPopup
              startDate={inActiveAccountStartDate}
              endDate={inActiveAccountEndDate}
              onChange={(dateRange) => {
                setInActiveAccountStartDate(dayjs.utc(dateRange?.startDate));
                setInActiveAccountEndDate(dayjs.utc(dateRange?.endDate));
              }}
              closed={() => {
                setFilterApply(!filterApply);
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <Button
            sx={{
              height: 35,
              // width: { md: 150, lg: 150, sm: '75%', xs: '75%' },
              // marginLeft: 5,
              backgroundColor: '#41AAAF',
              '&:hover': {
                backgroundColor: '#41AAAF',
              },
            }}
            fullWidth
            onClick={downloadAccounts}
            startIcon={<DownloadIcon />}
            variant="contained" >
            Download
          </Button>
        </Grid>
      </Grid>

      <Grid style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }} container spacing={2} p={3}>

        <Grid item xs={2}>
          <div style={{
            display: 'flex'
          }}>

            <p style={{
              fontSize: 18,
              fontWeight: 700
            }}>Upload Leaderboard</p>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{
            display: 'flex'
          }}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker onChange={setLastUpdatedAt} value={lastUpdatedAt} label="Update Date-time" />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </Grid>
        <Grid item xs={2}>
          {<Button
            fullWidth
            sx={{
              height: 40,
              marginTop: 2,
              marginLeft: 2,
              backgroundColor: '#41AAAF',
              '&:hover': {
                backgroundColor: '#41AAAF',
              },
            }}
            component="label"
            aria-label="upload picture"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >

            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            Upload XLSX File
          </Button>}
        </Grid>
      </Grid>
    </>
  );
};

export default Other;
